import React, {useEffect, useState} from "react";
import {connect, useDispatch} from "react-redux";

import MUIDataTable from "mui-datatables";
import {getEmployerStatus} from "../utils/Helpers";
import Grid2 from '@mui/material/Grid2';
import Tooltip from "@mui/material/Tooltip";
import Typography from '@mui/material/Typography';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Accordion from '@mui/material/Accordion';
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import Button from "@mui/material/Button";
import ContactMailIcon from '@mui/icons-material/ContactMail';

import {deleteEmployer, downloadEmployerList, getEmployerList} from "../actions/Employers";
import PropTypes from "prop-types";

import Visibility from "@mui/icons-material/Visibility";
import Delete from "@mui/icons-material/Delete";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import LoadingPanel from "./LoadingPanel";
import {useNavigate} from "react-router-dom";
import moment from "moment";

let globalEmployerID;
let globalStatus;

/**
 * @param value
 * @param status
 * @param setup_token_active
 * @param id
 * @param code
 * @param viewCompany
 * @returns {JSX.Element}
 */
const verifyPendingSetup = (value, status, setup_token_active, id, code, viewCompany) => {
	let link_to_setup = process.env.REACT_APP_EMPLOYER_APP + "sign-up/" + code;
	return (
		<span
			className={status}
			onClick={() =>
				setup_token_active ? window.open(link_to_setup) : viewCompany(id ?? null)
			}
		>
			{value}
		</span>
	);
};

/**
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const CompaniesTable = (props) => {

	/**
	 * @param dispatch
	 * @param advisor_id
	 * @param employer_id
	 * @param status
	 */
	function handleDeleteUserData(dispatch, advisor_id, employer_id, status) {
		const deleteObject = {
			emp_id: employer_id,
			adv_id: advisor_id,
			status: status,
		};
		dispatch(deleteEmployer(deleteObject));
	}

	const handleClickOpenFirstDialog = () => {
		setFirstDialog(true);
	};

	const handleClickOpenSecondDialog = () => {
		setFirstDialog(false);
		setSecondDialog(true);
	};

	const handleNo = () => {
		setFirstDialog(false);
		setSecondDialog(false);
	};

	/**
	 * @param advisor_id
	 * @param employer_id
	 * @param status
	 */
	const handleYes = (advisor_id, employer_id, status) => {
		setFirstDialog(false);
		setSecondDialog(false);
		handleDeleteUserData(dispatch, advisor_id, employer_id, status);
	};

	/**
	 * @param value
	 * @param tableMeta
	 * @returns {string|number}
	 */
	const renderEnrolledEmployees = (value, tableMeta = {
		rowData: [{
			setup_complete: false,
			employer_individual_count: 0,
		}]
	}): string | number => {
		if (value && !value?.setup_complete) {
			return "N/a";
		}

		if (value) {
			return tableMeta?.rowData[7];
		}

		return "N/a";
	}
	const renderLasUploadDate = value => value ? moment(value).format("DD/MM/YYYY") : "-";
	/**
	 * @param value
	 * @param tableMeta
	 * @returns {string}
	 */
	const renderCollectionDate = (value, tableMeta = {
		rowData: [{
			contribution_date: "",
		}]
	}): string => {
		if ((tableMeta?.rowData[3] === "Active" || tableMeta?.rowData[3] === "Locked" )&& !value?.is_employer_under_bureau) {
			return value.contribution_date;
		}
		return "N/a";
	}

	/**
	 * @param value
	 * @param tableMeta
	 * @returns {JSX.Element}
	 */
	const renderStatus = (value, tableMeta) => {
		let status = getEmployerStatus(value);
		if (value === "Pending Set Up") {
			return verifyPendingSetup(
				value,
				status,
				tableMeta?.rowData[2]?.setup_token?.active,
				tableMeta?.rowData[2]?.id,
				tableMeta?.rowData[2]?.setup_token?.code,
				props?.viewCompany,
			);
		} else {
			return (
				<span>
					{value}
				</span>
			);
		}
	}

	/**
	 * @param value
	 * @param tableMeta
	 * @param push
	 * @returns {JSX.Element}
	 */
	const renderIcons = (value, tableMeta, push) => {
		let employerID = value;
		return (
			<Grid2
				alignItems={"center"}
				justifyContent={"space-between"}
				direction={"row"}
				container
			>
				<Grid2
					item
					justifyContent={"center"}
				>
					{
						(
							tableMeta.rowData[3] === 'Active' ||
							tableMeta.rowData[3] === 'Locked' ||
							tableMeta.rowData[3] === 'Pending DDI' ||
							tableMeta.rowData[3] === 'Active Push Payment'
						) &&
						<Tooltip
							title={"Check Employees Communication"}
							arrow
						>
							<ContactMailIcon
								className={"info-icon"}
								onClick={() => push("/employees-communications/" + employerID)}
							/>
						</Tooltip>
					}
				</Grid2>
				<Grid2
					item
					justifyContent={"center"}
				>
					{
						(
							tableMeta.rowData[3] === 'Active' ||
							tableMeta.rowData[3] === 'Locked' ||
							tableMeta.rowData[3] === 'Pending DDI' ||
							tableMeta.rowData[3] === 'Active Push Payment'
						)
						&& <Tooltip
							title={"View Company"}
							arrow
						>
							<Visibility
								className={"info-icon"}
								onClick={() => props?.viewCompany(employerID ?? null)}
							/>
						</Tooltip>
					}
				</Grid2>
				<Grid2
					item
					justifyContent={"center"}
				>
					<Tooltip
						title={"Delete Company"}
						arrow
					>
						<Delete
							className={"delete-icon"}
							onClick={() => {
								globalEmployerID = employerID;
								globalStatus = tableMeta.rowData[3];
								handleClickOpenFirstDialog();
							}}
						/>
					</Tooltip>
				</Grid2>
			</Grid2>
		);
	}

	const columns = [
		{
			name: "title",
			label: "Company Name",
			options: {
				customBodyRender: (value) => (value),
				setCellProps: () => ({className: "table-cell-item-title table-cell-item-text-align"})
			}
		},
		{
			name: "company_number",
			label: "Company Number",
			options: {
				setCellProps: () => ({className: "table-cell-item-subtitle table-cell-item-text-align"})
			}
		},
		{
			name: "employer",
			label: "Enrolled Employees",
			options: {
				sort: true,
				searchable: true,
				customBodyRender: (value, tableMeta) => renderEnrolledEmployees(value, tableMeta),
				setCellProps: () => ({className: "table-cell-item-subtitle table-cell-item-text-align"}),
			}
		},
		{
			name: "connection_status",
			label: "Status",
			options: {
				hint: <span>
					<b>Possible statuses:</b> <br/>
					Locked <br/>
					Active <br/>
					Active Push Payment <br/>
					Pending DDI<br/>
					Pending Set Up <br/>
					Refused <br/>
				</span>,
				customBodyRender: (value, tableMeta) => renderStatus(value, tableMeta),
				setCellProps: (value) => ({className: "table-cell-item-text-align-" + getEmployerStatus(value.props.children)}),
			}
		},
		{
			name: "cyclical_reenrolment_date",
			label: "Re-enrolment Date",
			options: {
				searchable: false,
				customBodyRender: (value) => (value),
				setCellProps: () => ({className: "table-cell-item-subtitle table-cell-item-text-align"}),
			}
		},
		{
			name: "last_upload_date",
			label: "Last upload date",
			options: {
				sort: true,
				searchable: true,
				customBodyRender: (value, tableMeta) => renderLasUploadDate(value),
				setCellProps: () => ({className: "table-cell-item-subtitle table-cell-item-text-align"}),
			}
		},
		{
			name: "employer",
			label: "Collection Day",
			options: {
				display: !props?.profile_data?.is_b2b_b2c,
				sort: false,
				searchable: false,
				customBodyRender: (value, tableMeta) => renderCollectionDate(value, tableMeta),
				setCellProps: () => ({className: "table-cell-item-subtitle table-cell-item-text-align"}),
			}
		},
		{
			name: "enrolled_employees",
			label: "",
			options: {
				display: false,
			}
		},
	];

	if (props?.profile_data?.is_b2b_b2c) {
		columns.push({
			name: "is_employer_under_bureau",
			label: "Bureau company",
			options: {
				sort: true,
				searchable: false,
				setCellProps: () => ({className: "table-cell-item-subtitle table-cell-item-text-align"}),
				customBodyRenderLite: (dataIndex) =>
					props?.employers?.list[dataIndex]?.employer?.is_employer_under_bureau ? "Yes" : "No"
			}
		});
	}

	const push = useNavigate();


	columns.push(
		{
			name: "employer_id",
			label: " ",
			options: {
				sort: false,
				searchable: false,
				customBodyRender: (value, tableMeta) => renderIcons(value, tableMeta, push),
			}
		});

	const dispatch = useDispatch();
	const [openFirstDialog, setFirstDialog] = useState(false);
	const [openSecondDialog, setSecondDialog] = useState(false);
	const [searchFindText, setSearchFindText] = useState("");
	const [searchFindTextWithTimeOut, setSearchFindTextWithTimeOut] = useState("");
	const [sortColumn, setSortColumn] = useState("");
	const [sortDirection, setSortDirection] = useState("asc");
	const [actualPage, setActualPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(10);

	useEffect(() => {
		if (searchFindText === searchFindTextWithTimeOut && String(searchFindText).length > 2) {
			searchFindText ?
				dispatch(getEmployerList({advisors: {filter: searchFindText ?? ""}}))
				:
				dispatch(getEmployerList({advisors: {}}));
		}
	}, [searchFindText, searchFindTextWithTimeOut, dispatch]);

	useEffect(() => {
		if (props?.profile_data?.id !== undefined) {
			dispatch(getEmployerList({advisors: {}}));
		}
	}, [dispatch, props?.profile_data?.id]);

	return (
		<div>
			<Accordion id={"overview-table-accordion"}>
				<AccordionSummary
					expandIcon={
						<ExpandMoreIcon className={"accordion-arrow-icon"}/>
					}
					className={"overview-table-accordion-summary"}
				>
					<Typography variant={"h5"}>
						Manage Your Companies
					</Typography>
				</AccordionSummary>
				<AccordionDetails>
					{props?.employers?.isLoading ?
						<LoadingPanel/>
						:
						<MUIDataTable
							className={"data-table manage-your-companies-table"}
							columns={columns}
							data={props?.employers?.list}
							options={{
								// rowsPerPageOptions: [1,2,3],
								enableNestedDataAccess: ".",
								searchPlaceholder: "Search a company",
								searchOpen: true,
								searchAlwaysOpen: true,
								search: false,
								print: false,
								elevation: 0,
								filter: false,
								selectableRowsHeader: false,
								selectableRows: "none",
								responsive: "simple",
								viewColumns: false,
								serverSide: true,
								count: props?.employers?.total,
								page: props?.employers?.current_page - 1 || 0,
								rowsPerPage: rowsPerPage,
								sortOrder: {name: sortColumn ?? "", direction: sortDirection ?? ""},

								onChangeRowsPerPage: (numberOfRows: number) => {
									setRowsPerPage(numberOfRows);
									if (sortColumn === "") {
										props.getEmployerList({
											advisors: {},
										}, {
											perPage: numberOfRows,
											page: actualPage,
										})
									} else {
										props.getEmployerList({
											advisors: {
												sort: [
													true,
													sortColumn,
													sortDirection
												]
											},
										}, {
											perPage: numberOfRows,
											page: actualPage,
										})
									}
								},

								onChangePage: (currentPage: number) => {
									if (sortColumn === "") {
										props.getEmployerList({
											advisors: {},
										}, {
											perPage: rowsPerPage ?? 10,
											page: currentPage + 1,
										});
										setActualPage(currentPage);
									} else {
										props.getEmployerList({
											advisors: {
												sort: [
													true,
													sortColumn,
													sortDirection === 'asc' ? 'desc' : 'asc'
												]
											},
										}, {
											perPage: rowsPerPage ?? 10,
											page: currentPage + 1,
										});
										setActualPage(currentPage);
									}
								},

								onColumnSortChange: (changedColumn: string, columnSort, test, test1) => {
									setSortColumn(changedColumn);
									if (changedColumn === 'employer') {
										setSortColumn('enrolled_employees');
									}
									setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
								},

								onSearchChange: (searchText) => {
									setSearchFindText(searchText);
									setTimeout(() => setSearchFindTextWithTimeOut(searchText), 2000);
								},

								onTableChange: (action, tableState) => {
									switch (action) {
										case 'sort':
											let column;
											switch (tableState.activeColumn) {
												case 0:
													column = 'title';
													break;
												case 1:
													column = 'company_number';
													break;
												case 2:
													column = 'enrolled_employees';
													break;
												case 3:
													column = 'connection_status';
													break;
												case 4:
													column = "cyclical_reenrolment_date";
													break;
												case 5:
													column = "last_upload_date";
													break;
												default:
													column = 'title';
													break;
											}

											if (searchFindText === searchFindTextWithTimeOut) {
												searchFindText ?
													dispatch(getEmployerList({
														advisors: {
															filter:
															searchFindText,
															sort: [
																true,
																column,
																sortDirection ?? "asc"
															]
														}
													}, {
														perPage: rowsPerPage ?? 10,
														page: tableState?.page + 1,
													}))
													:
													dispatch(getEmployerList({
														advisors: {
															sort: [
																true,
																column,
																sortDirection ?? "asc"
															]
														}
													}, {
														perPage: rowsPerPage ?? 10,
														page: tableState?.page + 1,
													}))
											}
											break;
										default:
									}
								},

								onDownload: () => {
									dispatch(downloadEmployerList(
										{
											advisors: {
												advisor_id: props.advisor_id,
												total: props?.total
											}
										})
									);
									return false;
								},
							}}
						/>
					}
				</AccordionDetails>
			</Accordion>

			<Dialog
				open={openFirstDialog}
				onClose={handleNo}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<DialogContent>
					Are you sure you want to delete this connection?
					If you need access to this company’s account in the future you will need to add it again.
				</DialogContent>
				<DialogActions>
					<Button
						onClick={handleNo}
					>
						Cancel
					</Button>
					<Button
						onClick={handleClickOpenSecondDialog}
						autoFocus
					>
						Ok
					</Button>
				</DialogActions>
			</Dialog>

			<Dialog
				open={openSecondDialog}
				onClose={handleNo}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<DialogContent>
					If you delete this connection, you will not be able to access this company’s account
					anymore.
				</DialogContent>
				<DialogActions>
					<Button onClick={handleNo}>Cancel</Button>
					<Button
						onClick={() =>
							handleYes(props?.advisor_id, globalEmployerID, globalStatus)
						}
						autoFocus
					>
						Ok
					</Button>
				</DialogActions>
			</Dialog>
		</div>
	);
}

CompaniesTable.propTypes = {
	profile_data: PropTypes.shape({
		is_b2b_b2c: PropTypes.bool.isRequired,
	}).isRequired,
}

export default connect(
	(state) => ({
		...state.Employer,
	}),
	((dispatch) => ({}))
)(CompaniesTable);
