import Login from './Login';
import SignUp from './SignUp';
import ForgotPassword from "./ForgotPassword";
import NotFound from "./NotFound";
import PasswordRequestSent from "./PasswordRequestSent";
import ResetPassword from "./ResetPassword";
import BulkEmployers from "./AddNewCompany/BulkEmployers";
import SelectService from "./SelectService";
import ConfirmCompany from "./AddNewCompany/ConfirmCompany";
import EmailPreferences from './EmailPreferences';
import Page_403 from "./403";

// logged in pages
import MainPage from "./MainPage";
import Logout from "./Logout";
import AddNewCompany from "./AddNewCompany/AddNewCompany";
import Success from "./AddNewCompany/Success";
import UploadContributions from "./UploadContributions";
import Users from "./Users";
import CheckOptOuts from "./CheckOptOuts";
import MyAccount from "./MyAccount";
import ChangeInfo from "./ChangeInfo";
import ChangeEmail from "./ChangeEmail";
import ChangePassword from "./ChangePassword";
import Softwares from "./Softwares";
import PapdisDetails from "./PapdisDetails";
import ActiveAccount from "./ActiveAccount";
import DirectDebit from "./DirectDebit";
import ChangeAddress from "./ChangeAddress";
import BulkEmployersUploadDetails from "./AddNewCompany/BulkEmployersUploadDetails";
import UploadBankDdiFile from "./UploadBankDdiFile";
import IFADashboard from "./IFADashboard";
import PaymentCollections from "./PaymentCollections";
import ApiUploads from "./ApiUploads";
import EmployersCommunications from './EmployersCommunications';
import EmployeesCommunications from './EmployeesCommunications';

const Pages = {
	Page_403,
	Login,
	SignUp,
	NotFound,
	PasswordRequestSent,
	ResetPassword,
	ForgotPassword,
	SelectService,

	// logged in pages
	BulkEmployersUploadDetails,
	BulkEmployers,
	UploadContributions,
	MainPage,
	AddNewCompany,
	ConfirmCompany,
	Success,
	Users,
	CheckOptOuts,
	MyAccount,
	ChangeInfo,
	ChangeEmail,
	ChangePassword,
	ChangeAddress,
	PapdisDetails,
	PaymentCollections,
	ApiUploads,
	// ActivateAccount,
	Logout,
	// Authorization,
	Softwares,
	ActiveAccount,
	DirectDebit,
	EmailPreferences,
	IFADashboard,
	UploadBankDdiFile,
	EmployersCommunications,
	EmployeesCommunications,
}

export default Pages;
