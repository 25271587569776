import React from 'react';
import {connect} from 'react-redux';

import Snackbar from "@mui/material/Snackbar";

import * as EmployerNotificationAlertAction from "../actions/EmployerNotificationAlert";
import {Alert} from "@mui/material";


/**
 * @param message
 * @returns {*}
 * @private
 */
const _renderContent = (message) => {
	let text;
	if (typeof message === "object") {

		if (message.message) {
			text = message.message;
		}

		if (message.data) {
			if (message.data.link) {
				text = (
					<a
						href={message.data.url}
						target={"_blank"}
						rel="noreferrer"
						className={"snackbar-alert-link"}
					>
						{text}
					</a>
				);
			}
		}
	} else if (typeof message === "string") {
		text = message;
	}

	return text;
};

/**
 * @param onClose
 * @param severity
 * @param vertical
 * @param horizontal
 * @param open
 * @param message
 * @returns {JSX.Element}
 * @constructor
 */
const EmployerNotificationAlert = ({
	 onClose,
	 severity = "info",
	 vertical = "top",
	 horizontal = "center",
	 open = true,
	 message = ""
} : {
	onClose: Function,
	severity: string,
	vertical: string,
	horizontal: string,
	open: boolean,
	message: string,
}) => {
	return (
		<Snackbar
			anchorOrigin={{vertical, horizontal}}
			open={open}
			className={"snackbar-alert-box"}
		>
			<Alert
				onClose={onClose}
				severity={severity}
			>
				{_renderContent(message)}
			</Alert>
		</Snackbar>
	);
}

export default connect(
	state => ({...state.EmployerNotificationAlert}),
	dispatch => ({onClose: () => dispatch(EmployerNotificationAlertAction.hide())})
)(EmployerNotificationAlert);
