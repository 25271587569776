import React from 'react';
import Grid2 from '@mui/material/Grid2';

/** TODO: Import from external file
  * @param children
  * @param props
  */
const SectionGrid = ({
    children,
    ...props
}) => (
    <Grid2
	    size={{
		    xs: 12, sm: 12, md: 12, lg: 12, xl: 12,
	    }}
        direction={"column"}
        alignItems={"center"}
        alignContent={"center"}
        justifyContent={"center"}
        className={"section-grid"}
        item
        container
        {...props}
    >
        {children}
    </Grid2>
);

export default SectionGrid;
