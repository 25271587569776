import React, {useRef} from 'react';

// pages import
import Pages from '../pages';

// react router import
import {BrowserRouter as Router, Route, Routes as Switch} from "react-router-dom";
import ScrollToTop from "../layout/ScrollIntoView";
import {retrieveCollegiaAccessToken} from './Helpers';
import Request from "./Request";
import axios from "axios";
import {connect, useDispatch} from "react-redux";
import {SUBMIT_ENV_PASSWORD} from "../actions/403/types";

/**
 * @returns {unknown[]}
 */
function makeRoutes() {
	const authed = retrieveCollegiaAccessToken() ?? false;

	const IndexPage = authed ? Pages.MainPage : Pages.Login

	const publicPaths = {
		"/": IndexPage,
		"/home": IndexPage,
		"/index": IndexPage,
		"/homepage": IndexPage,
		"/login": IndexPage,
		"/sign-up": Pages.SignUp,
		"/sign-up/:token": Pages.SignUp,
		"/forgot-password": Pages.ForgotPassword,
		"/sign-up-advisor": Pages.SignUp,
		"/select-service": Pages.SelectService,
		"/forgot-password/request-sent": Pages.PasswordRequestSent,
		"/reset-password/:token": Pages.ResetPassword,
		"/setup/:token": Pages.SignUp,
		"/403": Pages.Page_403,
	};

	const privatePaths = {
		"/main-page": Pages.MainPage,
		"/main-page/:tab": Pages.MainPage,
		"/active-account": Pages.ActiveAccount,
		"/logout": Pages.Logout,
		"/bulk-employers": Pages.BulkEmployers,
		"/bulk-employers-upload-details/:file_upload_id": Pages.BulkEmployersUploadDetails,
		"/add-new-company": Pages.AddNewCompany,
		"/confirm-company": Pages.ConfirmCompany,
		"/ifa-dashboard": Pages.IFADashboard.IfaDashboard,
		"/funds": Pages.IFADashboard.IfaFunds,
		"/key-stats": Pages.IFADashboard.IfaKeyStats,
		"/add-new-client": Pages.IFADashboard.AddNewClient,
		"/beneficiary-information": Pages.IFADashboard.BeneficiaryInformation,
		"/success": Pages.Success,
		"/upload-contributions": Pages.UploadContributions,
		"/my-users": Pages.Users,
		"/manage-softwares": Pages.Softwares,

		"/check-opt-outs": Pages.CheckOptOuts,

		"/employees-communications/:id": Pages.EmployeesCommunications,
		"/employers-communications/:id": Pages.EmployersCommunications,

		"/payment-collection": Pages.PaymentCollections,
		"/api-uploads": Pages.ApiUploads,
		"/my-account": Pages.MyAccount,
		"/change-info": Pages.ChangeInfo,
		"/change-email": Pages.ChangeEmail,
		"/change-address": Pages.ChangeAddress,
		"/change-password": Pages.ChangePassword,
		"/papdis-details/:id": Pages.PapdisDetails,
		"/select-banks": Pages.DirectDebit.SelectBanks,
		"/sign-direct-debit": Pages.DirectDebit.SignDirectDebit,
		"/email-preferences": Pages.EmailPreferences,
		"/upload-bank-ddi-file": Pages.UploadBankDdiFile,
	};

	return [
		...Object.entries(publicPaths).map(([path, component]) => (
				<Route
					key={path}
					path={path}
					component={component}
					element={React.createElement(component, {})}
					exact
				/>
			)
		),

		...Object.entries(privatePaths).map(([path, component]) => (
				<Route
					key={path}
					path={path}
					element={React.createElement(component, {})}
					exact
				/>
			)
		),
		<Route
			key={"404-route"}
			element={React.createElement(Pages.NotFound)}
		/>
	];
}

const AppRoutes = (
	profile_data = {
		my_ip: "",
	}
) => {
	useRef("route");
	const dispatch = useDispatch();

	if (process.env.REACT_APP_ENV !== "production") {
		axios.get("https://api.ipify.org?format=json")
			.then((response = {
				data: {
					ip_address: "",
				}
			}) => {
				dispatch({
					type: SUBMIT_ENV_PASSWORD,
					profile_data: {
						...profile_data,
						my_ip: response.data.ip,
					}
				});
				Request.post("advisors/is_allowed", {
						my_ip: response.data.ip,
					})
					.catch(error => {
						if (error.response.status === 403) {
							if (window.location.pathname !== "/403") {
								window.location.href = `/${error.response.status}`;
							}
						}
					});
			});
	}

	return (
		<Router
			basename={"/"}
		>
			<ScrollToTop/>
			<Switch>
				{makeRoutes()}
			</Switch>
		</Router>
	);
};

const mapStateToProps = state => state.Profile;

const mapDispatchToProps = dispatch => ({});

export default connect(mapStateToProps, mapDispatchToProps)(AppRoutes);
