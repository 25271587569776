import React, {createRef, useEffect, useState} from "react";

import {connect} from "react-redux";
import Wrapper from "../layout/Logged/Wrapper";

import Grid2 from '@mui/material/Grid2';
import * as EMPLOYERS from "../actions/Employers";
import * as PAPDIS from "../actions/PAPDIS";

import {me} from "../actions/Profile";

import FileUpload from "../components/FileUpload";
import * as SnackBar from "../actions/SnackBar";
import SecondaryHeader from "../components/Headers/SecondaryHeader";
import PastUploadTable from "../components/Papids/PastUploadTable";
import {cleanEmployeeContributions, getEmployeeContributions} from "../actions/Contributions";
import * as Popup from "../actions/Popup";
import Pagination from "@mui/material/Pagination";

import {PrimaryHeader} from "../components/Headers/PrimaryHeader";
import Button from "@mui/material/Button";
import {ModalContents} from "../utils/ModalContents";
import {deletePapdisPastUpload, downloadPapdisContent, downloadPAPDISPastUploads} from "../actions/PAPDIS";
import {CloudDownload} from "@mui/icons-material";
import CircularProgress from "@mui/material/CircularProgress";
import Loading from "../components/Loading";

/**
 * @type {string}
 */
const acceptedPAPDISFormats = [
	"text/csv",
	"text/plain",
].join(",");

/**
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const UploadContributions = ({
	profile_data = [],
	me = () => false,
	papdis = {},
	triggerModal = Function,
	setTriggerUpload = () => {},
	getUploads = () => false,
	sendFile = () => {},
	warn = () => {},
	getEmployeeContributions = () => {},
	history = () => {},
	deletePapdisPastUpload = () => {},
	downloadPAPDISPastUploads = () => {},
	isLoadingPastUploads = false,
	downloadPapdisContent = () => {},
}) => {
	const dropzoneRef = createRef();
	const {past_uploads} = papdis;
	const id = profile_data?.id || 0;
	const [page, setPage] = useState(1);

	useEffect(() => {
		if (!(dropzoneRef && dropzoneRef.current)) return;
		setTriggerUpload(false);
	}, [dropzoneRef, setTriggerUpload]);

	useEffect(() => {
		me();
	}, [me]);

	useEffect(() => {
		getUploads(id, 1);
	}, [getUploads, id]);

	useEffect(() => {
		const timeoutId = setInterval(() => getUploads(id, page, false), 5000);

		return () => clearInterval(timeoutId);
	},[
		getUploads,
		id,
		page,
	]);

	if (isLoadingPastUploads || !profile_data) {
		return (
			<Loading absolute/>
		);
	}


	let link_csv_model = process.env.REACT_APP_API + "download-papdis-csv-sample";
	let link_csv_guide = process.env.REACT_APP_API + "download-papdis-csv-guide";

	return (
		<Wrapper
			history={history}
			profile_data={profile_data}
		>
			<PrimaryHeader
				backIcon
				helpIcon
				helpModal={ModalContents.upload_contributions_help}
			>
				Upload Contributions
			</PrimaryHeader>

			<Grid2 className={"collegia-border"}>

				<Grid2
					justifyContent={"flex-end"}
					alignItems={"center"}
					className={"container-wrapper"}
					container
				>
					<Grid2
						item
						marginRight={2}
					>
						<a
							target="_blank"
							rel="noreferrer"
							href={link_csv_guide}
						>
							<Button
								className={"default-button"}
							>
								GET CSV GUIDE
							</Button>
						</a>
					</Grid2>

					<a
						target="_blank"
						rel="noreferrer"
						href={link_csv_model}
					>
						<Button
							className={"default-button"}
						>
							GET CSV MODEL
						</Button>
					</a>
				</Grid2>

				<Grid2
					className={"container-wrapper no-padding-top"}
				>
					<FileUpload
						ref={dropzoneRef}
						acceptedFormats={acceptedPAPDISFormats}
						sendFile={sendFile}
						id={profile_data?.id}
						onError={warn}
						triggerModal={triggerModal}
						account_locked={profile_data?.account_locked}
					/>
				</Grid2>
			</Grid2>

			<SecondaryHeader>
				Past Uploads
				<Button>
					{
						papdis?.isLoading || papdis?.isLoadingPastUploads || isLoadingPastUploads ?
							<CircularProgress/> :
							<CloudDownload
								onClick={() => downloadPAPDISPastUploads({"id": profile_data.id})}
							/>
					}
				</Button>
			</SecondaryHeader>

			<Grid2>
				<Grid2>
					<PastUploadTable
						getPapdisContributions={getEmployeeContributions}
						uploads={past_uploads?.data || []}
						loading={papdis?.isLoading || papdis?.isLoadingPastUploads}
						triggerModal={triggerModal}
						deletePapdisPastUpload={deletePapdisPastUpload}
						profile_data={profile_data}
						papdis_file_upload_id={papdis.papdis_file_upload_id}
						label={papdis.label}
						downloadPapdisContent={downloadPapdisContent}
					/>
				</Grid2>
				<Grid2
					justifyContent={"center"}
					container
				>
					{
						(past_uploads && (past_uploads?.last_page > 1)) && (
							<Grid2>
								<Pagination
									onChange={(e, value) => {
										if (value !== past_uploads.current_page) {
											getUploads(id, value);
											setPage(value);
										}
									}}
									color="primary"
									count={past_uploads?.last_page}
								/>
							</Grid2>
						)
					}
				</Grid2>
			</Grid2>
		</Wrapper>
	);
}

export default connect(
	(state) => ({
		...state.Profile,
		papdis: state.PAPDIS,
	}),
	(dispatch) => ({
		me: () => dispatch(me()),
		setTriggerUpload: (shouldTrigger) =>
			dispatch(EMPLOYERS.setTriggerUpload(shouldTrigger)),
		warn: msg => dispatch(SnackBar.warn(msg)),
		sendFile: (files = [], advisor) =>
			dispatch(PAPDIS.insert({papdisFile: files[0], advisor})),
		getEmployeeContributions: (national_insurance_number: string = "", type: string = "", id: number = 0) =>
			dispatch(getEmployeeContributions(national_insurance_number, type, id)),
		getUploads: (advisor_id, page_index, loading = true) => dispatch(PAPDIS.getUploads(advisor_id, page_index, loading)),
		triggerModal: (errors) => dispatch(Popup.showUploadErrors(errors)),
		cleanEmployeeContributions: () => dispatch(cleanEmployeeContributions()),
		deletePapdisPastUpload: (id, advisor_id) => dispatch(deletePapdisPastUpload(id, advisor_id)),
		downloadPAPDISPastUploads: (data) => dispatch(downloadPAPDISPastUploads(data)),
		downloadPapdisContent: (id, fileName) => dispatch(downloadPapdisContent(id, fileName))
	})
)(UploadContributions);
