import React from "react";
import {Tooltip} from "@mui/material";
import CompareArrowsIcon from '@mui/icons-material/CompareArrows';
import Grid2 from '@mui/material/Grid2';
import Button from "@mui/material/Button";

/**
 * @param selectedEmployers
 * @param employerIfaAccess
 * @param advisor_id
 * @returns {JSX.Element}
 */
const customToolbarSelectEmployers = (
	{
		selectedEmployers = [],
		employerIfaAccess = () => {
		},
		advisor_id = 0
	}
) => (
	<Grid2
		marginRight={2}
		direction={"row"}
		container
		item
		justifyContent={"flex-end"}
	>
		<Grid2
			marginRight={2}
		>
			<Tooltip title={"Change Funds"} className={"success-button-toolbar"}>
				<Button
					onClick={() => employerIfaAccess({
						ifa_employers: {
							selectedEmployers,
							advisor_id
						}
					})}
				>
					Request Access
					<CompareArrowsIcon/>
				</Button>
			</Tooltip>
		</Grid2>
	</Grid2>
);

/**
 * @stateless
 * @param props
 * @returns {*}
 */
export default customToolbarSelectEmployers;
