import React from "react";
import {connect} from "react-redux";
import Grid2 from '@mui/material/Grid2';
import {getEmployerList, viewCompany} from "../actions/Employers";
import {TabButton} from "./TabButton";
import CompaniesTable from "./CompaniesTable";
import upload_contributions from "../images/upload-contributions.svg";
import check_opt_outs from "../images/check-opt-outs.svg";
import add_new_company from "../images/add-new-company.svg";

/**
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const OverviewTab = (props = {
	employer: null,
	employers: [],
	viewCompany: () => {
	},
	total: 0,
	per_page: 15,
	profile_data: {
		is_b2b_b2c: false,
		is_push_payment: false,
		mandate: {},
	}
}) => {
	const {
		employer,
		history,
		viewCompany = () => {
		},
	} = props;

	const mainButtons = {};

	if ((props?.profile_data?.is_b2b_b2c && props?.profile_data?.mandate) || props.profile_data.is_push_payment) {
		mainButtons.upload_contributions = {
			title: "Upload Contributions",
			image: upload_contributions,
			link: "/upload-contributions",
		}
	} else if (!props?.profile_data?.is_b2b_b2c || props.profile_data.is_push_payment) {
		mainButtons.upload_contributions = {
			title: "Upload Contributions",
			image: upload_contributions,
			link: "/upload-contributions",
		}
	}

	mainButtons.add_new_company = {
		title: "Add New Company",
		image: add_new_company,
		link: "/add-new-company"
	}

	mainButtons.check_opt_outs = {
		title: "Check Opt Outs",
		image: check_opt_outs,
		link: "/check-opt-outs"
	}

	return (
		<div>
			<Grid2
				className={"main-buttons-container"}
				container
				justifyContent={"space-between"}
				direction={"row"}
			>
				{Object?.values(mainButtons)?.map(({title, image, link}, index) => (
					<TabButton
						active={'true'}
						key={index}
						image={image}
						onClick={() => history(link)}
					>
						{title}
					</TabButton>
				))}
			</Grid2>

			<CompaniesTable
				viewCompany={viewCompany}
				getEmployerList={employer}
				advisor_id={props?.profile_data?.id}
				profile_data={props?.profile_data}
				per_page={props?.employers?.per_page}
			/>
		</div>
	);
}

export default connect(
	(state) => ({
		...state.Profile,
		...state.Employer
	}),
	(dispatch) => ({
		employer: (data = {}, queryString = {}) => dispatch(getEmployerList(data, queryString ?? {})),
		viewCompany: (employer_id: any = null) => dispatch(viewCompany(employer_id ?? null)),
	})
)(OverviewTab);
