import React from "react";
import Grid2 from '@mui/material/Grid2';

/**
 * @param individuals_count
 * @returns {JSX.Element}
 * @constructor
 */
export const CountIndividuals = (
	{
		individuals_count = {
			total: 0,
			active: 0,
			pending: 0,
		}
	}
) => (
	<Grid2
		justifyContent={"space-between"}
		alignItems={"center"}
		className={"collegia-border count-employers"}
		container
	>
		<Grid2
			size={{
				xs: 12, sm: 12, md: 4, lg: 4, xl: 4,
			}}
			justifyContent={"center"}
			item
			container
		>
				<span className={"count-employers-title"}>
					Total Individuals:&nbsp;
				</span>
			<span className={"count-employers-number"}>
					{individuals_count?.total ?? 0}
				</span>
		</Grid2>

		<Grid2
			size={{
				xs: 12, sm: 12, md: 4, lg: 4, xl: 4,
			}}
			justifyContent={"center"}
			item
			container
		>
				<span className={"count-employers-title"}>
					Active:&nbsp;
				</span>
			<span className={"count-employers-number"}>
					{individuals_count?.active ?? 0}
				</span>
		</Grid2>

		<Grid2
			size={{
				xs: 12, sm: 12, md: 4, lg: 4, xl: 4,
			}}
			justifyContent={"center"}
			item
			container
		>
				<span className={"count-employers-title"}>
					Pending:&nbsp;
				</span>
			<span className={"count-employers-number"}>
					{individuals_count?.pending ?? 0}
				</span>
		</Grid2>
	</Grid2>
);
