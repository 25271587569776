import React from "react";
import {connect} from "react-redux";

// importing material ui icons.
import {
	AppBar,
	IconButton,
	Toolbar,
	Typography,
	Grid2,
	Link,
	Badge,
} from "@mui/material";
import MenuIcon from '@mui/icons-material/Menu';
import clsx from "clsx";

// importing the default images.
import logo from "../../../images/logo-icon.svg";
import NotificationsIcon from "@mui/icons-material/Notifications";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import NotificationProvider from "../../../components/NotificationProvider";

/**
 * @param notifications
 * @param handleNotificationsOpen
 * @param open
 * @returns {JSX.Element}
 * @private
 */
const _renderIconButton = (notifications = [], handleNotificationsOpen = () => {
}, open) => {
	if (notifications?.notifications?.length) {
		return (
			<IconButton
				color={"inherit"}
				aria-label={"open drawer"}
				edge={"end"}
				onClick={event => ((notifications.notifications || [])?.length && handleNotificationsOpen(event.currentTarget))}
				className={clsx(open && 'hide')}
				style={{marginRight: "2%"}}
			>
				{(notifications.notifications || [])?.filter(x => !x.visualized).length
					?
					<Badge
						color={"error"}
						variant={"dot"}
					>
						<NotificationsIcon/>
					</Badge>
					: <NotificationsNoneIcon/>
				}
			</IconButton>
		);
	}
	return [];
}

/**
 * @param profile_data
 * @param classes
 * @param open
 * @param handleDrawer
 * @param handleNotificationsOpen
 * @param showSandwich
 * @param notifications
 * @param isIFA
 * @returns {JSX.Element}
 * @constructor
 */
const AppBarHeader = (
	{
		profile_data = null,
		classes = {},
		open = false,
		handleDrawer = () => {
		},
		handleNotificationsOpen = () => {
		},
		showSandwich = true,
		notifications = [],
		isIFA = null,
	}
) => {

	let linkHeader = "";

	if (isIFA) {
		linkHeader = '/ifa-dashboard';
	} else {
		linkHeader = '/main-page';
	}

	return (
		<AppBar
			position={"static"}
			id={"menu-logged"}
			className={clsx(classes.appBar, {
				[classes.appBarShift]: open,
			})}
		>
			<NotificationProvider>
				<Toolbar>
					<Grid2
		size={{
			xs: 12, sm: 12, md: 12, lg: 12, xl: 12,
		}}
						alignContent={"center"}
						alignItems={"center"}
						direction={"row"}
						item
						container
					>
						<Grid2
							size={{
								xs: 6, sm: 6, md: 6, lg: 6, xl: 6,
							}}
							alignContent={"flex-start"}
							alignItems={"flex-start"}
							direction={"column"}
							container
							item
						>
							<Link href={linkHeader}>
								<Typography variant={"h6"} noWrap className={"title"}>
									<img
										src={logo}
										alt={"Collegia logo."}
									/>
								</Typography>
							</Link>
						</Grid2>
						<Grid2
							className={"advisor-header-title"}

							size={{
								xs: 5, sm: 5, md: 5, lg: 5, xl: 5,
							}}
							alignContent={"flex-end"}
							alignItems={"flex-end"}
							direction={"column"}
							container
							item
						>
							{profile_data?.title}
						</Grid2>
						{
							showSandwich &&
							<Grid2
								size={{
									xs: 1, sm: 1, md: 1, lg: 1, xl: 1,
								}}
								alignContent={"center"}
								alignItems={"center"}
								justifyContent={"flex-end"}
								direction={"row"}
								container
								item
							>
								{
									profile_data?.notifications?.length > 0 ?
										""
										:
										_renderIconButton(notifications, handleNotificationsOpen, open)
								}
								{_renderIconButton(profile_data, handleNotificationsOpen, open)}
								<IconButton
									color={"inherit"}
									aria-label={"open drawer"}
									edge={"end"}
									onClick={handleDrawer}
									className={clsx(open && 'hide')}
								>
									<MenuIcon/>
								</IconButton>
							</Grid2>
						}
					</Grid2>
				</Toolbar>
			</NotificationProvider>
		</AppBar>
	);
};

/**
 * @stateless
 * @param props
 * @returns {*}
 */
export default connect(state => ({notifications: state.Notifications}))(AppBarHeader);
