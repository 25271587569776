import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import Wrapper from "../layout/Logged/Wrapper";
import Loading from "../components/Loading";
import {me, updateCompanyNumber} from "../actions/Profile";
import OverviewTab from "../components/OverviewTab";
import {PrimaryHeader} from "../components/Headers/PrimaryHeader";
import ActiveDirectDebitButton from "../components/DirectDebit/ActiveDirectButton";
import {ModalContents} from "../utils/ModalContents";
import IndividualOptOut from "../components/IndividualOptOut/IndividualOptOut";
import Grid2 from '@mui/material/Grid2';
import Button from "@mui/material/Button";
import {CountEmployers} from "../components/CountEmployers";
import {BeneficiaryCard} from "../components/IFA/BeneficiaryCard";
import {useNavigate} from "react-router-dom";
import AdvisorCompanyNumberDialog from "../components/Advisor/AdvisorCompanyNumberDialog";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";

/**
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const MainPage = (props = {
	me: () => false,
	updateCompanyNumber: () => {},
	profile_data: {
		companies_count: {
			active: 0,
			pending: 0,
			total: 0,
		},
		is_b2b_b2c: false,
		is_push_payment: false,
		my_ip: "",
	},
	profile_loading: false,
}) => {
	const {
		profile_data = {
			has_beneficiary: false,
			is_payroll_advisor: false,
			userLogged: {
				forename: "",
				surname: "",
			},
		},
		profile_loading = false,
		me = () => {
		},
		updateCompanyNumber = () => {
		},
	} = props;
	const history = useNavigate();
	const [handleDialog, setHandleDialog] = useState(true),
		[companyNumber, setCompanyNumber] = useState("");

	useEffect(
		() => me(),
		[
			me,
		]
	);

	useEffect(() => {
		if (profile_data?.company_number !== null && profile_data?.company_number !== undefined) {
			setHandleDialog(false);
		}
	}, [profile_data?.company_number]);



	if (!profile_data || profile_loading) {
		return (
			<Loading absolute/>
		);
	}

	if (profile_data?.email) {
		window.$crisp.push([
			"set",
			"user:email",
			profile_data.email
		]);
		window.$crisp.push([
			"set",
			"user:nickname",
			[
				`${profile_data?.userLogged?.forename} ${profile_data?.userLogged?.surname}`
			]
		]);
		window.$crisp.push(["set", "user:phone", [profile_data?.telephone]]);
		window.$crisp.push([
			"set",
			"user:company",
			[
				profile_data?.title,
				{
					url: "",
				}
			]
		]);
		window.$crisp.push(["set", "session:data", [
			[
				["address", `${profile_data?.address?.address_1 ?? ""} ${profile_data?.address?.address_2 ?? ""} ${profile_data?.address?.address_4 ?? ""}`],
				["category", "Advisor"],
			]
		]]);
	}

	return (
		<Wrapper history={history} profile_data={props.profile_data}>

			{
				profile_data?.account_locked &&
				<Snackbar
					anchorOrigin={{vertical: "top", horizontal: "center"}}
					open={true}
					className={"snackbar-alert-box"}
				>
					<Alert severity={"error"} elevation={6} variant={"filled"}>
						Your account is locked. Please contact support.
					</Alert>
				</Snackbar>
			}

			<PrimaryHeader
				helpIcon
				helpModal={ModalContents.home_help}
			>
				Payroll Dashboard
			</PrimaryHeader>
			<CountEmployers
				companies_count={props?.profile_data?.companies_count}
			/>
			{
				profile_data.is_ifa_advisor && profile_data.is_payroll_advisor && (
					<Grid2
						size={{
							xs: 12, sm: 12, md: 12, lg: 12, xl: 12,
						}}
						direction={"row"}
						alignItems={"center"}
						justifyContent={"center"}
						container
						item
					>
						<Grid2
							size={{
								xs: 6, sm: 6, md: 6, lg: 6, xl: 6,
							}}
							className={"tabs-active"}
							textAlign={"center"}
							item
							onClick={() => window.location.href = '/main-page'}
						>
							<Button fullWidth label={"Payroll Dashboard"} className={"tabs-active"}>
								Payroll Dashboard
							</Button>
						</Grid2>
						<AdvisorCompanyNumberDialog
							advisorId={profile_data?.id}
							companyNumber={companyNumber}
							handleDialog={handleDialog}
							setCompanyNumber={setCompanyNumber}
							setHandleDialog={setHandleDialog}
							updateCompanyNumber={updateCompanyNumber}
						/>
						<Grid2
							size={{
								xs: 6, sm: 6, md: 6, lg: 6, xl: 6,
							}}
							className={"tabs-inactive"}
							textAlign={"center"}
							item
							onClick={() => window.location.href = '/ifa-dashboard'}
						>
							<Button fullWidth label={"IFA Dashboard"} className={"tabs-inactive"}
									disabled={!profile_data?.has_beneficiary}>
								IFA Dashboard
							</Button>
						</Grid2>
					</Grid2>
				)
			}
			{
				!profile_data?.has_beneficiary && profile_data?.is_ifa_advisor &&
				<BeneficiaryCard
					history={history}
				/>
			}
			<ActiveDirectDebitButton
				history={history}
				profile_data={props?.profile_data}
			/>
			<div>
				<IndividualOptOut
					open={profile_data?.individualsOptOut?.length > 0}
					individualsOptOut={profile_data?.individualsOptOut}
				/>
				<div className={"main-page-container-wrapper"}>
					<OverviewTab
						history={history}
					/>
				</div>
			</div>
		</Wrapper>
	);
};

const mapStateToProps = state => state.Profile;

const mapDispatchToProps = dispatch => ({
	me: () => dispatch(me()),
	updateCompanyNumber: (id, data) => dispatch(updateCompanyNumber(id, data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(MainPage);
