import React from 'react';
import {connect} from "react-redux";
import ListItemText from "@mui/material/ListItemText";
import Drawer from "@mui/material/Drawer";
import CloseIcon from '@mui/icons-material/Close';
import Divider from "@mui/material/Divider";
import Grid2 from '@mui/material/Grid2';
import List from "@mui/material/List";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import DomainOutlined from "@mui/icons-material/DomainOutlined";
import ListItemButton from "@mui/material/ListItemButton";

/**
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const RightLoggedNavigation = (props = {
	theme: {},
	open: false,
	profile_data: {
		title: "",
	},
	handleDrawer: () => {
	},
	history: () => {
	},
}) => {
	const options = [
		{
			text: "My dashboard",
			link: "/main-page",
			external: false
		},
		{
			text: 'My account',
			link: "/my-account",
			external: false,
		},
		{
			text: "My users",
			link: "/my-users",
			external: false
		},
		{
			text: 'Legal',
			link: "https://www.collegia.co.uk/legal",
			external: true,
		},
	]

	if (props?.profile_data?.is_b2b_b2c) {
		options.push({
			text: 'Payment Collection',
			link: "/payment-collection",
			external: false,
		});
	}

	if (props?.profile_data?.has_software) {
		options.push({
			text: 'API Uploads',
			link: "/api-uploads",
			external: false,
		});
	}

	if (props?.profile_data?.has_software) {
		options.push({
			text: "Manage softwares",
			link: "/manage-softwares",
			external: false
		})
	}

	options.push({
		text: 'Log out',
		link: "/logout",
		external: false,
	});

	return (
		<Drawer
			className={"app-bar-drawer"}
			variant="persistent"
			anchor="right"
			open={props.open}
			classes={{
				paper: "logged-menu-drawer",
			}}
		>
			<Grid2
				size={{
					xs: 12, sm: 12, md: 12, lg: 12, xl: 12,
				}}
				className={"app-bar-drawer-header"}
				direction={"column"}
				alignContent={"flex-end"}
				alignItems={"flex-end"}
				item
				container
			>
				<Typography
					className={"typography-icon"}
				>
					<DomainOutlined className={"domain-icon"}/>
				</Typography>
				<IconButton
					onClick={props.handleDrawer}
					disableTouchRipple
					disableFocusRipple
					disableRipple
				>
					<CloseIcon/>
				</IconButton>
			</Grid2>

			<Grid2
				size={{
					xs: 12, sm: 12, md: 12, lg: 12, xl: 12,
				}}
				direction={"column"}
				alignItems={"center"}
				alignContent={"center"}
				id={"profile-header-page-container"}
				className={"menu"}
				item
				container
			>
				<Grid2 item>
					<Typography
						variant={"subtitle1"}
						className={"hello-individual"}
						align={"center"}
					>
						Hi {`${props?.profile_data?.title}`}!
					</Typography>
				</Grid2>
			</Grid2>
			<Divider orientation="horizontal"/>
			<List className={'drawerList'}>
				<Grid2
					size={{
						xs: 12, sm: 12, md: 12, lg: 12, xl: 12,
					}}
					direction={"row"}
					alignContent={"center"}
					alignItems={"center"}
					item
					container
				>
					<Grid2
						size={{
							xs: 12, sm: 12, md: 12, lg: 12, xl: 12,
						}}
						item
					>
						{
							options.map((links, index) => (
								<ListItemButton
									onClick={() =>
										!links.external ?
											props?.history(links.link)
										:
											window.open(links.link, "_blank")
									}
									key={index}
								>
									<ListItemText
										primary={links.text}
									/>
								</ListItemButton>
							))
						}
					</Grid2>
				</Grid2>

			</List>
		</Drawer>
	);
}

const mapStateToProps = state => state.Profile;

export default connect(mapStateToProps, null)(RightLoggedNavigation);
