import React, {useEffect, useState} from 'react';

import {connect} from "react-redux";
import {cancelPrepareEmandate, verifyIfTheMandateFinish} from "../../actions/Banks";
import Wrapper from "../../layout/Logged/Wrapper";
import Grid2 from '@mui/material/Grid2';
import Slide from '@mui/material/Slide';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import {retrieveCollegiaAccessToken} from "../../utils/Helpers";
import {me} from "../../actions/Profile";
import {useNavigate} from "react-router-dom";
import Loading from "../../components/Loading";
import {PrimaryHeader} from "../../components/Headers/PrimaryHeader";

const SignDirectDebit = ({
	profile_data = [],
	profile_loading = false,
	me = () => {},
	cancelPrepareEmandate = () => {},
	verifyIfTheMandateFinish = () => {},
	verify_emandate_loading = false,
}) => {
	const push = useNavigate();
	const [link, setLink] = useState("");
	useEffect(() => {
		me();
	}, [me]);

	useEffect(() => {
		const prepareEmandateId = profile_data?.prepare_emandate?.id;
		const verifyAndSetInterval = () => {
			verifyIfTheMandateFinish(prepareEmandateId);
		};
		verifyAndSetInterval();
		const intervalId = setInterval(verifyAndSetInterval, 10000);
		return () => clearInterval(intervalId);

	}, [profile_data?.prepare_emandate?.id, verifyIfTheMandateFinish]);

	useEffect(() => {
		if (profile_data?.prepare_emandate?.active) {
			if (process.env.REACT_APP_ENV === "production") {
				setLink("https://api.nuapay.com/tpp-ui/emandate-ui/redirect?conversationToken=" + profile_data.prepare_emandate?.token);
			} else {
				setLink("https://sandbox.nuapay.com/tpp-ui/emandate-ui/redirect?conversationToken=" + profile_data.prepare_emandate?.token);
			}
		}
	}, [setLink, profile_data]);

	if (!retrieveCollegiaAccessToken()) {
		push("/login");
	}

	if (profile_loading) {
		return (
			<Loading/>
		);
	}

	return (
		<Wrapper>
			<Slide in={true} direction={"up"}>
				<Grid2>
					<Grid2
						spacing={0}
						direction="column"
						alignItems="center"
						alignContent={"center"}
						id={"direct-debit-contribution-container"}
						container
						item
					>
						<PrimaryHeader>
							Finish the emandate
						</PrimaryHeader>
						<Paper
							id={"direct-debit-container"}
						>
							<Grid2
								size={{
									xs: 12, sm: 12, md: 12, lg: 12, xl: 12,
								}}
								alignContent={"center"}
								direction={"column"}
								item
								container
							>
								<Typography
									className={"main-title"}
									variant={"h5"}
									align={"center"}
								>
									{"Lost the link of the your Direct Debit? Click "}
									<a href={link} target={"_blank"} rel={"noopener noreferrer"}>here</a>
									{" to be redirect"}
								</Typography>
								<Typography
									className={"main-title"}
									variant={"h5"}
									align={"center"}
								>
									Or click
									<span
										className={"span-red"}
										onClick={() => cancelPrepareEmandate(profile_data?.prepare_emandate?.id)}
									>
										{" here "}
									</span>
									to cancel the direct debit
								</Typography>
							</Grid2>
							{
								verify_emandate_loading ?
									<Grid2
										container
										alignItems={"center"}
										alignContent={"center"}
									>
										<Loading/>
									</Grid2>
									:
									<></>
							}
						</Paper>
					</Grid2>
				</Grid2>
			</Slide>
		</Wrapper>
	);
}

const mapStateToProps = state => {
	const {
		DirectDebit = [],
		Profile = [],
	} = state;

	return {
		...DirectDebit,
		...Profile,
	};
}

/**
 * @param dispatch
 * @returns {{details: (function(string=): *)}}
 */
const mapDispatchToProps = dispatch => ({
	me: () => dispatch(me(true, false)),
	cancelPrepareEmandate: (id: number) => dispatch(cancelPrepareEmandate(id)),
	verifyIfTheMandateFinish: (id) => dispatch(verifyIfTheMandateFinish(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SignDirectDebit);
