import {connect} from "react-redux";
import React, {useEffect, useState} from "react";

import UserTablePanel from "./UserTablePanel";
import Wrapper from "../../layout/Logged/Wrapper";

import {me} from "../../actions/Profile";
import UserCreationPanel from "./UserCreatingPanel";
import Grid2 from '@mui/material/Grid2';
import ContentGrid from "../../layout/ContentGrid";
import Typography from "@mui/material/Typography";
import LoadingPanel from "../../components/LoadingPanel"

import {
	createUser,
	deleteUser,
	editUser,
	checkIsSuper,
} from "../../actions/Users";
import {getEmployerList} from "../../actions/Employers";

/**
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const Users = (props) => {
	const [flagCreate, setFlagCreate] =
		useState(false);
	const [open, setOpen] = useState(false);
	const [checked, setChecked] = React.useState([0]);

	const [flagEdit, setFlagEdit] =
		useState(false);
	const [page, setPage] = useState(1);

	const [updateUser, setUpdateUser] =
			useState([]),
		{
			history,
			profile_data,
			createUser,
			deleteUser,
			editUser,
			checkIsSuper,
			users,
			profile_loading,
			getEmployerList = () => false,
		} = props;

	useEffect(() => {
		getEmployerList({
			perPage: 100,
		});
		checkIsSuper();
	}, [
		getEmployerList,
		checkIsSuper,
	]);

	function handleDeleteUserData(data) {
		const object = {
			profileID: profile_data.id,
			id: data.id,
			advisor_id: data.advisor_id,
			active: data.active,
			is_super: data.is_super
		};
		deleteUser(object)
	}

	function handleEditUserData(data = {}) {
		const object = {
			id: data.id,
			advisor_id: data.advisor_id,
			forename: data.forename,
			middle_name: data.middle_name,
			surname: data.surname,
			is_super: data.is_super,
			selectedEmployers: data?.selectedEmployers ?? [],
		};
		editUser(object)
	}

	const handleToggle = (value: number) => () => {
		const currentIndex = checked.indexOf(value);
		const newChecked = [...checked];

		if (currentIndex === -1) {
			newChecked.push(value);
		} else {
			newChecked.splice(currentIndex, 1);
		}

		setChecked(newChecked);
	};

	return (
		<Wrapper history={history}>
			{(users?.isLoading || profile_loading)
				? <LoadingPanel/>
				: <>
					<Grid2 className={"main-page-container-wrapper container-wrapper"}>
						<ContentGrid
							direction={"row"}
							alignItems={"flex-start"}
							alignContent={"center"}
							id={"profile-header-page-container-my-users"}
							className={"container-wrapper"}
							item
							container
						>
							<Grid2
								size={{
									xs: 8, sm: 8, md: 8, lg: 8, xl: 8,
								}}
								justifyContent={"center"}
								direction={"column"}
								container
								item
							>
								<Typography
									variant={"subtitle1"}
									className={"hello-individual"}
									align={"center"}
								>
									Welcome, {props?.profile_data?.title}!
								</Typography>
								<Typography
									component={"p"}
									variant={"caption"}
									className={"this-is-your-retirement-story"}
									align={"center"}
								>
									Here you can manage existing users or add new users.
								</Typography>
							</Grid2>
						</ContentGrid>
					</Grid2>

					<div className={"dark-bg-container"}>
						<Grid2 className={"container-wrapper"}>
							{(flagCreate === true)
								? <UserCreationPanel
									employers={props?.employers?.list ?? []}
									getEmployerList={getEmployerList}
									last_page={props?.employers?.last_page ?? 1}
									select_input_loading={props?.employers?.select_input_loading ?? false}
									page={page}
									setPage={setPage}
									profile_data={profile_data}
									flagCreate={flagCreate}
									setFlagCreate={setFlagCreate}
									flagEdit={flagEdit}
									setFlagEdit={setFlagEdit}
									updateUser={updateUser}
									onPressCreate={(data) => {
										createUser(data);
										setFlagCreate(!flagCreate);
									}}
									onPressEditUser={(data) => {
										handleEditUserData(data);
										setFlagEdit(!flagEdit);
										setFlagCreate(!flagCreate);
									}}
									open={open}
									setOpen={setOpen}
									handleToggle={handleToggle}
									checked={checked}
									users={profile_data.users}
									setChecked={setChecked}
								/>
								: <UserTablePanel
									users={profile_data?.users}
									profile_data={profile_data?.userLogged}
									onPressCreateUser={() => {
										setFlagCreate(!flagCreate);
									}}
									onPressDeleteUser={data => handleDeleteUserData(data)}
									onPressEditUser={(data) => {
										setUpdateUser(data);
										setFlagEdit(!flagEdit);
										setFlagCreate(!flagCreate);
									}}
								/>
							}
						</Grid2>
					</div>
				</>
			}
		</Wrapper>
	);
};

const mapStateToProps = state => {
	return {
		...state.Profile,
		...state.Employer,
	}
};

const mapDispatchToProps = dispatch => ({
	me: dispatch(me()),
	getEmployerList: (queryString = null, data = {
		advisors: {},
	}) => dispatch(getEmployerList(data,queryString)),
	createUser: (userdata) => dispatch(createUser(userdata)),
	deleteUser: (userdata) => dispatch(deleteUser(userdata)),
	editUser: (userdata) => dispatch(editUser(userdata)),
	checkIsSuper: (userdata) => dispatch(checkIsSuper(userdata)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Users);
