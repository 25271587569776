import React, {useEffect} from "react";
import {connect} from "react-redux";
import Wrapper from "../../layout/Logged/Wrapper";
import Loading from "../../components/Loading";
import {me} from "../../actions/Profile";
import {PrimaryHeader} from "../../components/Headers/PrimaryHeader";
import OverviewIfaDashboard from "../../components/IFA/OverviewIfaDashboard";
import Grid2 from '@mui/material/Grid2';
import {Tab} from "@mui/material";
import {CountIndividuals} from "../../components/IFA/CountIndividulas";
import {BeneficiaryCard} from "../../components/IFA/BeneficiaryCard";
import {CountEmployers} from "../../components/CountEmployers";

/**
 * @param me
 * @param profile_data
 * @param profile_loading
 * @param history
 * @param employerView
 * @returns {JSX.Element}
 * @constructor
 */
const IfaDashboard = (
	{
		me = () => {
		},
		profile_data = [],
		profile_loading = false,
		history = {},
		employerView = false,
	}
) => {

	useEffect(() => {
			me();
		},
		[
			me,
		]
	);

	if (!profile_data || profile_loading || !profile_data?.is_ifa_advisor) {
		return (
			<Loading absolute/>
		);
	}

	if (profile_data?.email) {
		window.$crisp.push([
			"set",
			"user:email",
			profile_data.email
		]);
		window.$crisp.push([
			"set",
			"user:nickname",
			[
				`${profile_data?.userLogged?.forename} ${profile_data?.userLogged?.surname}`
			]
		]);
		window.$crisp.push(["set", "user:phone", [profile_data?.telephone]]);
	}

	return (
		<Wrapper
			history={history}
			profile_data={profile_data}
			isIFA={true}
		>

			<PrimaryHeader>
				IFA Dashboard
			</PrimaryHeader>
			{
				employerView === false ?
					<CountIndividuals
						individuals_count={profile_data?.individuals_count}
					/>
					:
					<CountEmployers
						companies_count={profile_data?.employers_count}
					/>
			}

			{
				profile_data.is_ifa_advisor && profile_data.is_payroll_advisor && (
					<Grid2
						size={{
							xs: 12, sm: 12, md: 12, lg: 12, xl: 12,
						}}
						direction={"row"}
						alignItems={"center"}
						justifyContent={"center"}
						container
						item
					>
						<Grid2
							size={{
								xs: 6, sm: 6, md: 6, lg: 6, xl: 6,
							}}
							className={"tabs-inactive"}
							textAlign={"center"}
							item
							onClick={() => window.location.href = '/main-page'}
						>
							<Tab label={"Payroll Dashboard"}/>
						</Grid2>
						<Grid2
							size={{
								xs: 6, sm: 6, md: 6, lg: 6, xl: 6,
							}}
							className={"tabs-active"}
							textAlign={"center"}
							item
							onClick={() => window.location.href = '/ifa-dashboard'}
						>
							<Tab label={"IFA Dashboard"}/>
						</Grid2>
					</Grid2>
				)
			}
			{
				!profile_data?.has_beneficiary && profile_data?.is_ifa_advisor &&
				<BeneficiaryCard
					history={history}
				/>
			}
			<div>
				<div className={"main-page-container-wrapper"}>
					<OverviewIfaDashboard
						history={history}
					/>
				</div>
			</div>
		</Wrapper>
	);
}

export default connect(
	(state) => ({
		...state.Profile,
		...state.EmployersIFA
	}),
	(dispatch) => ({
		me: () => dispatch(me()),
	})
)(IfaDashboard);
