import React from "react";
import {Tooltip} from "@mui/material";
import CompareArrowsIcon from '@mui/icons-material/CompareArrows';
import Grid2 from '@mui/material/Grid2';
import Button from "@mui/material/Button";

/**
 * @param advisor_id
 * @param requestAccess
 * @param selectedEmployees
 * @param ifaFee
 * @returns {JSX.Element}
 */
const customToolbarSelectEmployees = (
	{
	                                      advisor_id = 0,
	                                      requestAccess = () => {
	                                      },
	                                      selectedEmployees = [],
	                                      ifaFee,
                                      }
									  ) => (
	<Grid2
		marginRight={2}
		direction={"row"}
		container
		item
		justifyContent={"flex-end"}
	>
		<Grid2
			marginRight={2}
		>
			<Tooltip title={"Change Funds"} className={"success-button-toolbar"}>
				<Button
					onClick={() => requestAccess({
						advisors: {
							selectedEmployees,
							advisor_id: advisor_id,
							ifa_fee: ifaFee,
						}
					})}
				>
					Request Access
					<CompareArrowsIcon/>
				</Button>
			</Tooltip>
		</Grid2>
	</Grid2>
);

/**
 * @stateless
 * @param props
 * @returns {*}
 */
export default customToolbarSelectEmployees;
