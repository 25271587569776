import {connect, useDispatch} from "react-redux";
import React, {useEffect} from "react";
import Wrapper from "../layout/Logged/Wrapper";

import {me} from "../actions/Profile";
import OptOutTab from "../components/CheckOptOuts/OptOutTab";
import {getOptOut} from "../actions/OptOut";
import LoadingPanel from "../components/LoadingPanel";
import {PrimaryHeader} from "../components/Headers/PrimaryHeader";
import {ModalContents} from "../utils/ModalContents";
import Grid2 from "@mui/material/Grid2";

/**
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const CheckOptOuts = (props) => {
	const {
		history,
		me = () => {},
	} = props;
	let myIndividualsOptList;
	let dispatch;
	dispatch = useDispatch();

	useEffect(() => {
		me();
		dispatch(getOptOut(props.profile_data.id));
	}, [me,props.profile_data.id,dispatch]);
	myIndividualsOptList = props.myoptoutlist;
	if (props.profile_loading) {
		return (
			<Grid2
				size={{
					xs: 12, sm: 12, md: 12, lg: 12, xl: 12,
				}}
				style={{
					width: "100%",
				}}
				container
			>
				<LoadingPanel/>
			</Grid2>
		);
	}
	return (
		<Wrapper history={history} profile_data={props?.profile_data}>
			<div>
				<PrimaryHeader
					backIcon
					helpIcon
					helpModal={ModalContents.opt_outs_help}
				>
					Opt Outs
				</PrimaryHeader>
				<OptOutTab
					myIndividualsOptList={myIndividualsOptList}
				/>
			</div>
		</Wrapper>
	);
};

export default connect(
	(state) => ({
		...state.Profile,
		...state.OptOut,
	}),
	(dispatch) => ({
		me: () => dispatch(me()),
	})
)(CheckOptOuts);
