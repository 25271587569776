import React, {useState, useEffect} from "react";

import Wrapper from "../layout/Logged/Wrapper";
import Slide from "@mui/material/Slide";
import MinimalFooter from "../layout/MinimalFooter";
import {connect} from "react-redux";
import {SIGNUP_ACCOUNT_ACTIVATION_RESET} from "../actions/SignUp/type";
import {me, updateAdvisor} from "../actions/Profile";
import LoadingPanel from "../components/LoadingPanel";
import ContributionPaymentDate from "../components/DirectDebit/ContributionPaymentDate";
import Loading from "../components/Loading";
import {useNavigate} from "react-router-dom";
import Grid2 from "@mui/material/Grid2";

/**
 * @param stepIndex
 * @param profile_data
 * @param paymentDate
 * @param history
 * @param setStepIndex
 * @param setPaymentDate
 * @param update
 * @returns {JSX.Element}
 * @private
 */
const _renderContent = (
	stepIndex: number = 0,
	profile_data: any = [],
	paymentDate: number = 0,
	history: any = [],
	setStepIndex: Function = () => {
	},
	setPaymentDate: Function = () => {
	},
	update: Function = () => {
	}
) => {
	switch (stepIndex) {
		case 0:
			return <ContributionPaymentDate
				paymentDate={paymentDate}
				profile_data={profile_data}
				setStepIndex={setStepIndex}
				setPaymentDate={setPaymentDate}
				update={update}
				history={history}
			/>;

		case 1:
			const contributionValue = process.env.REACT_APP_ENV === 'production' ? 0.10 : 0.30,
				step = "Monthly";
			history(`/select-banks`, {
				state: {
					step,
					contributionValue,
					paymentDate,
					profile_data
				},
			});
		break;

		default:
			return [];
	}
}
/**
 * @returns {JSX.Element}
 * @constructor
 * @param props
 */
const ActivateAccount = (props = {
	history: false,
}) => {
	const {
		profile_loading = false,
		profile_data = [],
		update = () => {},
		me = () => {}
	} = props;
	const history = useNavigate();

	const [paymentDate, setPaymentDate] = useState(10),
		[stepIndex, setStepIndex] = useState(props?.history?.location?.state?.activation_step ?? 0);

	useEffect(() => me(), [
		me,
	]);

	if (profile_loading || !profile_data) {
		return (
			<Grid2
				size={{
					xs: 12, sm: 12, md: 12, lg: 12, xl: 12,
				}}
			>
				<Loading />
			</Grid2>
		);
	}

	return (
		<Wrapper
			history={history}
		>
			{
				profile_loading ?
					<LoadingPanel/>
				:
					<Slide
						direction="up"
						in={true}
						mountOnEnter
						unmountOnExit
					>
						<div
							id={"activeaccount-wrapper"}
						>
							{
								_renderContent(
									stepIndex,
									profile_data,
									paymentDate,
									history,
									setStepIndex,
									setPaymentDate,
									update
								)
							}
							<MinimalFooter/>
						</div>
					</Slide>
			}
		</Wrapper>
	);
}

/**
 * @param state
 * @returns {*}
 */
const mapStateToProps = state => {
	const {
		Profile = [],
		DirectDebit = {},
	} = state;

	return {
		...Profile,
		...DirectDebit,
	};
};

const mapDispatchToProps = dispatch => ({
	me: () => dispatch(me()),
	resetActivationFlow: () => {
		dispatch({type: SIGNUP_ACCOUNT_ACTIVATION_RESET})
	},
	update: (data = {}) => dispatch(updateAdvisor(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(ActivateAccount);
