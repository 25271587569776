import {useTheme} from '@mui/material';
import {connect} from "react-redux";
import {getBulkEmployersUploadDetails} from "../../actions/Employers";
import React, {useEffect} from "react";
import LoadingPanel from "../../components/LoadingPanel";
import {ThemeProvider} from "@mui/styles";
import MUIDataTable from "mui-datatables";
import Grid2 from "@mui/material/Grid2";

const BulkEmployersUploadDetails = (
	{
		bulkEmployersUploadDetails = () => {},
		bulkEmployersDetails = {
			isLoading: false,
			bulk_employers_details: []
		},
		match = {
			params: {
				file_upload_id: 0
			}
		},
	}
) => {
	const theme = useTheme({
		breakpoints: {
			md: '800px'
		}
	});

	useEffect(() => {
		bulkEmployersUploadDetails(match.params.file_upload_id);
	}, [bulkEmployersUploadDetails, match.params.file_upload_id]);


	if (bulkEmployersDetails?.isLoading) {
		return (
			<Grid2
				size={{
					xs: 12, sm: 12, md: 12, lg: 12, xl: 12,
				}}
				style={{
					width: "100%",
				}}
				container
			>
				<LoadingPanel/>
			</Grid2>
		);
	}

	let columns = [];

	if (!bulkEmployersDetails?.isLoading && bulkEmployersDetails?.bulk_employers_details.length > 0) {
		Object.keys(bulkEmployersDetails?.bulk_employers_details[0]).map(key => {
			columns.push({
				name: key,
				label: key.replace(/_/g, " ").toUpperCase(),
				options: {
					setCellProps: () => ({className: "table-cell-item-subtitle", align: 'center'})
				}
			});
			return true;
		})
	}

	return (
		bulkEmployersDetails?.bulk_employers_details.length > 0 ?
			<ThemeProvider theme={theme}>
				<MUIDataTable
					className={"data-table bulk-employers-details-table"}
					columns={columns}
					data={bulkEmployersDetails?.bulk_employers_details}
					options={{
						print: false,
						elevation: 1,
						filter: false,
						selectableRowsHeader: false,
						selectableRows: "none",
						responsive: "simple",
						viewColumns: false,
					}}
				/>
			</ThemeProvider>
		:
			<h2
				className={"no-records-found"}
			>
				Sorry, no matching records found
			</h2>
	);
}


const mapStateToProps = state => {
	const { Employer } = state;

	return {
		bulkEmployersDetails: Employer,
	}
};

export default connect(
	mapStateToProps,
	dispatch => ({
		bulkEmployersUploadDetails: (file_upload_id = 0) => dispatch(getBulkEmployersUploadDetails(file_upload_id)),
	})
)(BulkEmployersUploadDetails);
