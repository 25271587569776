import FormControl from "@mui/material/FormControl";
import Button from "@mui/material/Button";
import Grid2 from '@mui/material/Grid2';
import PropTypes from "prop-types";

/**
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const WrongInfo = (props = {
	history: {
		push: () => {
		},
	}
}): JSX.Element => {

	return (
		<Grid2
			size={{
				xs: 12, sm: 12, md: 12, lg: 12, xl: 12,
			}}
			alignItems={"center"}
			alignContent={"center"}
			direction={"column"}
			justifyContent={"center"}
			item
			container
		>
			<FormControl
				className={"signup-form-control-2 forgot-password"}
				fullWidth
				align={"center"}
			>
				<small>Wrong info?</small>
			</FormControl>

			<FormControl
				className={"signup-form-control-2 forgot-password"}
				fullWidth
				align={"center"}
			>
			<span>
				<Button
					variant="text"
					color="primary"
					style={{
						textTransform: "lowercase",
						padding: 0,
						minHeight: 0,
						minWidth: 0,
					}}
					onClick={() => props.history("/add-new-company")}
				>
					Search again
				</Button>
				{"   "}/{"   "}
				<Button
					variant="text"
					color="primary"
					style={{
						textTransform: "lowercase",
						padding: 0,
						minHeight: 0,
						minWidth: 0,
					}}
					onClick={() => props.history("/add-new-company", {
						state: {
							enterCompany: "block",
						},
					})}
				>
					enter manually
				</Button>
			</span>
			</FormControl>
		</Grid2>
	);
}

WrongInfo.propTypes = {
	history: PropTypes.shape({
		history: PropTypes.shape({
            push: PropTypes.func,
        }),
	}).isRequired,
};

export default WrongInfo;
