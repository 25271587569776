import React from 'react';


import FormControl from '@mui/material/FormControl';
import Grid2 from '@mui/material/Grid2';
import Slide from '@mui/material/Slide';
import Typography from '@mui/material/Typography';
import Wrapper from "../../layout/Logged/Wrapper"
import MinimalFooter from "../../layout/MinimalFooter";
import SuccessImageNotExists from '../../images/success-not-exists.png';
import SuccessImageExists from '../../images/success-exsits.png';
import Button from "@mui/material/Button";
import {connect} from "react-redux";
import LoadingPanel from "../../components/LoadingPanel";
import {useLocation, useNavigate} from "react-router-dom";

/**
 * @param profile_loading
 * @param activation_loading
 * @returns {JSX.Element}
 * @constructor
 */
const Success = (
	{
		profile_loading = false,
		activation_loading = false,
	}
) => {
	const
		location = useLocation(),
		history = useNavigate();

	if (profile_loading || activation_loading)
		return (
			<Grid2
				size={{
					xs: 12, sm: 12, md: 12, lg: 12, xl: 12,
				}}
				style={{
					width: "100%",
				}}
				container
			>
				<LoadingPanel/>
			</Grid2>
		);

	let image = location?.state?.exists ? SuccessImageExists : SuccessImageNotExists;
	let successTitle = location?.state?.exists ?
		location?.state?.companyName + ' already has a Collegia Account!'
		:
		location?.state?.send_email ?
			'Done, setup complete!'
			:
			'Done, instructions sent!'

	let successSubtitle = location?.state?.exists ?
		<span>
			We have sent them instructions on how to proceed.
			Now you have to wait for them to give you authorisation
			to access their Collegia account.
		</span>
		:
		location?.state?.send_email ?
			<span>
				<strong>
					{
						location?.state?.company_title ?
							location?.state?.company_title + " "
						:
							location?.state?.companyName + " "
					}
					was added successfully.
				</strong>
				<br/>
				We have sent your client's username and password to the email provided.
				They can change their password after login for the first time.
			</span>
			:
			<span>
				<strong>
					{
						location?.state?.company_title ?
							location?.state?.company_title + " "
						:
							location?.state?.companyName + " "
					}
					was added successfully.
				</strong>
				<br/>
				We have sent the link to complete the sign-up to the email provided.
				They will have to create login credentials, accept the Terms & Conditions,
				sign the Direct Debit Instruction and select the date contributions
				will be collected from their account.
			</span>

	return (
		<Wrapper>
			<Slide
				direction={"up"}
				in={true}
				mountOnEnter
				unmountOnExit
			>
				<div id={"forgot-password-requested-wrapper"}>
					<Grid2
						size={{
							xs: 12, sm: 12, md: 12, lg: 12, xl: 12,
						}}
						id={"login-container"}
						alignItems={"center"}
						alignContent={"center"}
						direction={"column"}
						container
						item
					>
						<form
							noValidate
							autoComplete="off"
						>
							<FormControl
								className={"login-form-control-2 first-title"}
								fullWidth
							>

								<Grid2
									size={{
										xs: 12, sm: 12, md: 12, lg: 12, xl: 12,
									}}
									alignItems={"center"}
									alignContent={"center"}
									direction={"column"}
									container
									item
								>
									<img
										src={image}
										alt={"Password forgot e-mail sent."}
										width={450}
									/>
								</Grid2>
							</FormControl>
							<FormControl
								className={"login-form-control-2"}
								fullWidth
							>
								<Typography
									align={"center"}
									className={"success-title"}
								>
									{successTitle}
								</Typography>
							</FormControl>
							<FormControl
								className={"login-form-control-2"}
								fullWidth
							>
								<Typography
									align={"center"}
									className={"success-subtitle"}
								>
									{successSubtitle}
								</Typography>
							</FormControl>

							<FormControl
								className={"login-form-control"}
								fullWidth
							>
								<Button
									variant="contained"
									onClick={() => history("/")}
									className={"success-button-contained"}
								>
									GO BACK TO DASHBOARD
								</Button>
							</FormControl>

							<FormControl
								className={"login-form-control"}
								fullWidth
							>
								<Button
									variant={"outlined"}
									color={"primary"}
									onClick={() => history("/add-new-company")}
								>
									ADD ANOTHER COMPANY
								</Button>
							</FormControl>
						</form>
					</Grid2>
					<MinimalFooter />
				</div>
			</Slide>
		</Wrapper>
	);
}

export default connect(
	(state) => ({
		...state.SignUp,
	}),
	(dispatch) => ({
	}),
)(Success);
