import Wrapper from "../layout/Logged/Wrapper";
import {useNavigate, useParams} from "react-router-dom";
import {me} from "../actions/Profile";
import {connect} from "react-redux";
import {PrimaryHeader} from "../components/Headers/PrimaryHeader";
import MUIDataTable from "mui-datatables";
import React, {useEffect} from "react";
import { getEmployeeCommunications} from "../actions/Employers";
import Loading from "../components/Loading";

/**
 * @param profile_data
 * @param employees_communications
 * @param is_loading
 * @param getCommunications
 * @returns {JSX.Element}
 * @constructor
 */
const EmployeesCommunications = (
	{
		profile_data = {},
		employees_communications = [],
		is_loading = false,
		getCommunications = () => {},
	}
) => {
	const history = useNavigate();


	let id = useParams().id;

	useEffect(() => {
		getCommunications({
			advisors: {
				id: id,
			}
		});
	}, [id, getCommunications]);

	const columns = [
		{
			"name": "forename",
			"label": "Forename",
		},
		{
			"name": "surname",
			"label": "Surname",
		},
		{
			"name": "email",
			"label": "EmaiL",
		},
		{
			"name": "national_insurance_number",
			"label": "NIN",
		},
		{
			"name": "status",
			"label": "Email status",
		},
		{
			"name": "sent_date",
			"label": "Sent date",
		},
		{
			"name": "description",
			"label": "Email description",
		},
	];

	if (is_loading) {
		return (
			<Loading absolute/>
		);
	}

	return (
		<Wrapper history={history} profile_data={profile_data}>
			<div>
				<PrimaryHeader
					backIcon
				>
					Employees communications
				</PrimaryHeader>
				<MUIDataTable
					className={"data-table optout-table"}
					columns={columns}
					data={employees_communications}
					options={{
						filter: false,
						selectableRowsHeader: false,
						selectableRows: "none",
						responsive: "simple",
						viewColumns: false,
					}}
					pagination
				/>
			</div>
		</Wrapper>
	);
}

const mapStateToProps = state => {
	return {
		...state.Profile,
		...state.Employer,
	};
};

const mapDispatchToProps = () => dispatch => ({
	me: dispatch(me()),
	getCommunications: (data) => dispatch(getEmployeeCommunications(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(EmployeesCommunications);
