import {connect} from 'react-redux';
import {logout} from "../actions/Profile";

import Typography from "@mui/material/Typography";
import Grid2 from '@mui/material/Grid2';

/**
 *
 * @returns {JSX.Element}
 * @constructor
 */
const LogoutScreen = () => {

	window.$crisp.push(["do", "session:reset"]);

	return (
		<Grid2
			size={{
				xs: 12, sm: 12, md: 12, lg: 12, xl: 12,
			}}
			direction={"row"}
			alignItems={"center"}
			alignContent={"center"}
			justifyContent={"center"}
			container
			item
		>
			<Grid2
				size={{
					xs: 12, sm: 12, md: 12, lg: 12, xl: 12,
				}}
				justifyContent={"center"}
				container
				item
			>
				<Typography variant={"h4"}>
					You just logged out
				</Typography>
			</Grid2>

			<Grid2
				size={{
					xs: 12, sm: 12, md: 12, lg: 12, xl: 12,
				}}
				justifyContent={"center"}
				item
				container
			>
				<Typography>
					You will be redirected to the login page shortly
				</Typography>
			</Grid2>
		</Grid2>
	)
}

export default connect(null, dispatch => ({
	logout: dispatch(logout())
}))(LogoutScreen);
