import React, {useState} from "react";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Typography from "@mui/material/Typography";
import AccordionDetails from "@mui/material/AccordionDetails";
import LoadingPanel from "../LoadingPanel";
import MUIDataTable from "mui-datatables";
import CustomToolbar from "./CustomToolbar";
import Accordion from "@mui/material/Accordion";
import CustomToolbarSelectEmployers from "./CustomToolbarSelectEmployers";
import Grid2 from '@mui/material/Grid2';
import Loading from "../Loading";

/**
 * @param setSearchFindText
 * @param setSelectedEmployers
 * @param setEmployerView
 * @param setSearchFindTextWithTimeOut
 * @param employers_loading_table
 * @param employers_ifa
 * @param employersColumns
 * @param getIfaEmployers
 * @param employerView
 * @param selectedEmployers
 * @param searchFindText
 * @param advisor_id
 * @param employerIfaAccess
 * @returns {JSX.Element}
 * @constructor
 */
export const EmployersView = (
	{
	setSearchFindText = () => {},
	setSelectedEmployers = () => {},
	setEmployerView = () => {},
	setSearchFindTextWithTimeOut = () => {},
    employers_loading_table = false,
	employers_ifa = [],
	employersColumns = [],
	getIfaEmployers = () => {},
	employerView = false,
	selectedEmployers = [],
	searchFindText = "",
	advisor_id = 0,
	employerIfaAccess = () => {},
}
) => {

	const [sortColumn, setSortColumn] = useState("");
	const [sortDirection, setSortDirection] = useState("asc");
	const [actualPage, setActualPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(10);

	return (
		<Accordion id={"overview-table-accordion"}>
			<AccordionSummary
				expandIcon={
					<ExpandMoreIcon className={"accordion-arrow-icon"}/>
				}
				className={"overview-table-accordion-summary"}
			>
				<Typography variant={"h5"}>
					Manage Your Employers
				</Typography>

			</AccordionSummary>
			<AccordionDetails>
				{
					employers_loading_table ?
						<LoadingPanel/>
						:
						<MUIDataTable
							className={"data-table individuals-table"}
							columns={employersColumns}
							data={employers_ifa?.data ?? []}
							options={{
								searchPlaceholder: "Search a employer",
								searchOpen: true,
								searchAlwaysOpen: true,
								searchText: searchFindText,
								search: false,
								print: false,
								elevation: 0,
								filter: false,
								selectableRowsHeader: true,
								selectableRows: "multiple",
								responsive: "simple",
								viewColumns: false,
								serverSide: true,
								count: employers_ifa?.total,
								page: employers_ifa?.current_page - 1 || 0,
								rowsPerPage: rowsPerPage,
								sortOrder: {name: sortColumn ?? "", direction: sortDirection ?? ""},
								textLabels: {
									body: {
										noMatch: employers_loading_table ?
											<Grid2
												size={{
													xs: 12, sm: 12, md: 12, lg: 12, xl: 12,
												}}
												marginTop={50}
											>
												<Loading />
											</Grid2>
											:
											'Sorry, there is no matching data to display',
									},
								},

								customToolbar: () => {
									return (
										<CustomToolbar
											setSearchFindText={setSearchFindText}
											setSortColumn={setSortColumn}
											setSortDirection={setSortDirection}
											setActualPage={setActualPage}
											setRowsPerPage={setRowsPerPage}
											setSearchFindTextWithTimeOut={setSearchFindTextWithTimeOut}
											employerView={employerView}
											setEmployerView={setEmployerView}
										/>
									);
								},

								customToolbarSelect: () => {
									return (
										<CustomToolbarSelectEmployers
											selectedEmployers={selectedEmployers}
											employerIfaAccess={employerIfaAccess}
											advisor_id={advisor_id}
										/>
									);
								},

								isRowSelectable: (dataIndex: number) => {
									return (employers_ifa?.data[dataIndex]?.ifa_allowed === null && employers_ifa?.data[dataIndex]?.requested_access === null)
								},

								onRowSelectionChange: (
									currentRowsSelected: [],
									allRowsSelected: [],
								) => {
									setSelectedEmployers([]);
									const selectedEmployers = [];
									allRowsSelected.forEach((row) => {
										selectedEmployers.push(employers_ifa?.data[row.dataIndex]);
									});
									setSelectedEmployers(selectedEmployers);
								},

								onChangeRowsPerPage: (numberOfRows: number) => {
									setRowsPerPage(numberOfRows);
									if (sortColumn === "") {
										getIfaEmployers({
											ifa_employers: {
												advisor_id,
												advisors: {},
											}
										}, {
											perPage: numberOfRows,
											page: actualPage,
										})
									} else {
										getIfaEmployers({
											ifa_employers: {
												advisor_id,
												sort: [
													true,
													sortColumn,
													sortDirection
												]
											},
										}, {
											perPage: numberOfRows,
											page: actualPage,
										})
									}
								},

								onChangePage: (currentPage: number) => {
									if (sortColumn === "") {
										getIfaEmployers({
											ifa_employers: {
												advisor_id,
												advisors: {},
											}
										}, {
											perPage: rowsPerPage ?? 10,
											page: currentPage + 1,
										});
										setActualPage(currentPage);
									} else {
										getIfaEmployers({
											ifa_employers: {
												advisor_id,
												sort: [
													true,
													sortColumn,
													sortDirection
												]
											},
										}, {
											perPage: rowsPerPage ?? 10,
											page: currentPage + 1,
										});
										setActualPage(currentPage);
									}
								},

								onColumnSortChange: (changedColumn: string) => {
									setSortColumn(changedColumn);
									setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
									switch (changedColumn) {
										case "title":
											getIfaEmployers({
												ifa_employers: {
													advisor_id,
													sort: [
														true,
														changedColumn,
														sortDirection
													]
												},
											}, {
												perPage: rowsPerPage ?? 10,
												page: actualPage + 1,
											});
											break;
										default:
											break;
									}
								},

								onSearchChange: (searchText) => {
									setSearchFindText(searchText ?? "");
									setTimeout(() => setSearchFindTextWithTimeOut(searchText), 2000);
								},


							}}
						/>
				}
			</AccordionDetails>
		</Accordion>
	);
};
