import Grid2 from '@mui/material/Grid2';
import HelpOutline from "@mui/icons-material/HelpOutline";
import ArrowBackIos from "@mui/icons-material/ArrowBackIos";

import {useState} from "react";
import GenericModal from "../GenericModal";
import { useNavigate } from 'react-router-dom';

export const PrimaryHeader = (props = {
	backIcon: false,
	helpIcon: false,
	modalTitle: "",
	modalBody: {},
}) => {
	const [show, setShow] = useState(true);
	const [isModalVisible, setModalVisible] = useState(false);
	let history = useNavigate();
	return (
		<Grid2
			size={{
				xs: 12, sm: 12, md: 12, lg: 12, xl: 12,
			}}
			className={"primary-header"}
			container
			alignItems={"center"}
			justifyContent={"space-evenly"}
		>
			<Grid2
				size={{
					xs: 1, sm: 1, md: 1, lg: 1, xl: 1,
				}}
				item
				container
				justifyContent={"center"}
				alignItems={"center"}
			>
				{props?.backIcon ?
					<ArrowBackIos
						cursor={"pointer"}
						onClick={() => history("/")}
					/>
					: ""
				}
			</Grid2>

			<Grid2
				size={{
					xs: 10, sm: 10, md: 10, lg: 10, xl: 10,
				}}
				item
				container
				justifyContent={"center"}
				alignItems={"center"}
			>
				{props.children}
			</Grid2>

			{isModalVisible &&
				<GenericModal
					show={show}
					setShow={setShow}
					setModalVisible={setModalVisible}
					title={props?.helpModal?.title}
					body={props?.helpModal?.body()}
				/>
			}

			<Grid2
				size={{
					xs: 1, sm: 1, md: 1, lg: 1, xl: 1,
				}}
				item
				container
				justifyContent={"center"}
				alignItems={"center"}
			>
				{props?.helpIcon ?
					<HelpOutline
						cursor={"pointer"}
						onClick={() => setModalVisible(true)}
					/>
					: ""
				}
			</Grid2>
		</Grid2>
	);
}
