import React from "react";

import Modal from "@mui/material/Modal";
import Grid2 from '@mui/material/Grid2';
import Typography from "@mui/material/Typography";


/**
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
export default function GenericModal(props) {
	const [open, setOpen] = React.useState(false),

		handleOpen = () => {
			setOpen(true);
		},
		handleClose = () => {
			setOpen(false);
			props?.setModalVisible(false);
		};

	// if parent show state is true and modal is invisible then turn modal visible
	if (props.show && !open) {
		handleOpen();
	}

	const body = (
		<Grid2
			justifyContent={"flex-start"}
			container
		>
			<div
				className={"generic-modal-paper"}
			>
				<Grid2
					justifyContent={"flex-start"}
					container
				>
					<Grid2
						item
					>
						<h2
							className={"generic-modal-title"}
						>
							{props?.title}
						</h2>
					</Grid2>
				</Grid2>
				<Grid2
					size={{
						xs: 12, sm: 12, md: 12, lg: 12, xl: 12,
					}}
					justifyContent={"flex-start"}
					container
					item
				>
					<Grid2
						className={"generic-modal-body"}
						item
					>
						<Typography
							component={"div"}
							variant={"subtitle1"}
						>
							{props?.body}
						</Typography>
					</Grid2>
				</Grid2>

				<Grid2
					container
					justifyContent={"flex-end"}
				>
					<button
						onClick={handleClose}
						className={"MuiCardHeader-button generic-modal-button"}
					>
						OK
					</button>
				</Grid2>
			</div>
		</Grid2>
	);

	return (
		<div>
			<Modal
				open={open}
				onClose={handleClose}
				aria-labelledby={"simple-modal-title"}
				aria-describedby={"simple-modal-description"}
				className={"generic-modal"}
			>
				{body}
			</Modal>
		</div>
	);
}
