import React, {useEffect, useRef, useState} from "react";
import {connect, useDispatch} from "react-redux";
import Typography from "@mui/material/Typography";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Grid2 from '@mui/material/Grid2';
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import {createAutoCorrectedDatePipe, onChange, validateEmail, validStagingDate} from "../utils/Helpers";
import {me} from "../actions/Profile";
import SnackBar from "../components/SnackBar";
import MaskedInput from "react-text-mask";
import CompanyDefaultInformation from "./ReferCompanyManually/CompanyDefaultInformation";
import Address from "./ReferCompanyManually/Address";
import RegisteredAddress from "./ReferCompanyManually/RegisteredAddress";
import {addCompanyManually, verifyEmail} from "../actions/Employers";
import GetStagingDateByApi from "./GetStagingDateByApi";
import MenuItem from "@mui/material/MenuItem";
import {FormLabel, Select} from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import CircularProgress from "@mui/material/CircularProgress";
import {Check, Close} from "@mui/icons-material";
import PhoneInput from "react-phone-input-2";
import {isValidPhoneNumber} from "libphonenumber-js";

/**
 * @param stagingDate
 * @param company_business_name
 * @param selectedOption
 * @param company_number
 * @param company_trading_address_line_1
 * @param company_trading_country
 * @param company_trading_locality
 * @param trading_address_postcode
 * @param company_registered_country
 * @param company_registered_address_line_1
 * @param company_registered_locality
 * @param registered_address_postcode
 * @param forename
 * @param surname
 * @param email
 * @param telephone
 * @param is_employer_under_bureau
 * @param canProceed
 * @param checkConfirmAuthorised
 * @param error
 * @param is_b2b_b2c
 * @param terms_accepted
 * @returns {boolean}
 */
const disableContinueButton = (
	stagingDate = "",
	company_business_name = "",
	selectedOption = "",
	company_number = "",
	company_trading_address_line_1 = "",
	company_trading_country = "",
	company_trading_locality = "",
	trading_address_postcode = "",
	company_registered_country = "",
	company_registered_address_line_1 = "",
	company_registered_locality = "",
	registered_address_postcode = "",
	forename = "",
	surname = "",
	email = "",
	telephone = "",
	is_employer_under_bureau,
	canProceed = false,
	checkConfirmAuthorised = false,
	error,
	is_b2b_b2c = false,
	terms_accepted = false,
): boolean => {
	let disabled;
	disabled = !validStagingDate(stagingDate ?? "") || (
			!company_business_name ||
			!selectedOption ||
			!company_number ||
			!company_trading_address_line_1 ||
			!company_trading_locality ||
			!company_trading_country ||
			!trading_address_postcode ||
			!registered_address_postcode ||
			!company_registered_address_line_1 ||
			!company_registered_locality ||
			!company_registered_country ||
			!forename ||
			!surname ||
			!validateEmail(email) ||
			!isValidPhoneNumber("+" + telephone) ||
			!canProceed
		) ||
		!checkConfirmAuthorised ||
		!terms_accepted ||
		error;

	if (is_b2b_b2c) {
		if (is_employer_under_bureau === "") {
			disabled = true;
		}
	}

	return disabled;
}

/**
 * @param generated_company_number
 * @param generateCompanyNumber
 * @param activation_loading
 * @param canProceed
 * @param profile_data
 * @param error
 * @param setEnterCompany
 * @param emailVerifyErrorMessages
 * @param emailVerified
 * @param addCompanyManually
 * @param history
 * @returns {JSX.Element}
 * @constructor
 */
const ReferCompanyManually = (
	{
		generated_company_number = "",
		generateCompanyNumber = () => {
		},
		activation_loading = false,
		canProceed = false,
		profile_data = {
			is_b2b_b2c: false,
		},
		error = false,
		setEnterCompany = () => {
		},
		emailVerifyErrorMessages = "",
		emailVerified = false,
		addCompanyManually = () => {
		},
		history = "",
	}
) => {
	const [registeredAddressSameAsTrading, setRegisteredAddressSameAsTrading] = useState(false),
		[checkConfirmAuthorised, setCheckConfirmAuthorised] = useState(false),
		[stagingDate, setStagingDate] = useState(''),
		legalStructure =
			[
				"Limited Company",
				"Limited Partnership",
				"Limited Liability Partnership",
				"Unincorporated Association",
				"Ordinary Business Partnership",
				"Sole Trader",
				"Charity",
				"Other",
			],
		[selectedOption, setSelectedOption] = useState(''),
		[forename, handleChangeForename] = useState(''),
		[surname, handleChangeSurname] = useState(''),
		[email, handleChangeEmail] = useState(""),
		[telephone, setTelephone] = useState(""),
		[company_business_name, setCompanyBusinessName] = useState(''),
		[company_number, setCompanyNumber] = useState(''),
		[company_trading_address_line_1, setCompanyTradingAddressLine1] = useState(""),
		[company_trading_locality, setTradingLocality] = useState(""),
		[company_trading_country, setCompanyTradingCountry] = useState(""),
		[trading_address_postcode, setTradingAddressPostcode] = useState(""),
		[company_registered_address_line_1, setCompanyRegisteredAddressLine1] = useState(""),
		[company_registered_premises, setCompanyRegisteredPremises] = useState(""),
		[company_registered_locality, setCompanyRegisteredLocality] = useState(""),
		[company_registered_country, setCompanyRegisteredCountry] = useState(""),
		[registered_address_postcode, setRegisteredAddressPostcode] = useState(""),
		[company_trading_premises, setCompanyTradingPremises] = useState(""),
		[is_employer_under_bureau, setIsUmbrellaCompany] = useState(""),
		[terms_accepted, setTermsAccepted] = useState(false);
	const [stagingDateOption, setStagingDateOption] = useState(false);
	const [taxRelief, setTaxRelief] = useState("");
	const [searchingText, setSearchText] = useState(false);
	let timeout = useRef(null);
	let link = <a
		href={'https://s3.eu-west-2.amazonaws.com/public.production.collegia/Collegia+Legal+Docs/22++Employer+Terms+and+Conditions.pdf'}
		target={'_blank'} rel="noreferrer">terms and conditions.</a>;

	let dispatch = useDispatch();

	function handleSelectedOption(event) {
		let inputOption = event.target.value
		setSelectedOption(inputOption);
	}

	useEffect(() => {
		if (registeredAddressSameAsTrading) {
			setRegisteredAddressPostcode(trading_address_postcode);
			setCompanyRegisteredCountry(company_trading_country);
			setCompanyRegisteredLocality(company_trading_locality);
			setCompanyRegisteredAddressLine1(company_trading_address_line_1);
			setCompanyRegisteredPremises(company_trading_premises);
		}

		if (generated_company_number !== "") {
			setCompanyNumber(generated_company_number);
		}

		if (selectedOption === "Limited Company") {
			setCompanyNumber("");

		} else if (selectedOption === "Limited Liability Partnership") {
			setCompanyNumber("");
		}

		if (email !== "") {
			if (timeout) {
				timeout.current = clearTimeout(timeout.current);
			}
			timeout.current = setTimeout(() => {
				dispatch(verifyEmail({
					advisors: {
						primary_email: email,
					}
				}));
			}, 1000);
		}

	}, [
		registeredAddressSameAsTrading,
		trading_address_postcode,
		company_trading_country,
		company_trading_locality,
		company_trading_address_line_1,
		company_trading_premises,
		selectedOption,
		generated_company_number,
		email,
		timeout,
		dispatch,
	]);

	return (
		<Grid2
			id={"signup-container"}
			alignItems={"center"}
			alignContent={"center"}
			direction={"column"}
			spacing={0}
			style={{background: "#ffff"}}
			container
		>
			<Grid2
				size={{
					xs: 12, sm: 12, md: 12, lg: 12, xl: 12,
				}}
				id={"signup-container-detail"}
				className={"collegia-border"}
				item
				style={{padding: 20}}
			>
				<CompanyDefaultInformation
					selectedOption={selectedOption}
					company_business_name={company_business_name}
					company_number={company_number}
					legalStructure={legalStructure}

					generateCompanyNumber={generateCompanyNumber}
					handleSelectedOption={handleSelectedOption}
					setCompanyBusinessName={setCompanyBusinessName}
					setCompanyNumber={setCompanyNumber}
					setEnterCompany={setEnterCompany}
				/>


				{
					profile_data?.is_b2b_b2c && (
						<FormControl fullWidth>
							<FormLabel htmlFor={`is_employer_under_bureau`}>
								Is this is bureau company?
							</FormLabel>
							<Select
								labelId="is_employer_under_bureau"
								id="is_employer_under_bureau"
								name={"is_employer_under_bureau"}
								placeholder={`Select your answer here*`}
								value={is_employer_under_bureau}
								onChange={(event) =>
									onChange(setIsUmbrellaCompany(event.target.value))
								}
							>
								<MenuItem value={""}>Select your answer here*</MenuItem>
								<MenuItem value={true}>Yes</MenuItem>
								<MenuItem value={false}>No</MenuItem>
							</Select>
						</FormControl>
					)
				}

				<Divider style={{marginBottom: 30, width: "100%"}}/>

				<Address
					company_trading_address_line_1={company_trading_address_line_1}
					company_trading_premises={company_trading_premises}
					company_trading_locality={company_trading_locality}
					trading_address_postcode={trading_address_postcode}
					company_trading_country={company_trading_country}

					setTradingAddressPostcode={setTradingAddressPostcode}
					setCompanyTradingAddressLine1={setCompanyTradingAddressLine1}
					setCompanyTradingPremises={setCompanyTradingPremises}
					setTradingLocality={setTradingLocality}
					setCompanyTradingCountry={setCompanyTradingCountry}
				/>

				<FormControlLabel
					className={"signup-form-control-checkbox"}
					style={{marginBottom: 30}}
					control={
						<Checkbox
							name={"registeredAddressSameAsTrading"}
							id={"registeredAddressSameAsTrading"}
							value={registeredAddressSameAsTrading}
						/>
					}
					onChange={(event, value) => setRegisteredAddressSameAsTrading(value)}
					label={"Trading address is the same of registered address"}
				/>

				<RegisteredAddress
					company_registered_address_line_1={company_registered_address_line_1}
					company_registered_premises={company_registered_premises}
					company_registered_locality={company_registered_locality}
					company_registered_country={company_registered_country}
					registered_address_postcode={registered_address_postcode}
					registeredAddressSameAsTrading={registeredAddressSameAsTrading}

					setCompanyRegisteredAddressLine1={setCompanyRegisteredAddressLine1}
					setCompanyRegisteredPremises={setCompanyRegisteredPremises}
					setCompanyRegisteredLocality={setCompanyRegisteredLocality}
					setCompanyRegisteredCountry={setCompanyRegisteredCountry}
					setRegisteredAddressPostcode={setRegisteredAddressPostcode}
				/>

				<Divider style={{marginBottom: 30, width: "100%"}}/>

				<FormControl
					className={"signup-form-control-2 confirm-text"}
					fullWidth
				>
					<Typography
						align={"left"}
						style={{fontSize: 18, marginBottom: 15}}
					>
						Staging date
					</Typography>
				</FormControl>
				<Typography>
					You don't know your employer's staging date?
					<a
						href="# " onClick={() => setStagingDateOption(!stagingDateOption)}
					>
						{!stagingDateOption ? " Click here" : " Hide"}
					</a>
				</Typography>

				<FormControl
					variant={"outlined"}
					className={"login-form-control-staging-date"}
					fullWidth
					style={{marginBottom: 10, marginTop: 10}}
				>
					<MaskedInput
						mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
						placeholderChar={'\u2000'}
						className={"staging-date-input"}
						placeholder={"Staging date (dd/mm/yyyy format). *"}
						label={"Staging date"}
						variant={"outlined"}
						name={"staging"}
						id={"staging"}
						value={stagingDate}
						onChange={event => onChange(setStagingDate(event.target.value))}
						pipe={createAutoCorrectedDatePipe("dd mm yyyy", {
							minYear: 1000,
							maxYear: new Date().getFullYear()
						})}
					/>
				</FormControl>

				<FormControl
					variant={"outlined"}
					className={"login-form-control-staging-date"}
					fullWidth
					style={
						stagingDateOption ? {display: "block"} : {display: "none"}
					}
				>
					<GetStagingDateByApi
						searchingText={searchingText}
						setSearchText={setSearchText}
						setStagingDate={setStagingDate}
						taxRelief={taxRelief}
						setTaxRelief={setTaxRelief}
						dispatch={dispatch}
					/>
				</FormControl>
				<Divider style={{marginBottom: 30, marginTop: 30, width: "100%"}}/>

				<FormControl
					className={"signup-form-control-2 confirm-text"}
					fullWidth
				>
					<Typography align={"left"} style={{fontSize: 18, marginBottom: 15}}>
						Director / Authorised signatory details
					</Typography>
				</FormControl>
				<FormControl
					className={"signup-form-control-2 confirm-text"}
					fullWidth
				>
					<Typography
						align={"left"}
						style={{fontWeight: "bold", fontSize: 16, marginBottom: 15}}
					>
						A director or authorised signatory of the company will need to sign
						a participation agreement with Collegia.
					</Typography>
				</FormControl>

				<Box
					component="div"
				>
					<FormControl className={"login-form-control"} fullWidth>
						<TextField
							style={{marginBottom: 15}}
							type={"text"}
							onChange={(event) =>
								handleChangeForename(event.target.value)
							}
							label={"First Name*"}
							variant={"outlined"}
							name={"forename"}
							id={"forename"}
							value={forename}
						/>
					</FormControl>
					<FormControl className={"login-form-control"} fullWidth>
						<TextField
							style={{marginBottom: 15}}
							type={"text"}
							onChange={(event) =>
								handleChangeSurname(event.target.value)
							}
							label={"Surname*"}
							variant={"outlined"}
							name={"forename"}
							id={"forename"}
							value={surname}
						/>
					</FormControl>
					<FormControl className={"login-form-control"} fullWidth>
						<TextField
							style={{marginBottom: 30}}
							type={"email"}
							value={email}
							onChange={(event) =>
								handleChangeEmail(event.target.value)
							}
							label={"Email *"}
							variant={"outlined"}
							InputProps={{
								endAdornment: (
									<InputAdornment position="end">
										{
											email ?
												!emailVerified ?
													<CircularProgress size={'25px'}/>
													:
													emailVerified && emailVerifyErrorMessages?.length === 0 ?
														<Check color='success'/>
														:
														<Close onClick={() => handleChangeEmail('')} color="error"/>

												:
												""
										}
									</InputAdornment>
								)
							}}
							name={"email"}
							id={"email"}
						/>
						{
							<small
								className={"red-text"}
							>
								{
									email ?
										emailVerified ?
											emailVerifyErrorMessages?.primary_email
											:
											null
										:
										""
								}
							</small>
						}
					</FormControl>
					<FormControl className={"login-form-control"} fullWidth>
						<PhoneInput
							country={"gb"}
							inputStyle={{
								width: "100%",
							}}
							inputProps={{
								name: 'telephone',
								id: "telephone",
								required: true,
							}}
							searchPlaceholder={"Country name"}
							searchNotFound={"No country found, please search again."}
							specialLabel={"Mobile | Phone*"}
							enableSearch
							countryCodeEditable={false}
							value={telephone}
							onChange={e => setTelephone(e)}
						/>
					</FormControl>

					{
						!isValidPhoneNumber("+" + telephone) &&

						<small className={"red-text"}>
							* Invalid phone number.
						</small>
					}
				</Box>

				<Divider
					style={{marginBottom: "15px", width: "100%"}}
				/>

				<FormControl className={"signup-form-control-2 confirm-text"} fullWidth>
					<Typography align={"left"} style={{fontSize: 16, marginBottom: 15}}>
						You can only apply to set up a pension scheme for a company that you
						lawfully represent or work for.
					</Typography>
				</FormControl>
				<FormControlLabel
					className={"signup-form-control-checkbox"}
					style={{width: '100%'}}
					control={
						<Checkbox
							name={"check_confirm_authorised"}
							id={"check_confirm_authorised"}
							value={checkConfirmAuthorised}
						/>
					}
					onChange={(event, value) => setCheckConfirmAuthorised(value)}
					label={"I confirm that I’m authorised to act on behalf of the employer"}
				/>
				<FormControlLabel
					className={"signup-form-control-checkbox"}
					control={
						<Checkbox
							name={"terms_accepted"}
							id={"terms_accepted"}
							value={terms_accepted}

						/>
					}
					onChange={(event, value) => setTermsAccepted(value)}
					label={<span>I have read and accept Collegia's {link}</span>}
				/>
				<Grid2 id={"forgot-password-requested-wrapper"}>
					<FormControl
						className={"signup-form-control"}
						fullWidth
						style={{marginBottom: 30}}
					>
						<Button
							variant={"contained"}
							className={"success-button-contained success-button-margin-bottom box-shadow-button"}
							onClick={() => {
								const data = {
									advisors: {
										company_number,
										title: company_business_name,
										description: "Company created by advisor",
										primary_email: email,
										employer_admins: {
											forename: forename,
											surname: surname,
											email,
											telephone,
										},
										director: {
											name: `${forename} ${surname}`,
											email,
											phone_number: telephone,
										},
										callback_url: process.env.REACT_APP_EMPLOYER_APP,
										manual_insertion: true,
										staging_date: stagingDate,
										company_registered_address_line_1,
										company_registered_premises,
										company_registered_locality,
										company_registered_country,
										registered_address_postcode,
										company_trading_address_line_1,
										company_trading_premises,
										company_trading_locality,
										trading_address_postcode,
										company_trading_country,
										legal_structure: selectedOption,
										is_employer_under_bureau: is_employer_under_bureau === "" ? false : is_employer_under_bureau,
										terms_accepted: terms_accepted,
										send_email: false,
										deal_owner: profile_data?.deal_owner?.forename ? profile_data?.deal_owner?.forename + " " + profile_data?.deal_owner?.surname : "N/A",
										advisor: profile_data?.title ?? ""
									}
								}
								addCompanyManually(data, false, history);
							}}
							disabled={disableContinueButton(
								stagingDate ?? "",
								company_business_name ?? "",
								selectedOption ?? "",
								company_number ?? "",
								company_trading_address_line_1 ?? "",
								company_trading_country ?? "",
								company_trading_locality ?? "",
								trading_address_postcode ?? "",
								company_registered_country ?? "",
								company_registered_address_line_1 ?? "",
								company_registered_locality ?? "",
								registered_address_postcode ?? "",
								forename ?? "",
								surname ?? "",
								email ?? "",
								telephone ?? "",
								is_employer_under_bureau ?? false,
								canProceed ?? false,
								checkConfirmAuthorised ?? false,
								error ?? false,
								profile_data?.is_b2b_b2c ?? false,
								terms_accepted ?? false,
							) || activation_loading}
						>
							Send instructions to employer to complete sign up
						</Button>
						<Button
							color={'primary'}
							variant={"outlined"}
							className={"box-shadow-button"}
							onClick={() => {
								const data = {
									advisors: {
										company_number,
										title: company_business_name,
										description: "Company created by advisor",
										primary_email: email,
										employer_admins: {
											forename: forename ?? "",
											surname: surname ?? "",
											email,
											telephone,
										},
										director: {
											name: `${forename} ${surname}`,
											email,
											phone_number: telephone
										},
										callback_url: process.env.REACT_APP_EMPLOYER_APP,
										manual_insertion: true,
										staging_date: stagingDate,
										company_registered_address_line_1,
										company_registered_premises,
										company_registered_locality,
										company_registered_country,
										registered_address_postcode,
										company_trading_address_line_1,
										company_trading_premises,
										company_trading_locality,
										trading_address_postcode,
										company_trading_country,
										legal_structure: selectedOption,
										is_employer_under_bureau: is_employer_under_bureau === "" ? false : is_employer_under_bureau,
										terms_accepted: terms_accepted,
										send_email: true,
										deal_owner: profile_data?.deal_owner?.forename ? profile_data?.deal_owner?.forename + " " + profile_data?.deal_owner?.surname : "N/A",
										advisor: profile_data?.title ?? ""
									}
								}
								addCompanyManually(data, true, history);
							}}
							disabled={disableContinueButton(
								stagingDate ?? "",
								company_business_name ?? "",
								selectedOption ?? "",
								company_number ?? "",
								company_trading_address_line_1 ?? "",
								company_trading_country ?? "",
								company_trading_locality ?? "",
								trading_address_postcode ?? "",
								company_registered_country ?? "",
								company_registered_address_line_1 ?? "",
								company_registered_locality ?? "",
								registered_address_postcode ?? "",
								forename ?? "",
								surname ?? "",
								email ?? "",
								telephone ?? "",
								is_employer_under_bureau ?? false,
								canProceed ?? false,
								checkConfirmAuthorised ?? false,
								error ?? false,
								profile_data?.is_b2b_b2c ?? false,
								terms_accepted ?? false,
							) || activation_loading}
						>
							Complete sign up on behalf of the employer
						</Button>
					</FormControl>
				</Grid2>
			</Grid2>
		</Grid2>
	);
}

export default connect(
	(state) => ({
		...state.Profile,
		...state.Employer,
		...state.SignUp,
	}),
	(dispatch) => ({
		me: dispatch(me()),
		warn: (msg) => dispatch(SnackBar.warn(msg)),
		addCompanyManually: (data = {}, isManual = false, history = {}) =>
			dispatch(addCompanyManually(data, isManual, history)),
		verifyEmail: (data = {}) => dispatch(verifyEmail(data)),
	})
)(ReferCompanyManually);
