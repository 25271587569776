import {connect} from "react-redux";
import React, {useEffect} from "react";
import Wrapper from "../layout/Logged/Wrapper";
import {me} from "../actions/Profile";
import LoadingPanel from "../components/LoadingPanel";
import {PrimaryHeader} from "../components/Headers/PrimaryHeader";
import Grid2 from '@mui/material/Grid2';
import ApiUploadTable from "../components/ApiUpload/ApiUploadTable";
import {downloadAllApiUpload, getApiUploads, getApiUploadsSpreadSheet, getPayrollDates} from "../actions/Softwares";

/**
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const ApiUploads = ({
	profile_data = [],
	history = {},
	profile_loading = false,
	me = () => {},
	get = () => {},
	getPayrollDates = () => {
	},
	apiUploads = [],
    download = () => {},
	isLoading = false,
	payroll_start_date = [],
	payroll_end_date = [],
	downloadAllApiUpload = () => {
	},
}) => {
	useEffect(() => {
		me();
		if (profile_data?.id) {
			get({
				advisors: {
					advisor_id: profile_data?.id,
				},
			}, {
				perPage: 10,
				page: 1,
			});
			getPayrollDates(profile_data?.id);
		}
	}, [me, get, profile_data?.id, getPayrollDates]);

	if (profile_loading) {
		return (
			<Grid2
				size={{
					xs: 12, sm: 12, md: 12, lg: 12, xl: 12,
				}}
				style={{
					width: "100%",
				}}
				container
			>
				<LoadingPanel/>
			</Grid2>
		);
	}

	return (
		<Wrapper history={history} profile_data={profile_data}>
			<Grid2>
				<PrimaryHeader
					backIcon
				>
					API Uploads
				</PrimaryHeader>
				<ApiUploadTable
					profile_data={profile_data}
					profile_loading={profile_loading}
					apiUploads={apiUploads}
					download={download}
					get={get}
					advisor_id={profile_data?.id}
					payroll_start_date={payroll_start_date}
					payroll_end_date={payroll_end_date}
					downloadAllApiUpload={downloadAllApiUpload}
					isLoading={isLoading}
				/>
			</Grid2>
		</Wrapper>
	);
};

export default connect(
	(state) => ({
		...state.Profile,
		...state.Softwares
	}),
	(dispatch) => ({
		me: () => dispatch(me()),
		get: (data = {}, queryString = {}) => dispatch(getApiUploads(data, queryString)),
		download: (data) => dispatch(getApiUploadsSpreadSheet(data)),
		getPayrollDates: (data) => dispatch(getPayrollDates(data)),
		downloadAllApiUpload: (data) => dispatch(downloadAllApiUpload(data)),
	})
)(ApiUploads);
