import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import Wrapper from "../../layout/Logged/Wrapper";
import Grid2 from '@mui/material/Grid2';
import {me} from "../../actions/Profile";
import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import {PrimaryHeader} from "../../components/Headers/PrimaryHeader";
import {onChange} from "../../utils/Helpers";
import TextField from "@mui/material/TextField";
import {requestAccess} from "../../actions/IFA/IndividualsIFA";
import Loading from "../../components/Loading";
import InputAdornment from "@mui/material/InputAdornment";
import {Tab} from "@mui/material";
import {employerIfaAccess} from "../../actions/IFA/EmployersIFA";

/**
 * @param me
 * @param history
 * @param profile_data
 * @param requestAccess
 * @param profile_loading
 * @param individuals_loading
 * @param employerAccess
 * @returns {JSX.Element}
 * @constructor
 */
const AddNewClient = (
	{
		me = () => {
		},
		history = {},
		profile_data = {},
		requestAccess = () => {
		},
		profile_loading = false,
		individuals_loading = false,
		employerAccess = () => {
		},
	}
) => {

	useEffect(() => {
		me();

	}, [
		me,
	]);

	const [policyNumber, setPolicyNumber] = useState("");
	const [ifaFee, setIfaFee] = useState("");
	const [isEmployer, setIsEmployer] = useState(false);
	const [companyNumber, setCompanyNumber] = useState("");

	if (profile_loading || individuals_loading) {
		return (
			<Loading/>
		);
	}

	return (
		<Wrapper
			history={history}
			profile_data={profile_data}
			isIFA={true}
		>
			<PrimaryHeader
				backIcon
			>
				IFA Dashboard - Add new client
			</PrimaryHeader>
			<Grid2
				size={{
					xs: 12, sm: 12, md: 12, lg: 12, xl: 12,
				}}
				direction={"row"}
				alignItems={"center"}
				justifyContent={"center"}
				container
				item
			>
				<Grid2
					size={{
						xs: 6, sm: 6, md: 6, lg: 6, xl: 6,
					}}
					className={isEmployer ? "tabs-active" : "tabs-inactive"}
					textAlign={"center"}
					item
					onClick={() => setIsEmployer(true)}
				>
					<Tab label={"Company"}/>
				</Grid2>
				<Grid2
					size={{
						xs: 6, sm: 6, md: 6, lg: 6, xl: 6,
					}}
					className={isEmployer ? "tabs-inactive" : "tabs-active"}
					textAlign={"center"}
					item
					onClick={() => setIsEmployer(false)}
				>
					<Tab label={"Individual"}/>
				</Grid2>
			</Grid2>
			<Grid2
				marginTop={2}
			>
				<Grid2
					size={{
						xs: 12, sm: 12, md: 12, lg: 12, xl: 12,
					}}
					alignItems={"start"}
					alignContent={"start"}
					direction={"row"}
					container
					item
					marginBottom={3}
				>
					<Grid2
						size={{
							xs: 3, sm: 3, md: 3, lg: 3, xl: 3,
						}}
						item
					>
					</Grid2>
					{
						isEmployer ?
							<Grid2
								size={{
									xs: 6, sm: 6, md: 6, lg: 6, xl: 6,
								}}
								item
								alignItems={"center"}
								alignContent={"center"}
								direction={"row"}
								container
							>
								<FormControl
									fullWidth
								>
									<TextField
										value={companyNumber}
										className={'margin-text'}
										onChange={event => onChange(setCompanyNumber(event.target.value))}
										label={"Company number*"}
										variant={"outlined"}
										name={"company_number"}
										id={"company_number"}
										inputProps={{
											maxLength: 10
										}}
									/>
								</FormControl>
								<FormControl
									className={"login-form-control"}
									fullWidth
								>
									<Button
										className={'margin-button'}
										variant={"contained"}
										color={"primary"}
										disabled={companyNumber.length < 1}
										onClick={() => employerAccess({
											ifa_employers: {
												company_number: companyNumber,
												advisor_id: profile_data.id,
											}
										})}
									>
										Continue
									</Button>
								</FormControl>
							</Grid2>
							:
							<Grid2
								size={{
									xs: 6, sm: 6, md: 6, lg: 6, xl: 6,
								}}
								item
								alignItems={"center"}
								alignContent={"center"}
								direction={"row"}
								container
							>
								<FormControl
									fullWidth
								>
									<TextField
										type={"nin"}
										value={policyNumber.toUpperCase()}
										onChange={(event) => setPolicyNumber(event.target.value)}
										label={"Policy Number*"}
										variant={"outlined"}
										name={"policy_number"}
										id={"policy_number"}
										inputProps={{
											maxLength: 13
										}}
									/>
								</FormControl>
								<FormControl
									fullWidth
								>
									<TextField
										type={"number"}
										className={'margin-text'}
										value={(ifaFee <= 1 && ifaFee >= 0) ? ifaFee : ""}
										onChange={(event) => setIfaFee(event.target.value)}
										label={"Annual Fee*"}
										placeholder={"Max fee is 1%"}
										variant={"outlined"}
										name={"ifaFee"}
										id={"ifaFee"}
										InputProps={{
											startAdornment: <InputAdornment position="start">%</InputAdornment>,
										}}
									/>
								</FormControl>
								<FormControl
									className={"login-form-control"}
									fullWidth
								>
									<Button
										className={'margin-button'}
										variant={"contained"}
										color={"primary"}
										disabled={policyNumber.length < 1 || ifaFee.length < 1 || ifaFee < 0}
										onClick={() => requestAccess({
											advisors: {
												policy_number: policyNumber.toUpperCase(),
												advisor_id: profile_data.id,
												ifa_fee: ifaFee,
											}
										})}
									>
										Continue
									</Button>
								</FormControl>
							</Grid2>
					}

				</Grid2>
			</Grid2>
		</Wrapper>
	);
}

export default connect(
	(state) => ({
		...state.Profile,
		...state.SignUp,
		...state.IndividualsIFA,
		...state.EmployersIFA
	}),
	(dispatch) => ({
		me: () => dispatch(me()),
		requestAccess: (data = {}) => dispatch(requestAccess(data)),
		employerAccess: (data = {}) => dispatch(employerIfaAccess(data)),
	})
)(AddNewClient);
