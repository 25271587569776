import React from "react";
import Grid2 from '@mui/material/Grid2';

/**
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
export const CountEmployers = (props = {
	companies_count: {
		total: 0,
		active: 0,
		pending: 0,
	}
}) => {

	return (
		<Grid2
			justifyContent={"space-between"}
			alignItems={"center"}
			className={"collegia-border count-employers"}
			container
		>
			<Grid2
				size={{
					xs: 12, sm: 12, md: 4, lg: 4, xl: 4,
				}}
				justifyContent={"center"}
				item
				container
			>
				<span className={"count-employers-title"}>
					Registered Companies:&nbsp;
				</span>
				<span className={"count-employers-number"}>
					{
						(props?.companies_count?.active + props?.companies_count?.pending) ?? 0
					}
				</span>
			</Grid2>

			<Grid2
				size={{
					xs: 12, sm: 12, md: 4, lg: 4, xl: 4,
				}}
				justifyContent={"center"}
				item
				container
			>
				<span className={"count-employers-title"}>
					Active:&nbsp;
				</span>
				<span className={"count-employers-number"}>
					{props?.companies_count?.active ?? 0}
				</span>
			</Grid2>

			<Grid2
				size={{
					xs: 12, sm: 12, md: 4, lg: 4, xl: 4,
				}}
				justifyContent={"center"}
				item
				container
			>
				<span className={"count-employers-title"}>
					Pending:&nbsp;
				</span>
				<span className={"count-employers-number"}>
					{props?.companies_count?.pending ?? 0}
				</span>
			</Grid2>
		</Grid2>
	);
}
