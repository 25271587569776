import React, {useEffect, useState} from 'react';
import {connect} from "react-redux";

import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import Slide from '@mui/material/Slide';
import Typography from '@mui/material/Typography';
import TextField from "@mui/material/TextField";
import Grid2 from '@mui/material/Grid2';

import {removeDefaultLoggedLayout, validateEmail} from "../utils/Helpers";
import Loading from "../components/Loading";
import {requestNewPassword} from "../actions/Profile";
import Wrapper from "../layout/Logged/Wrapper";

import Logo from '../images/logo-icon.svg';
import MinimalFooter from "../layout/MinimalFooter";
import ContentGrid from '../layout/ContentGrid';
import {useNavigate} from "react-router-dom";

/**
 * @param profile_loading
 * @param requestNewPassword
 * @returns {JSX.Element}
 * @constructor
 */
const ForgotPassword = (
	{
		profile_loading = false,
		requestNewPassword
	}
) => {
	const [email, setEmail] = useState("");
	const navigate = useNavigate();

	useEffect(() => {
		removeDefaultLoggedLayout();
		const menu = document.getElementById("menu");

		if (menu) menu.style.display = "none";
	}, []);

	if (profile_loading) {
		return (
			<Loading absolute/>
		);
	}

	return (
		<Wrapper>
			<Slide direction="up" in={true} mountOnEnter unmountOnExit>
				<div id={"forgot-password-wrapper"}>
					<ContentGrid
						direction={"column"}
						id={"header"}
						onClick={() => navigate("/login")}
						alignItems={"center"}
						alignContent={"center"}
						container
					>
						<img
							src={Logo}
							alt={"Welcome Collegia"}
						/>
					</ContentGrid>
					<form
						noValidate
						autoComplete="off"
						onSubmit={ev => {
							ev.preventDefault();
							requestNewPassword(email);
						}}
					>
						<ContentGrid id={"login-container"}>

							<FormControl
								className={"login-form-control-2 first-title"}
								fullWidth
							>
								<Typography align={"center"}>
									Password? We can help!
								</Typography>
							</FormControl>
							<FormControl
								className={"login-form-control-2 second-title"}
								fullWidth
							>
								<Typography align={"center"}>
									Please enter your email address.
									You will receive a link to create a new password via email.
								</Typography>
							</FormControl>

							<Grid2
								id={"forgot-password-form"}
								item
							>
								<FormControl
									className={"login-form-control"}
									fullWidth
								>
									<TextField
										type={"email"}
										onChange={(event, value) => setEmail(event.target.value)}
										label="Email"
										variant="outlined"
										name={"email"}
										id={"email"}
										value={email}
										error={(!validateEmail(email) && !(email === ""))}
									/>
									<small className={"red-text"}>
										{
											(!validateEmail(email) && !(email === "")) ?
												"Please enter a valid e-mail"
												: ""
										}
									</small>
								</FormControl>
							</Grid2>

							<Grid2
								id={"forgot-password-form"}
								item
							>
								<FormControl
									className={"login-form-control"}
									fullWidth
								>
									<Button
										variant="contained"
										color="primary"
										disabled={!email}
										onClick={() => requestNewPassword(email)}>
										Continue
									</Button>
								</FormControl>
							</Grid2>

							<Grid2
								id={"forgot-password-form"}
								item
							>
								<FormControl
									className={"login-form-control"}
									fullWidth
								>
									<Button
										className={"gray-outlined-button"}
										fullWidth
										color="primary"
										onClick={() => navigate("/login")}
									>
										Cancel
									</Button>
								</FormControl>
							</Grid2>
						</ContentGrid>
					</form>
				</div>
			</Slide>
			<MinimalFooter/>
		</Wrapper>
	);
}

/**
 * @param state
 * @returns {*}
 */
const mapStateToProps = ({Profile = {}}) => Profile;

/**
 * @param dispatch
 * @returns {{login: (function(*=): *)}}
 */
const mapDispatchToProps = dispatch => ({
	requestNewPassword: (email: string) => (email && dispatch(requestNewPassword({
		forgot_password: {
			email,
            is_advisor: true,
		}
	}))),
});

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPassword);
