import React from "react";

import {useDispatch, useSelector} from "react-redux";
import AccountDataEditor from "../components/MyAccount/AccountDataEditor";
import {checkPassword} from "../utils/Helpers";
import {updatePassword} from "../actions/Profile";
import {useNavigate} from "react-router-dom";

/**
 * @returns {JSX.Element}
 * @constructor
 */
const ChangePassword: React.Component = () => {
	const {
		profile_loading,
		pending_request,
		edited_fields = {},
		profile_data
	} = useSelector(state => state?.Profile),
	{
		new_password = '',
		confirm_password = ''
	} = edited_fields,
	dispatch = useDispatch(),
	navigate = useNavigate();

	function updatePasswordFunction(id, new_password, confirm_password) {
		let data = {
			users_advisors_id: id,
			new_password: new_password,
			confirm_password: confirm_password,
		};
		dispatch(updatePassword(data));
	}

	return (
		<AccountDataEditor
			headline={[
				'Here you can change your password',
				<br />,
				'Follow the steps below'
			]}
			header={"Change your password"}
			loading={pending_request || profile_loading}
			edited_fields={edited_fields}
			inputFields={[
				{
					id: 'new_password',
					value: '',
					label: 'New password',
					type: 'password',
					error: (new_password !== '' && !checkPassword(new_password))
				},
				{
					id: 'confirm_password',
					value: '',
					label: 'Confirm new password',
					type: 'password',
					error: (new_password !== confirm_password)
				},
			]}
			buttons={[
				{
					variant: 'outlined',
					onClick: () => navigate(-1),
					text: 'CANCEL',
					enabled: true
				},
				{
					onClick: () => updatePasswordFunction(profile_data.userLogged.id,new_password,confirm_password),
					text: 'Save Password',
					variant: 'contained'
				}
			]}
		/>
	);
}

export default ChangePassword;
