import React from "react";
import Grid2 from '@mui/material/Grid2';
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";

const MinimalFooter = () =>
    <Grid2
        alignItems={"center"}
        alignContent={"center"}
        id={"footer"}
        direction={"column"}
        item
        container
    >
        <Grid2
	        size={{
		        xs: 12, sm: 12, md: 12, lg: 12, xl: 12,
	        }}
            className={"copyrights"}
            item
        >
            <Typography
                align={"center"}
                component={"p"}
                variant={"subtitle1"}
            >
                <Link
                    href={"https://www.collegia.co.uk/"}
                    target={"_blank"}
                >
                    © {new Date().getFullYear()} Collegia Partners Limited. All rights reserved.
                </Link>
                <br />
                All rights reserved. Oxford, United Kingdom.
            </Typography>
        </Grid2>
    </Grid2>

/**
 * @returns {*}
 */
export default MinimalFooter;
