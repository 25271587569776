import React, {useEffect} from "react";
import {connect} from "react-redux";

import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import * as Notifications from '../../actions/Notifications';
import Typography from "@mui/material/Typography";
import NotificationsIcon from "@mui/icons-material/Notifications";
import ListItemIcon from "@mui/material/ListItemIcon";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import Button from "@mui/material/Button";
import Grid2 from '@mui/material/Grid2';

/**
 * @param notifications
 * @param notificationAnchor
 * @param setNotificationAnchor
 * @param visualizeNotification
 * @param clearNotifications
 * @param getNotifications
 * @param profile_data
 * @returns {JSX.Element}
 * @constructor
 */
const NotificationMenu = ({
	notifications = [],
	notificationAnchor = null,
	setNotificationAnchor = () => {},
	visualizeNotification = () => {},
	clearNotifications = () => {},
	getNotifications = () => {},
	profile_data = {}
}: {
	notifications: Array<any>,
	notificationAnchor: any,
	setNotificationAnchor: Function,
	visualizeNotification: Function,
	clearNotifications: Function,
	getNotifications: Function,
}) => {
	useEffect(() => {
		if (profile_data?.id !== undefined) {
			getNotifications();
		}
	}, [getNotifications, profile_data?.id]);
	if (notifications?.notifications?.length > 0) {
		return (
			<Menu
				id={"notifications-menu"}
				anchorEl={notificationAnchor}
				keepMounted
				open={Boolean(notificationAnchor)}
				anchorOrigin={{
					vertical: 'top',
					horizontal: 'center',
				}}
				transformOrigin={{
					vertical: 'top',
					horizontal: 'center',
				}}
				onClose={(event, id) => setNotificationAnchor(null) }
			>
				<Typography
					className={"notifications-menu-title"}
				>
					Notifications
				</Typography>
				{
					notifications.notifications?.map(({message, visualized}, index) => (
						<MenuItem
							key={`notification-${index}`}
							onClick={() => {
								visualizeNotification(notifications?.notifications[index]);
								setNotificationAnchor(null);
							}}
							className={visualized ? "null" : "app-bar-notification"}
						>
							<ListItemIcon style={{color:"#3C69E4"}}>
								{notifications?.notification?.notifications[index]?.visualized
									? <NotificationsNoneIcon/>
									: <NotificationsIcon/>
								}
							</ListItemIcon>

							<Typography
								key={index}
								variant="inherit"
								style={{whiteSpace: 'normal', maxWidth: 400}}
							>
								{(JSON.parse(notifications?.notifications[index]?.notification))[0]?.message}
							</Typography>
						</MenuItem>
					))
				}
				<Grid2
					size={{
						xs: 12, sm: 12, md: 12, lg: 12, xl: 12,
					}}
					className={"notification-menu-clear-button"}
					justifyContent={"center"}
					item
					container
				>
					<Button
						color={"primary"}
						variant={"outlined"}
						fullWidth
						onClick={() => {
							clearNotifications(notifications?.notifications);
							setNotificationAnchor(null);
						}}
					>
						Clear All
					</Button>
				</Grid2>
			</Menu>
		);
	}

	return [];
};

export default connect(
	({
		 Notifications,
	 }) => ({
		notifications: Notifications,
	}),
	dispatch => ({
		visualizeNotification: data => dispatch(Notifications.setVisualized({advisors: {notification: data}})),
		clearNotifications: data => dispatch(Notifications.clearAllNotification({advisors: data})),
		getNotifications: () => dispatch(Notifications.getNotification()),
	}))(NotificationMenu);
