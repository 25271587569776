import React, {useState} from "react";

import FormControl from "@mui/material/FormControl";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Grid2 from '@mui/material/Grid2';
import 'react-phone-input-2/lib/material.css';
import CompaniesList from "../pages/AddNewCompany/CompaniesList";
import {geocode, RequestType, setDefaults} from "react-geocode";


/**
 * @param props
 * @constructor
 */
const enterManually = (props) => {
	props.setEnterManually(true);
	props.fillTypeOfCompanies(true, props.setFormValues, props.formValues);
	props.proceedStep(props.stepIndex + 1, props.setStepIndex);
}

/**
 * @constructor
 * @param country
 */
const countryAdjust = (country) => {
	if(country === "England") {
		return "GB";
	}
	else {
		return country;
	}
}

/**
 * @returns {JSX.Element}
 * @constructor
 * @param apiTypeOfBusiness
 */
const searchTypeOfBusiness = (apiTypeOfBusiness) => {
	switch (apiTypeOfBusiness) {
		case "private-unlimited":
			return "Private unlimited company";

		case "ltd":
			return "Private limited company";

		case "plc":
			return "Public limited company";

		case "old-public-company":
			return "Old public company";

		case "private-limited-guarant-nsc-limited-exemption":
			return "Private Limited Company by guarantee without share capital, use of 'Limited' exemption";

		case "limited-partnership":
			return "Limited partnership";

		case "private-limited-guarant-nsc":
			return "Private limited by guarantee without share capital";

		case "converted-or-closed":
			return "Converted or closed";

		case "private-unlimited-nsc":
			return "Private unlimited company without share capital";

		case "private-limited-shares-section-30-exemption":
			return "Private Limited Company, use of 'Limited' exemption";

		case "protected-cell-company":
			return "Protected cell company";

		case "assurance-company":
			return "Assurance company";

		case "oversea-company":
			return "Overseas company";

		case "eeig":
			return "European Economic Interest Grouping (EEIG)";

		case "icvc":
			return "Investment company with variable capital";

		case "registered-society-non-jurisdictional":
			return "Registered society";

		case "industrial-and-provident-society":
			return "Industrial and Provident society";

		case "northern-ireland":
			return "Northern Ireland company";

		case "northern-ireland-other":
			return "Credit union (Northern Ireland)";

		case "llp":
			return "Limited liability partnership";

		case "royal-charter":
			return "Royal charter company";

		case "unregistered-company":
			return "Unregistered company";

		case "european-public-limited-liability-company-se":
			return "European public limited liability company (SE)";

		case "uk-establishment":
			return "UK establishment company";

		case "scottish-partnership":
			return "Scottish qualifying partnership";

		case "charitable-incorporated-organisation":
			return "Charitable incorporated organisation";

		case "scottish-charitable-incorporated-organisation":
			return "Scottish charitable incorporated organisation";

		case "further-education-or-sixth-form-college-corporation":
			return "Scottish charitable incorporated organisation";

		default:
			return "";
	}
}

/**
 * @param props
 * @param advisor
 * @constructor
 */
const _companyHouse = (props, advisor) => {
	props.setApiTypeOfBusiness(searchTypeOfBusiness(advisor?.company_type));
	setDefaults({
		key: process.env.REACT_APP_GEOCODE_API_KEY,
		language: "en",
	});
	if (geocode) {
		geocode(RequestType.ADDRESS, advisor?.address?.postal_code)
			.then((response) => {
					const {
						status = "OK"
					} = response;
					if (status === "OK") {
						props.setFormValues({...props.formValues,
							address_1: response.results[0]?.address_components[1]?.long_name,
							address_2: advisor?.address?.locality,
							address_3: advisor?.address?.address_line_2 ? advisor?.address?.address_line_2 : "",
							postal_code: advisor?.address?.postal_code,
							country: response.results[0]?.address_components[5]?.short_name ?
								countryAdjust(response.results[0]?.address_components[5]?.short_name)
								:
								countryAdjust(response.results[0]?.address_components[4]?.short_name),
							company_name: advisor?.title,
							company_legal_structure: searchTypeOfBusiness(advisor?.company_type) ?? "",
						});
					}
				},
				error => console.error(error)
			)
			.catch(error => console.error(error));
	}
	props.proceedStep(props.stepIndex + 1, props.setStepIndex);
}

/**
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const FindCompanyOrManually = (props) => {
	const [
			advisor,
			setAdvisorValue,
		] = useState(null),
		[
			timeout = null,
			setTimeoutDispatch,
		] = useState(null),
		[
			searchCompanyInput,
			setSearchCompanyInput,
		] = useState("");
	return (
		<Grid2
			size={{
				xs: 12, sm: 12, md: 12, lg: 12, xl: 12,
			}}
			id={"signup-container"}
			alignItems={"center"}
			alignContent={"center"}
			direction={"column"}
			spacing={0}
			container
			item
		>
			<form noValidate autoComplete="off">
				<FormControl className={"signup-form-control-2 first-title"} fullWidth>
					<Typography align={"center"}>
						Search your company
					</Typography>
				</FormControl>
				<FormControl className={"signup-form-control-2 second-title"} fullWidth>
					<Typography align={"center"}>
						Please follow the info below
					</Typography>
				</FormControl>
				<FormControl
					fullWidth
				>
					<Typography
						marginBottom={1.5}
						marginLeft={2}
						color={"black"}
					>
						{ "Didn't find your company?" }
						<a
							href="# " onClick={ ()=> enterManually(props) }
						>
							{ " Click Here" }
						</a>
					</Typography>
				</FormControl>
				<Grid2>
					<FormControl
						className={"signup-form-control form-control-padding"}
						fullWidth
					>
						<CompaniesList
							setEmployerValue={setAdvisorValue}
							timeout={timeout}
							setTimeoutDispatch={setTimeoutDispatch}
							setTextInput={setSearchCompanyInput}
						/>
					</FormControl>
				</Grid2>

				<Grid2>
					<FormControl className={"signup-form-control"} fullWidth>
						<Button
							variant="contained"
							color="primary"
							onClick={() =>
								_companyHouse(props, advisor)
							}
							disabled={
								!advisor ||
								!searchCompanyInput ||
								props.error
							}
						>
							Continue
						</Button>
					</FormControl>
					<FormControl
						className={"signup-form-control-2 forgot-password"}
						fullWidth
					>
						<span
							className={"span-style"}
							onClick={() =>
								props.proceedStep(props.stepIndex - 1, props.setStepIndex)
							}
						>
							Back
						</span>
					</FormControl>
				</Grid2>
			</form>
		</Grid2>
	);
}

export default FindCompanyOrManually;
