import React from "react";
import {Tooltip} from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";

/**
 * @param setSearchFindText
 * @param setSortColumn
 * @param setSortDirection
 * @param setActualPage
 * @param setRowsPerPage
 * @param setSearchFindTextWithTimeOut
 * @param employerView
 * @param setEmployerView
 * @returns {JSX.Element}
 */
const customToolbar = (
	{
		setSearchFindText = () => {
		},
		setSortColumn = () => {
		},
		setSortDirection = () => {
		},
		setActualPage = () => {
		},
		setRowsPerPage = () => {
		},
		setSearchFindTextWithTimeOut = () => {
		},
		employerView = false,
		setEmployerView = () => {
		},
	}
) => (
	<Tooltip title={"Change View"}>
		<Visibility
			onClick={() => {
				setSortColumn("");
				setSortDirection("asc");
				setActualPage(0);
				setRowsPerPage(10);
				setSearchFindText("");
				setSearchFindTextWithTimeOut("");
				setEmployerView(employerView);
			}}
			className={"info-icon"}
		/>
	</Tooltip>
);

/**
 * @stateless
 * @param props
 * @returns {*}
 */
export default customToolbar;
