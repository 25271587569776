import Grid2 from '@mui/material/Grid2';

/**
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const TableCellItem = (props = {
	title: "",
	subtitle: "",
}) => {
	return (
		<Grid2
			container
			{...props}
			direction={"column"}
		>
			<Grid2
				item
				className={"table-cell-item-title"}
			>
				{props?.title}
			</Grid2>

			<Grid2
				item
				className={"table-cell-item-subtitle"}
			>
				{props?.subtitle}
			</Grid2>
		</Grid2>
	);
}

export default TableCellItem;
