import React, {useState} from 'react';
import {connect} from "react-redux";

import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import Grid2 from '@mui/material/Grid2';
import Typography from '@mui/material/Typography';
import Slide from '@mui/material/Slide';
import Wrapper from "../layout/Wrapper";
import Logo from '../images/logo-icon.svg';
import MinimalFooter from "../layout/MinimalFooter";
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import OutlinedInput from "@mui/material/OutlinedInput";
import {checkForgotPasswordToken, forgotPasswordUpdatePassword} from "../actions/Profile";
import {useParams} from "react-router-dom";

/**
 * @param token
 * @param password
 * @param confirmPassword
 * @param setMsgError
 * @param forgotPasswordUpdatePassword
 * @private
 */
const handleSubmit = (
	token: string = "",
	password: string = "",
	confirmPassword: string = "",
	setMsgError: () => {
	},
	forgotPasswordUpdatePassword: () => {
	}
) =>  {
	if (password !== confirmPassword) {
		setMsgError("Passwords doesn't match");
	} else {
		forgotPasswordUpdatePassword({
			forgot_password: {
				token,
				password,
				is_advisor: true,
			}
		});
	}
}

/**
 * @returns {*}
 */
const ResetPassword = (props) => {
	const [showPassword, setShowPassword] = useState(false);
	const [password, setPassword] = useState("");
	const [confirmPassword, setConfirmPassword] = useState("");
	const [msgError, setMsgError] = useState("");
	let token = useParams().token;

	return (
		<Wrapper>
			<Slide direction="up" in={true} mountOnEnter unmountOnExit>
				<div id={"forgot-password-requested-wrapper"}>
					<Grid2
						size={{
							xs: 12, sm: 12, md: 12, lg: 12, xl: 12,
						}}
						alignItems={"center"}
						alignContent={"center"}
						direction={"column"}
						id={"header"}
						container
						item
						onClick={() => props.history("/login")}
						marginBottom={20}
					>
						<img
							src={Logo}
							alt={"Welcome Collegia"}
						/>
					</Grid2>
					<Grid2
						id={"login-container"}
						size={{
							xs: 12, sm: 12, md: 12, lg: 12, xl: 12,
						}}
						alignItems={"center"}
						alignContent={"center"}
						direction={"column"}
						spacing={0}
						container
						item
					>
						<form
							noValidate
							autoComplete="off"
						>
							<FormControl
								className={"login-form-control-2 first-title"}
								fullWidth
							>
								<Typography align={"center"}>
									Set a new password
								</Typography>
							</FormControl>
							<FormControl
								className={"login-form-control-2 second-title"}
								fullWidth
							>
								<Typography align={"center"}>
									Please create a new password to access your account
								</Typography>
							</FormControl>
							<FormControl
								className={"login-form-control"}
								fullWidth
							>
								<OutlinedInput
									id={"password"}
									name={"password"}
									type={showPassword ? 'text' : 'password'}
									placeholder={"Password"}
									error={msgError !== ""}
									endAdornment={
										<InputAdornment position="end">
											<IconButton
												onClick={() => setShowPassword(!showPassword)}
												aria-label="toggle password visibility"
												disableFocusRipple
												disableRipple
												disableTouchRipple
											>
												{showPassword ? <Visibility/> : <VisibilityOff/>}
											</IconButton>
										</InputAdornment>
									}
									value={password}
									onChange={(event) =>
										setPassword(event.target.value)
									}
								/>
							</FormControl>
							<FormControl
								className={"login-form-control"}
								fullWidth
							>
								<OutlinedInput
									error={msgError !== ""}
									id={"confirmPassword"}
									name={"confirmPassword"}
									type={showPassword ? 'text' : 'password'}
									placeholder={"Confirm password"}
									value={confirmPassword}
									onChange={(event) =>
										setConfirmPassword(event.target.value)
									}
								/>
								<small>
									{msgError}
								</small>
							</FormControl>

							<FormControl
								className={"login-form-control"}
								fullWidth
							>
								<Button
									variant="contained"
									color="primary"
									onClick={() =>
										handleSubmit(
											token ?? "",
											password ?? "",
											confirmPassword ?? "",
											setMsgError,
											props.forgotPasswordUpdatePassword
										)
									}
									disabled={!password || !confirmPassword}
								>
									Save
								</Button>
							</FormControl>
						</form>
					</Grid2>
					<MinimalFooter/>
				</div>
			</Slide>
		</Wrapper>
	);
}

/**
 * @param dispatch
 * @returns {{checkForgotPasswordToken: (function(string=): *)}}
 */
const mapDispatchToProps = dispatch => ({
	checkForgotPasswordToken: (token: string = "") => dispatch(checkForgotPasswordToken(token ?? "")),
	forgotPasswordUpdatePassword: (data: any[] = false) => dispatch(forgotPasswordUpdatePassword(data ?? false)),
});

export default connect(null, mapDispatchToProps)(ResetPassword);
