import React from "react";
import ContentGrid from "../../layout/ContentGrid";
import Grid2 from '@mui/material/Grid2';
import Typography from "@mui/material/Typography";
import SectionGrid from "../../layout/SectionGrid";


/**
 * @returns {JSX.Element}
 * @constructor
 */
const TopBannerGrid = (): JSX.Element => (
	<SectionGrid className={"main-page-container-wrapper top-banner-content"}>
		<ContentGrid
			direction={"row"}
			alignItems={"flex-start"}
			alignContent={"center"}
			id={"confirm-new-company-container"}
			item
			container
		>
			<Grid2
				size={{
					xs: 12, sm: 12, md: 12, lg: 12, xl: 12,
				}}
				justifyContent={"center"}
				direction={"column"}
				container
				item
			>
				<Typography
					variant={"subtitle1"}
					className={"title-individual"}
					align={"center"}
					style={{fontSize: 30}}
				>
					Please, confirm your company
				</Typography>
				<Typography
					component={"p"}
					variant={"caption"}
					className={"subtitle-individual"}
					align={"center"}
				>
					Please, review the details below and confirm they are correct
				</Typography>
			</Grid2>
		</ContentGrid>
	</SectionGrid>
);

export default TopBannerGrid;
