import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Grid2 from '@mui/material/Grid2';
import FormControl from "@mui/material/FormControl";
import {capitalizeFirstLetter} from "../../utils/Helpers";

const _renderErrors = (error_messages = {}) => {
	if (Object.keys(error_messages).length > 0) {

		const errors = [];

		Object.keys(error_messages).forEach((e) => {
			errors.push(
				<smal
					className={"error"}
					key={e}
				>
					{capitalizeFirstLetter(e)}
					<ul>
						{
							error_messages[e].map(error => <li>{capitalizeFirstLetter(error)}</li>)
						}
					</ul>
				</smal>
			);
		});

		return errors;
	}

	return [];
};

/**
 * @param password
 * @param user
 * @param setPassword
 * @param setUser
 * @param submitEnvPassword
 * @param profile_data
 * @param error_messages
 * @returns {JSX.Element}
 * @constructor
 */
const PasswordForm = (
	{
		password = "",
		user = "",
		setPassword = (password) => password,
		setUser = (user) => user,
		submitEnvPassword = (data) => data,
		profile_data = {
			my_ip: "",
		},
		error_messages = {},
	}
) => (
	<Grid2
		size={{
			xs: 12, sm: 12, md: 12, lg: 12, xl: 12,
		}}
		id={"login-container"}
		alignItems={"center"}
		alignContent={"center"}
		direction={"column"}
		spacing={0}
		container
		item
	>
		{_renderErrors(error_messages)}
		<form
			noValidate
			autoComplete="off"
		>
			<FormControl
				className={"login-form-control-2 second-title"}
				fullWidth
			>
				<TextField
					type={"text"}
					name={"user"}
					id={"user"}
					placeholder={"Your name"}
					value={user}
					onChange={event => setUser(event.target.value)}
				/>
			</FormControl>
			<FormControl
				className={"login-form-control-2 second-title"}
				fullWidth
			>
				<TextField
					type={"password"}
					name={"password"}
					id={"password"}
					placeholder={"Type the password"}
					value={password}
					onChange={event => setPassword(event.target.value)}
				/>
			</FormControl>
			<FormControl
				className={"login-form-control-2 second-title"}
				fullWidth
			>
				<Button
					variant={"contained"}
					color={"success"}
					disabled={!password || !user}
					onClick={() => submitEnvPassword(
						{
							password_ip_usage: {
								my_ip: profile_data.my_ip,
								password,
								user,
							},
						}
					)}
					fullWidth
				>
					SUBMIT
				</Button>
			</FormControl>
		</form>
	</Grid2>
);

export default PasswordForm;
