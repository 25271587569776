import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import Wrapper from "../../layout/Logged/Wrapper";
import Grid2 from '@mui/material/Grid2';
import {me} from "../../actions/Profile";
import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import {PrimaryHeader} from "../../components/Headers/PrimaryHeader";
import {onChange} from "../../utils/Helpers";
import TextField from "@mui/material/TextField";
import Loading from "../../components/Loading";
import PhoneInput from "react-phone-input-2";
import {createBeneficiary} from "../../actions/IFA/IFA";

/**
 * @param event
 * @param setMobileNumber
 * @param onlyNumbers
 * @param customValue
 */
const mobileNumberFormat = (
	event = null,
	setMobileNumber = () => {
	},
	onlyNumbers: boolean = false,
	customValue: string = "",
) => {
	let value = "";

	if (customValue !== "") {
		value = customValue;
	}

	if (event.target) {

		if (value === "") {
			value = event.target.value;
		}

		if (onlyNumbers) {
			if (value !== undefined) {
				if (typeof value.replace === "function") {
					value = value.replace(/[^0-9]/gi, '');
					value = value.replace(/^0+/, '')
				}
			}
		}
		setMobileNumber(value);
	}
};

/**
 * @param me
 * @param history
 * @param profile_data
 * @param profile_loading
 * @param createBeneficiary
 * @param stats_loading
 * @returns {JSX.Element}
 * @constructor
 */
const BeneficiaryInformation = (
	{
		me = () => {
		},
		history = {},
		profile_data = {},
		profile_loading = false,
		createBeneficiary = () => {
		},
		stats_loading = false,
	}
) => {

	useEffect(() => {
		me();

	}, [
		me,
	]);

	const [name, setName] = useState("");
	const [email, setEmail] = useState("");
	const [iban, setIban] = useState("");
	const [mobileNumber, setMobileNumber] = useState("");


	if (profile_loading || stats_loading) {
		return (
			<Loading/>
		);
	}

	return (
		<Wrapper
			history={history}
			profile_data={profile_data}
			isIFA={true}
		>
			<PrimaryHeader
				backIcon
			>
				IFA Dashboard - Beneficiary Information
			</PrimaryHeader>
			<Grid2
				marginTop={2}
			>
				<Grid2
					size={{
						xs: 12, sm: 12, md: 12, lg: 12, xl: 12,
					}}
					alignItems={"start"}
					alignContent={"start"}
					direction={"row"}
					container
					item
					marginBottom={3}
				>
					<Grid2
						size={{
							xs: 3, sm: 3, md: 3, lg: 3, xl: 3,
						}}
						item
					>
					</Grid2>
					<Grid2
						size={{
							xs: 6, sm: 6, md: 6, lg: 6, xl: 6,
						}}
						item
						alignItems={"center"}
						alignContent={"center"}
						direction={"row"}
						container
					>
						<FormControl
							fullWidth
						>
							<TextField
								type={"name"}
								value={name}
								onChange={event => onChange(setName(event.target.value))}
								label={"Full Name *"}
								variant={"outlined"}
								name={"name"}
								id={"name"}
							/>
						</FormControl>
						<FormControl
							fullWidth
						>
							<TextField
								value={email}
								onChange={event => onChange(setEmail(event.target.value))}
								label={"Email *"}
								variant={"outlined"}
								name={"email"}
								id={"email"}
							/>
						</FormControl>
						<FormControl
							fullWidth
						>
							<PhoneInput
								placeholder={"Type mobile number *"}
								country={"gb"}
								inputStyle={{
									width: "100%",
								}}
								regions={["europe", "south-america", "asia"]}
								searchPlaceholder={"Country name"}
								searchNotFound={"No country found, please search again."}
								onChange={
									(value, country, e, formattedValue) => {
										mobileNumberFormat(e, setMobileNumber, false, formattedValue);
									}
								}
								enableSearch
								value={mobileNumber}
								specialLabel={false}
								fullWidth
								countryCodeEditable={false}
							/>
							<small>
								There is no need to add the zero at the phone number
							</small>
						</FormControl>
						<FormControl
							fullWidth
						>
							<TextField
								type={"iban"}
								value={iban}
								onChange={event => onChange(setIban(event.target.value))}
								label={"Iban *"}
								variant={"outlined"}
								name={"iban"}
								id={"iban"}
							/>
						</FormControl>
						<FormControl
							className={"login-form-control"}
							fullWidth
						>
							<Button
								style={{marginBottom: 45}}
								variant={"contained"}
								color={"primary"}
								disabled={iban.length < 5 || email.length < 5 || mobileNumber.length < 5 || name.length < 1}
								onClick={() => createBeneficiary({
									ifa_advisor: {
										advisor_id: profile_data.id,
										name: name,
										email: email,
										mobile_number: mobileNumber,
										iban: iban
									}
								})}
							>
								Continue
							</Button>
						</FormControl>
					</Grid2>
				</Grid2>
			</Grid2>
		</Wrapper>
	);
};

export default connect(
	(state) => ({
		...state.Profile,
		...state.IFA
	}),
	(dispatch) => ({
		me: () => dispatch(me()),
		createBeneficiary: (data: any = {}) => dispatch(createBeneficiary(data))
	})
)(BeneficiaryInformation);
