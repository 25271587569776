import React, {useEffect, useState} from "react";

import {useDispatch, useSelector} from "react-redux";
import FindAddressByApi from "../components/FindAddressByApi";
import Wrapper from "../layout/Logged/Wrapper";
import {PrimaryHeader} from "../components/Headers/PrimaryHeader";
import * as Profile from "../actions/Profile";
import LoadingPanel from "../components/LoadingPanel";
import {updateAddress} from "../actions/Profile";

import Grid2 from '@mui/material/Grid2';
import FormControl from "@mui/material/FormControl";
import Button from "@mui/material/Button";

/**
 * @param history
 * @returns {JSX.Element}
 * @constructor
 */
const ChangeAddress: React.Component = ({history}: {history: Object}) => {

	const {
			profile_data,
			profile_loading,
		} = useSelector(state => state?.Profile),
		dispatch = useDispatch();

	const [formValues, setFormValues] = useState({
		address_1: "",
		address_2: "",
		address_3: "",
		address_4: "",
		postal_code: "",
		country: "",
	});

	useEffect(() => {
		dispatch(Profile.me())
		setFormValues({
			address_1: profile_data?.address?.address_1 ? profile_data?.address?.address_1 : "",
			address_2: profile_data?.address?.address_2 ? profile_data?.address?.address_2 : "",
			address_3: profile_data?.address?.address_3 ? profile_data?.address?.address_3 : "",
			address_4: profile_data?.address?.address_4 ? profile_data?.address?.address_4 : "",
			postal_code: profile_data?.address?.postal_code ? profile_data?.address?.postal_code : "",
			country: profile_data?.address?.country ? profile_data?.address?.country : "",
		})
	},
		[
			dispatch,
			profile_data?.address?.address_1,
			profile_data?.address?.address_2,
			profile_data?.address?.address_3,
			profile_data?.address?.address_4,
			profile_data?.address?.postal_code,
			profile_data?.address?.country
		]
	);

	const [addressComponents, setAddressComponents] = useState([]);


	function saveAddress(address_1, address_2, address_3, address_4, postal_code, country, dispatch) {
		let data = {
			address_1: address_1,
			address_2: address_2,
			address_3: address_3,
			address_4: address_4,
			postal_code: postal_code,
			country: country,
		};
		dispatch(updateAddress(data))
	}


	if (profile_loading) {
		return (
			<Wrapper>
				<LoadingPanel/>
			</Wrapper>
		);
	}

	return (
		<Wrapper headerLink='return' history={history}>

			<PrimaryHeader
				changeInfo={history}
				backIcon
			>
				Change Your Address
			</PrimaryHeader>
		 	<Grid2
			    size={{
				    xs: 12, sm: 12, md: 12, lg: 12, xl: 12,
			    }}
				id={"my-address"}
				alignItems={"center"}
				alignContent={"center"}
				direction={"column"}
				spacing={0}
				container
				item
			>
			    <FormControl
				    className={"update-address signup-form-control"}
				    fullWidth
			    >
					<FindAddressByApi
						addressComponents={addressComponents}
						setAddressComponents={setAddressComponents}
						address_1={formValues.address_1}
						address_2={formValues.address_2}
						address_3={formValues.address_3}
						address_4={formValues.address_4}
						postal_code={formValues.postal_code}
						country={formValues.country}
						setFormValues={setFormValues}
						formValues={formValues}
					/>
				</FormControl>
			</Grid2>
			<Grid2
				id={"signup-container"}
				alignContent={"flex-end"}
				direction={"column"}
				container
				item
				style={{marginBottom: "5%"}}
			>
				<Button
					style={{width: "100px"}}
					color="primary"
					variant="contained"
					onClick={() =>
						saveAddress(
							formValues.address_1,
							formValues.address_2,
							formValues.address_3,
							formValues.address_4,
							formValues.postal_code,
							formValues.country,
							dispatch
						)
					}
					disabled={
						!formValues.address_1 ||
						!formValues.address_2 ||
						!formValues.address_4 ||
						!formValues.postal_code ||
						!formValues.country
					}
				>
					SAVE
				</Button>
			</Grid2>
		</Wrapper>
	);
}

export default ChangeAddress
