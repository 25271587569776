import React from "react";
import {Tooltip} from "@mui/material";
import CompareArrowsIcon from '@mui/icons-material/CompareArrows';
import Grid2 from '@mui/material/Grid2';
import Button from "@mui/material/Button";

/**
 * @param openFirst
 * @param setOpenFirst
 * @returns {JSX.Element}
 */
const customToolbarSelect = (
	{
		openFirst = false,
		setOpenFirst = () => {
		},
	}
) => (
	<Grid2
		marginRight={2}
		direction={"row"}
		container
		item
		justifyContent={"flex-end"}
	>
		<Grid2
			marginRight={2}
		>
			<Tooltip title={"Change Funds"} className={"success-button-toolbar"}>
				<Button
					onClick={() => setOpenFirst(!openFirst)}
				>
					Change Funds
					<CompareArrowsIcon/>
				</Button>
			</Tooltip>
		</Grid2>
	</Grid2>
);

/**
 * @stateless
 * @param props
 * @returns {*}
 */
export default customToolbarSelect;
