import React from "react";
import Grid2 from '@mui/material/Grid2';
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";



/**
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const AdvisorCompanyNumberDialog = ({
	advisorId = 0,
	companyNumber = "",
	handleDialog = false,
	setHandleDialog = () => {
	},
	setCompanyNumber = () => {
	},
	updateCompanyNumber = () => {
	}
}) => {
	return (
		<Grid2>
			<Grid2
				item
			>
				<Dialog
					open={handleDialog}
					onClose={() => setHandleDialog(false)}
					aria-labelledby={"alert-dialog-title"}
					aria-describedby={"alert-dialog-description"}
				>
					<DialogTitle>
						{"Confirm your company number"}
					</DialogTitle>
					<DialogContent>
						<DialogContentText>
							Please provide your company number
						</DialogContentText>
					</DialogContent>
					<Grid2
						item
						p={2}
					>
						<FormControl
							fullWidth
						>
							<TextField
								label={"Company number"}
								variant={"outlined"}
								onChange={(event) => setCompanyNumber(event.target.value)}
								value={companyNumber}
							/>
						</FormControl>
					</Grid2>

					<DialogActions
					>
						<Button
							onClick={() => setHandleDialog(false)}
						>
							Try later
						</Button>
						<Button
							onClick={() => {
								updateCompanyNumber(advisorId, {
									advisors: {
										company_number: companyNumber.replace(/ /g, '').toLowerCase()
									}
								});
							}}
							autoFocus
						>
							Update company number
						</Button>
					</DialogActions>
				</Dialog>
			</Grid2>
		</Grid2>
	);
}

export default AdvisorCompanyNumberDialog
