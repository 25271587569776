import Typography from "@mui/material/Typography";
import Grid2 from '@mui/material/Grid2';

/**
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const PanelHeader = (props) => (
	<Grid2 className={"my-account-change-info-grid-title"}>
		<Typography className={"my-account-change-info-title"} variant="h5" {...props}/>
	</Grid2>
);

export default PanelHeader;
