import React, {createRef, useEffect} from "react";

import {connect} from "react-redux";
import Wrapper from "../../layout/Logged/Wrapper";

import Grid2 from '@mui/material/Grid2';
import * as EMPLOYERS from "../../actions/Employers";

import {me} from "../../actions/Profile";

import FileUpload from "../../components/FileUpload";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import moment from "moment/moment";
import LoadingPanel from "../../components/LoadingPanel";
import InfoOutlined from "@mui/icons-material/InfoOutlined";
import DeleteIcon from '@mui/icons-material/Delete';
import * as POPUP from "../../actions/Popup";
import {PrimaryHeader} from "../../components/Headers/PrimaryHeader";
import SecondaryHeader from "../../components/Headers/SecondaryHeader";
import Button from "@mui/material/Button";
import {ModalContents} from "../../utils/ModalContents";
import TableCellItem from "../../components/TableCellItem";
import Pagination from "@mui/material/Pagination";

/**
 * @type {string}
 */
const acceptedPAPDISFormats = [
	"text/csv",
].join(",");

/**
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const BulkEmployers = (
	{
		getBulkEmployersHistory = () => {},
		deletePastUpload = () => {},
		me = () => {},
		warn = () => {},
		setTriggerUpload = () => {},
		uploadBulkEmployers = () => {},
		triggerModal = () => {},
		history = {},
		bulkEmployersHistory = {
			data: {
				uploaded_date: "",
				number_of_companies: "",
				is_error: false,
				error_data: ""
			},
			last_page: 1,
			current_page: 1
		},
		isLoading = false,
		profile_data = {
			id: 0,
			is_b2b_b2c: false
		}
	}
) => {
	const statuses = {
		0: "In Progress",
		1: "Success",
		2: "Error"
	};

	const URL = process.env.REACT_APP_ADVISOR_DEFAULT_APP_LINK;
	const dropzoneRef = createRef();
	let link_bulk_employer_model = process.env.REACT_APP_API + "download-bulk-employers-csv-model";

	useEffect(() => {
		if (!(dropzoneRef && dropzoneRef.current)) return
		setTriggerUpload(false)
	}, [dropzoneRef, setTriggerUpload]);

	useEffect(() => {
		getBulkEmployersHistory(profile_data?.id, 1);
		me();
	}, [getBulkEmployersHistory, me, profile_data?.id]);

	if (isLoading) {
		return (
			<Grid2
				size={{
					xs: 12, sm: 12, md: 12, lg: 12, xl: 12,
				}}
				style={{
					width: "100%",
				}}
				container
			>
				<LoadingPanel/>
			</Grid2>
		);
	}

	return (
		<Wrapper
			history={history}
			profile_data={profile_data}
		>
			<PrimaryHeader
				backIcon
				helpIcon
				helpModal={ModalContents.bulk_employers_help}
			>
				Bulk Add Employers
			</PrimaryHeader>

			<div className={"collegia-border"}>
				<Grid2
					justifyContent={"flex-end"}
					alignItems={"center"}
					className={"container-wrapper"}
					container
				>
					<a
						target="_blank"
						rel="noreferrer"
						href={link_bulk_employer_model}
					>
						<Button
							className={"default-button"}
						>
							GET CSV MODEL
						</Button>
					</a>
				</Grid2>

				<Grid2
					className={"container-wrapper no-padding-top"}
				>
					<FileUpload
						ref={dropzoneRef}
						acceptedFormats={acceptedPAPDISFormats}
						onError={warn}
						sendFile={uploadBulkEmployers}
						id={profile_data?.id}
						is_b2b_b2c={profile_data?.is_b2b_b2c}
					/>
				</Grid2>
			</div>

			<SecondaryHeader>
				Past Uploads
			</SecondaryHeader>

			<Table>
				<TableHead className={"table-head"}>
					<TableRow>
						<TableCell align={"left"}>
							Upload date
						</TableCell>
						<TableCell align={"left"}>
							Filename
						</TableCell>
						<TableCell align={"left"}>
							Number of Companies
						</TableCell>
						<TableCell align={"center"}>
							Status
						</TableCell>
						<TableCell/>
					</TableRow>
				</TableHead>
				<TableBody>
					{bulkEmployersHistory?.data?.map(h => (
						<TableRow
							key={h.id}
							onClick={
								h.status !== 1 ?
									() => {}
									:
									() => window.open(URL + "/bulk-employers-upload-details/" + h.id)
							}
							style={{cursor: h.status !== 1 ? "default" : "pointer"}}
						>
							<TableCell align={"left"}>
								<TableCellItem
									title={moment(h.upload_date).format("DD/MM/YYYY")}
									subtitle={moment(h.upload_date).format("HH:mm:ss")}
								/>
							</TableCell>

							<TableCell align={"left"}>
								<TableCellItem
									subtitle={h.filename}
								/>
							</TableCell>

							<TableCell align={"left"}>
								<TableCellItem
									subtitle={h.number_of_companies}
								/>
							</TableCell>

							<TableCell align={"center"}>
								<span
									className={`status-${h.status % 3}`}
								>
									{statuses[h.status]}
								</span>
							</TableCell>

							<TableCell>
								{
									h.is_error &&
									<div>
										<InfoOutlined
											className={"info-icon"}
											onClick={() => triggerModal(JSON.parse(h.error_data))}
										/>

										<DeleteIcon
											color={"error"}
											cursor={"pointer"}
											onClick={() => deletePastUpload(h.id, profile_data?.id)}
										/>
									</div>
								}
							</TableCell>
						</TableRow>
					))}
				</TableBody>
			</Table>
			<Grid2
				style={{marginTop: "20px"}}
				justifyContent={"center"}
				container
			>
				{
					(bulkEmployersHistory && (bulkEmployersHistory?.last_page > 1)) && !isLoading && (
						<Grid2>
							<Pagination
								onChange={(e, value) => {
									if (value !== bulkEmployersHistory?.current_page) {
										getBulkEmployersHistory(profile_data?.id, value);
									}
								}}
								page={bulkEmployersHistory?.current_page}
								color={"primary"}
								count={bulkEmployersHistory?.last_page}
							/>
						</Grid2>
					)
				}
			</Grid2>
		</Wrapper>
	);
}

export default connect(
	(state) => ({
		...state.Profile,
		...state.Employer,
	}),
	(dispatch) => ({
		me: () => dispatch(me()),
		setTriggerUpload: (shouldTrigger) =>
			dispatch(EMPLOYERS.setTriggerUpload(shouldTrigger)),
		warn: (msg) => dispatch(POPUP.showUploadErrors([{"message": msg}])),
		uploadBulkEmployers: (files = [], advisor_id) =>
			dispatch(EMPLOYERS.uploadBulkEmployers({bulkEmployersFile: files[0], advisor_id})),
		getBulkEmployersHistory: (advisor_id, page_index) => dispatch(EMPLOYERS.getBulkEmployersHistory(advisor_id, page_index)),
		triggerModal: errors => dispatch(POPUP.showUploadErrors(errors)),
		deletePastUpload: (id, advisor_id) => dispatch(EMPLOYERS.deletePastUpload(id, advisor_id))
	})
)(BulkEmployers);
