import React, {useState} from 'react';
import {connect} from "react-redux";

import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import Grid2 from '@mui/material/Grid2';
import TextField from '@mui/material/TextField';
import Slide from '@mui/material/Slide';
import Typography from '@mui/material/Typography';
import {validateEmail} from "../utils/Helpers";
import LoginSelection from "../components/LoginSelection";
import Wrapper from "../layout/Wrapper";
import Logo from '../images/logo-icon.svg';
import MinimalFooter from "../layout/MinimalFooter";
import {login} from "../actions/Profile";
import {useNavigate} from "react-router-dom";
import Loading from "../components/Loading";

const mountRedirectRoute = (location = {
	from: {
		hash: "",
		pathname: "",
		search: "",
		state: false,
	},
}) => {
	if (location.from) {
		if (location.from.pathname !== "" && location.from.pathname !== "/login") {
			return location.from.pathname;
		}
	}

	return "/main-page";
}

/**
 * @returns {*}
 */
const Login = (props) => {
	const {
		location = {
			from: {
				hash: "",
				pathname: "",
				search: "",
				state: false,
			},
		},
		login_text = "",
		login = () => {
		},
		profile_loading = false,
	} = props;
	const history = useNavigate();

	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");

	const handleKeyDown = (event) => {
		if(event.nativeEvent.code === "Enter")
			login({
				advisors: {
					email,
					password,
				}
			}, mountRedirectRoute(location ?? false));
	}

	if (profile_loading) {
		return (
			<Loading absolute/>
		);
	}

	return (
		<Wrapper showSandwich={false}>
			<Slide
				direction="up"
				in={true}
				mountOnEnter
				unmountOnExit
			>
				<div id={"login-wrapper"}>
					<Grid2
						size={{
							xs: 12, sm: 12, md: 12, lg: 12, xl: 12,
						}}
						alignItems={"center"}
						alignContent={"center"}
						direction={"column"}
						id={"header"}
						container
						item
						onClick={() => history("/login")}
					>
						<img
							src={Logo}
							alt={"Welcome Collegia"}
						/>
					</Grid2>
					<Grid2
						id={"login-container"}
						size={{
							xs: 12, sm: 12, md: 12, lg: 12, xl: 12,
						}}
						alignItems={"center"}
						alignContent={"center"}
						direction={"column"}
						spacing={0}
						container
						item
					>
						<form
							noValidate
							autoComplete="off"
						>
							<FormControl
								className={"login-form-control-2 first-title"}
								fullWidth
							>
								<Typography align={"center"}>
									Welcome to the Collegia way
								</Typography>
							</FormControl>
							<FormControl
								className={"login-form-control-2 second-title"}
								fullWidth
							>
								<Typography align={"center"}>
									Please login to access your account
								</Typography>
							</FormControl>
							<FormControl
								className={"login-form-control"}
								fullWidth
							>
								<LoginSelection history={history} />
							</FormControl>
							<FormControl
								className={"login-form-control"}
								fullWidth
							>
								<TextField
									type={"email"}
									onChange={event => setEmail(event.target.value)}
									label="E-mail"
									variant="outlined"
									name={"email"}
									id={"email"}
									value={email}
									onKeyDown={(event) => {
										handleKeyDown(event)
									}}
									error={(!validateEmail(email) && email.length > 0)}
								/>
								<small className={"red-text"}>
									{
										(!validateEmail(email) && !(email === "")) ?
											"Please enter a valid e-mail"
											: ""
									}
								</small>
							</FormControl>
							<FormControl
								className={"login-form-control"}
								fullWidth
							>
								<TextField
									onChange={event => setPassword(event.target.value)}
									type={"password"}
									label="Password"
									variant="outlined"
									name={"password"}
									id={"password"}
									value={password}
									onKeyDown={(event) => {
										handleKeyDown(event)
									}}
								/>
								<small className={"red-text"}>
									{login_text}
								</small>
							</FormControl>
							<FormControl
								className={"login-form-control-2 forgot-password"}
								fullWidth
							>
                                <span
                                    onClick={() => history('/forgot-password')}
                                >
                                    Forgot your password?
                                </span>
							</FormControl>
							<FormControl
								className={"login-form-control"}
								fullWidth
							>
								<Button
									variant="contained"
									color="primary"
									onClick={() =>
										login({
											advisors: {
												email,
												password,
											}
									}, mountRedirectRoute(location ?? false))}
									disabled={!email && !password}
								>
									Continue
								</Button>
							</FormControl>
						</form>

						<FormControl
							className={"login-form-control form-footer-information"}
							fullWidth
						>
							<Grid2
								size={{
									xs: 12, sm: 12, md: 12, lg: 12, xl: 12,
								}}
								alignItems={"center"}
								alignContent={"center"}
								direction={"column"}
								container
							>
                                    <span>
                                        Don’t have an account?
                                    </span>
							</Grid2>

							<Grid2
								size={{
									xs: 12, sm: 12, md: 12, lg: 12, xl: 12,
								}}
								spacing={2}
								justifyContent={"space-evenly"}
								direction={"row"}
								container
							>
								<Grid2
									size={{
										xs: 6, sm: 6, md: 6, lg: 6, xl: 6,
									}}
									className={"blue pointer"}
									onClick={() => window.location.href = process.env.REACT_APP_INDIVIDUAL_APP + "sign-up"}
									item
								>
									<p>
										SIGN UP FOR YOU
									</p>
								</Grid2>
								<Grid2
									size={{
										xs: 6, sm: 6, md: 6, lg: 6, xl: 6,
									}}
									className={"blue pointer"}
									onClick={() => window.location.href = "/select-service"}
									item
								>
									<p>
										COMPANY SIGN UP
									</p>
								</Grid2>
							</Grid2>
						</FormControl>
					</Grid2>
					<MinimalFooter />
				</div>
			</Slide>
		</Wrapper>
	);
}

/**
 * @param state
 * @returns {*}
 */
const mapStateToProps = state => {
	const {
		Profile
	} = state;

	return {
		...Profile,
	}
};

/**
 * @param dispatch
 * @returns {{login: (function(*=): *)}}
 */
const mapDispatchToProps = dispatch => ({
	login: (data = false, redirectUrl = "/main-page") => dispatch(login(data, redirectUrl)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Login);
