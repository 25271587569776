import Grid2 from '@mui/material/Grid2';

/**
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
export const TabButton = (props) => (
	<Grid2
		className={props.active === 'true' ? 'tab-button' : 'tab-button-inactive'}
		{...props}
	>
		<Grid2
			className={'tab-button-title'}
			justifyContent={"center"}
			alignItems={"center"}
			container
		>
			{props?.children}
		</Grid2>

		<Grid2
			justifyContent={"center"}
			alignItems={"center"}
			container
		>
			<img
				src={props?.image}
				alt={props?.children}
			/>
		</Grid2>
	</Grid2>
);

