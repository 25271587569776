import Wrapper from "../layout/Logged/Wrapper";
import {useNavigate, useParams} from "react-router-dom";
import {me} from "../actions/Profile";
import {connect} from "react-redux";
import {PrimaryHeader} from "../components/Headers/PrimaryHeader";
import MUIDataTable from "mui-datatables";
import React, {useEffect} from "react";
import {getCommunications} from "../actions/Employers";
import Loading from "../components/Loading";

/**
 * @param profile_data
 * @param communications
 * @param is_loading
 * @param getCommunications
 * @returns {JSX.Element}
 * @constructor
 */
const EmployersCommunications = (
	{
		profile_data = {},
		communications = [],
		is_loading = false,
		getCommunications = (id) => {},
	}
) => {
	const history = useNavigate();

	const columns = [
		{
			"name": "title",
			"label": "Company name",
		},
		{
			"name": "company_number",
			"label": "Company number",
		},
		{
			"name": "status",
			"label": "Email status",
		},
		{
			"name": "sent_date",
			"label": "Sent date",
		},
		{
			"name": "description",
			"label": "Email description",
		},
	];

	let id = useParams().id;

	useEffect(() => {
		getCommunications({
			advisors: {
				id: id,
			}
		});
	}, [id, getCommunications]);

	if (is_loading) {
		return (
			<Loading absolute/>
		);
	}

	return (
		<Wrapper history={history} profile_data={profile_data}>
			<div>
				<PrimaryHeader
					backIcon
				>
					Employers communications
				</PrimaryHeader>
				<MUIDataTable
					className={"data-table optout-table"}
					columns={columns}
					data={communications}
					options={{
						filter: false,
						selectableRowsHeader: false,
						selectableRows: "none",
						responsive: "simple",
						viewColumns: false,
					}}
					pagination
				/>
			</div>
		</Wrapper>
	);
}

const mapStateToProps = state => {
	return {
		...state.Profile,
		...state.Employer,
	};
};

const mapDispatchToProps = () => dispatch => ({
	me: dispatch(me()),
	getCommunications: (data) => dispatch(getCommunications(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(EmployersCommunications);
