import React, {useEffect} from "react";
import {connect} from "react-redux";
import Grid2 from '@mui/material/Grid2';
import {TabButton} from "../TabButton";
import add_new_individual from "../../images/undraw_add_user_re_5oib.svg";
import key_stats from "../../images/undraw_metrics_re_6g90.svg";
import funds_available from "../../images/undraw_stock_prices_re_js33.svg";
import {getFunds, getIfaIndividuals} from "../../actions/IFA/IndividualsIFA";
import IndividualsOrEmployersTable from "./IndividualsOrEmployersTable";

/**
 * @param getFunds
 * @param funds
 * @param history
 * @param profile_data
 * @returns {JSX.Element}
 * @constructor
 */
const OverviewIfaDashboard = (
	{
		getFunds = () => {
		},
		funds = [],
		history = {},
		profile_data = {},
	}
) => {

	useEffect(
		() => getFunds(),
		[
			getFunds,
		]
	);

	const mainButtons = {};

	mainButtons.add_new_individual = {
		title: "Add New Client",
		image: add_new_individual,
		link: "/add-new-client",
	};

	mainButtons.key_stats = {
		title: "Key Stats",
		image: key_stats,
		link: "/key-stats"
	};

	mainButtons.funds = {
		title: "Funds",
		image: funds_available,
		link: "/funds"
	};

	return (
		<Grid2>
			<Grid2
				className={"main-buttons-container"}
				container
				justifyContent={"space-between"}
				direction={"row"}
			>
				{
					Object.values(mainButtons).map(({title, image, link}, index) => (
						<TabButton
							active={profile_data?.has_beneficiary.toString()}
							key={index}
							image={image}
							onClick={() =>
								profile_data?.has_beneficiary ?
									history(link)
									:
									""
							}
						>
							{title}
						</TabButton>
					))
				}
			</Grid2>

			<IndividualsOrEmployersTable
				getIfaIndividuals={getIfaIndividuals}
				advisor_id={profile_data?.id}
				profile_data={profile_data}
				funds={funds}
			/>
		</Grid2>
	);
};

export default connect(
	(state) => ({
		...state.Profile,
		...state.IndividualsIFA,
		...state.EmployersIFA
	}),
	(dispatch) => ({
		getIfaIndividuals: (data = {}, queryString = {}) => dispatch(getIfaIndividuals(data, queryString ?? {})),
		getFunds: () => dispatch(getFunds()),
	})
)(OverviewIfaDashboard);
