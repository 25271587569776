import React, {useEffect, useState} from "react";
import {connect, useDispatch} from "react-redux";
import {changeFunds, getIfaIndividuals, requestAccess} from "../../actions/IFA/IndividualsIFA";
import {onChange, toggleSnackBar} from "../../utils/Helpers";
import Grid2 from '@mui/material/Grid2';
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import FormLabel from "@mui/material/FormLabel";
import Select from "@mui/material/Select";
import Step from "@mui/material/Step";
import Stepper from "@mui/material/Stepper";
import StepLabel from "@mui/material/StepLabel";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import {IndividualsView} from "./IndividualsView";
import {EmployersView} from "./EmployersView";
import {employerIfaAccess, getEmployees, getIfaEmployers} from "../../actions/IFA/EmployersIFA";
import TableCellItem from "../TableCellItem";
import MUIDataTable from "mui-datatables";
import CustomToolbarSelectEmployees from "./CustomToolbarSelectEmployees";
import BadgeIcon from '@mui/icons-material/Badge';
import LoadingPanel from "../LoadingPanel";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";

/**
 * @param value
 * @returns {JSX.Element}
 */
const renderAgeBanding = (value) => (
	<Grid2
		container
		justifyContent={"space-around"}
	>
		<TableCellItem
			title={BandingAge(value, 21, 30)}
			subtitle={"21-30"}
			item
			sm={2}
		/>

		<TableCellItem
			title={BandingAge(value, 31, 40)}
			subtitle={"31-40"}
			item
			sm={2}
		/>

		<TableCellItem
			title={BandingAge(value, 41, 50)}
			subtitle={"41-50"}
			item
			sm={2}
		/>
		<TableCellItem
			title={BandingAge(value, 51, 60)}
			subtitle={"51-60"}
			item
			sm={2}
		/>
		<TableCellItem
			title={BandingAge(value, 61, 70)}
			subtitle={"61-70"}
			item
			sm={2}
		/>
	</Grid2>
);

/**
 * @param dateString
 * @param min
 * @param max
 * @returns {number}
 * @constructor
 */
function BandingAge(dateString, min, max): number {
	let count = 0
	dateString.forEach((item) => {
		let today = new Date();
		let birthDate = new Date(item.birthdate);
		let age = today.getFullYear() - birthDate.getFullYear();
		if (age >= min && age <= max) {
			count += 1;
		}
	})
	return count;
}

/**
 * @param status
 * @returns {string}
 */
export const getIndividualStatus = (status): string => {
	switch (status) {
		case true:
			return 'status-1';
		case false:
			return 'status-2';
		default:
			return 'status-3';
	}
};

/**
 * @param value
 * @returns {JSX.Element}
 */
const renderStatus = (value) => {

	let status = getIndividualStatus(value);

	return (
		<span
			className={`individuals-table ${status}`}
		>
				{
					value === null ?
						"Pending"
						:
						value ?
							"Active"
							:
							"Refused"
				}
			</span>
	);
};

/**
 * @param individuals
 * @param individuals_loading
 * @param getIfaIndividuals
 * @param advisor_id
 * @param funds
 * @param changeFunds
 * @param employers_ifa
 * @param employerIfaAccess
 * @param getIfaEmployers
 * @param getEmployees
 * @param employees
 * @param requestAccess
 * @param employers_loading_table
 * @param employees_table_loading
 * @returns {JSX.Element}
 * @constructor
 */
const IndividualsOrEmployersTable = (
	{
		individuals = [],
		individuals_loading = false,
		getIfaIndividuals = () => {
		},
		advisor_id = 0,
		funds = [],
		changeFunds = () => {
		},
		employers_ifa = [],
		employerIfaAccess = () => {
		},
		getIfaEmployers = () => {
		},
		getEmployees = () => {
		},
		employees = [],
		requestAccess = () => {
		},
		employers_loading_table = false,
		employees_table_loading = false,
	}
) => {

	const dispatch = useDispatch();
	const [openFirst, setOpenFirst] = useState(false);
	const [openEmployees, setOpenEmployees] = useState(false);
	const [searchFindText, setSearchFindText] = useState("");
	const [searchFindTextWithTimeOut, setSearchFindTextWithTimeOut] = useState("");
	const [selectedIndividuals, setSelectedIndividuals] = useState([]);
	const [selectedEmployers, setSelectedEmployers] = useState([]);
	const [selectedEmployees, setSelectedEmployees] = useState([]);
	const [selectedFund, setSelectedFund] = useState("");
	const [employerView, setEmployerView] = useState(false);
	const [activeStep, setActiveStep] = useState(0);
	const [ifaFee, setIfaFee] = useState("");

	const ITEM_HEIGHT = 48;
	const ITEM_PADDING_TOP = 8;
	const MenuProps = {
		PaperProps: {
			style: {
				maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
				width: 250,
			},
		},
	};

	const steps = [
		'Choose the fee to be charged',
		'Select the employees',
	];

	const individualsColumns = [
		{
			name: "individual_id",
			label: "ID",
			options: {
				display: false,
			}
		},
		{
			name: 'full_name',
			label: 'NAME',
			options: {
				filter: true,
				sort: true,
				searchable: true,
				display: true,
				viewColumns: true,
				customBodyRender: (value, tableMeta) => tableMeta.rowData[2] + " " + (tableMeta.rowData[3] ?? ""),
			},
		},
		{
			name: 'forename',
			label: 'Forename',
			flex: 1,
			options: {
				display: false,
			}
		},
		{
			name: 'surname',
			label: 'Surname',
			flex: 1,
			options: {
				display: false,
			}
		},
		{
			name: "national_insurance_number",
			label: "NIN",
			options: {
				setCellProps: () => ({className: "table-cell-item-subtitle table-cell-item-text-align"})
			}
		},
		{
			name: "total_pot",
			label: "Total_Pot",
			options: {
				sort: false,
				searchable: false,
				customBodyRender: (value) => value !== undefined && ("£ " + parseFloat(value)?.toLocaleString("en")),
				setCellProps: () => ({className: "table-cell-item-subtitle table-cell-item-text-align"}),
			}
		},
		{
			name: 'projection',
			label: 'EXPECTED TOTAL POT',
			flex: 1,
			options: {
				sort: false,
				searchable: false,
				customBodyRender: (value) => value !== undefined && ("£ " + parseFloat(value)?.toLocaleString("en")),
				setCellProps: () => ({className: "table-cell-item-subtitle table-cell-item-text-align"}),
			}
		},
		{
			name: 'birthdate',
			label: 'Date of Birth',
			flex: 1,
			options: {
				sort: false,
				searchable: false,
				setCellProps: () => ({className: "table-cell-item-subtitle table-cell-item-text-align"}),
			}
		},
		{
			name: 'investment_strategy',
			label: 'INSIDE DEFAULT ?',
			flex: 1,
			options: {
				sort: false,
				searchable: false,
				customBodyRender: (value, tableMeta) => tableMeta.rowData[10] === true && (value?.name === 'Sustainable' ? "Yes" : "No"),
				setCellProps: () => ({className: "table-cell-item-subtitle table-cell-item-text-align"}),
			}
		},
		{
			name: "funds",
			label: "Fund Invested",
			options: {
				sort: false,
				searchable: false,
				customBodyRender: (value) => (
					<Grid2>
						{
							value?.map(key => (
								value.length > 1 ?
									<span key={key?.id}>{key?.name} | </span>
									:
									<span key={key?.id}>{key?.name}</span>
							))
						}
					</Grid2>
				),
				setCellProps: () => ({className: "table-cell-item-subtitle table-cell-item-text-align"}),
			}
		},
		{
			name: "active",
			label: "Status",
			options: {
				customBodyRender: (value) => renderStatus(value),
				setCellProps: () => (
					{className: `table-cell-item-text-align`}),
			}
		},
	];

	const employersColumns = [
		{
			name: "employers_id",
			label: "ID",
			options: {
				display: false,
			}
		},
		{
			name: 'title',
			label: 'EMPLOYER',
			options: {
				setCellProps: () => (
					{className: `table-cell-item-text-align`}
				),
			}
		},
		{
			name: 'ifa_allowed',
			label: 'IFA SERVICES ALLOWED',
			options: {
				customBodyRender: (value, tableMeta) => (tableMeta.rowData[3] === null && value === null) ? "" : renderStatus(value),
				setCellProps: () => (
					{className: `table-cell-item-text-align`}
				),
			}
		},
		{
			name: "requested_access",
			label: "STATUS",
			options: {
				display: false
			}
		},
		{
			name: 'employees',
			label: 'EMPLOYEES AGE BANDING',
			options: {
				customBodyRender: (value) => value !== undefined && (renderAgeBanding(value)),
				setCellProps: () => (
					{className: `table-cell-item-text-align`}
				),
				sort: false,
			}
		},
		{
			name: "ifa_allowed",
			label: "ACTIONS",
			options: {
				customBodyRender: (value, tableMeta) => value === true && (
					<Tooltip title={"View Employees"}>
						<BadgeIcon
							className={"info-icon"}
							onClick={() => {
								getEmployees({
									ifa_employers: {
										advisor_id,
										filter: searchFindText,
										employer_id: tableMeta.rowData[0]
									}
								});
								setOpenEmployees(true);
							}}
						>
							VIEW EMPLOYEES
						</BadgeIcon>
					</Tooltip>
				),
				setCellProps: () => (
					{className: `table-cell-item-text-align`}
				),
			}
		},

	];

	const employeesColumns = [
		{
			name: "individual_id",
			label: "ID",
			options: {
				display: false,
			}
		},
		{
			name: "policy_number",
			label: "policy_number",
			options: {
				display: false,
			}
		},
		{
			name: 'forename',
			label: 'FORENAME',
			options: {
				setCellProps: () => (
					{className: `table-cell-item-text-align`}),
			}
		},
		{
			name: 'surname',
			label: 'SURNAME',
			options: {
				setCellProps: () => (
					{className: `table-cell-item-text-align`}),
			}
		},
	];

	useEffect(() => {
		const delayDebounceFn = setTimeout(() => {
			if (searchFindText.length >= 2 || searchFindText.length === 0) {
				if (employerView) {
					getIfaEmployers({
						ifa_employers: {
							advisor_id,
							filter: searchFindText
						}
					});
				} else {
					getIfaIndividuals({
						advisors: {
							advisor_id,
							filter: searchFindText
						}
					});
				}

			} else {
				toggleSnackBar(dispatch,
					false,
					true,
					false,
					"The filter must be greater than 2 characters!",
					{},
					true,
				);
			}
		}, 1000);

		return () => clearTimeout(delayDebounceFn)
	}, [getIfaIndividuals, searchFindText, dispatch, advisor_id, employerView, getIfaEmployers]);

	useEffect(() => {
		if (employers_ifa.length === 0 && employerView) {
			getIfaEmployers({
				ifa_employers: {
					advisor_id,
					filter: searchFindText
				}
			});
		}

	}, [employers_ifa, employerView, getIfaEmployers, advisor_id, searchFindText]);

	return (
		<Grid2>
			{
				employerView ?
					<EmployersView
						setSearchFindText={setSearchFindText}
						setSelectedEmployers={setSelectedEmployers}
						setEmployerView={setEmployerView}
						searchFindTextWithTimeOut={searchFindTextWithTimeOut}
						setSearchFindTextWithTimeOut={setSearchFindTextWithTimeOut}
						individuals_loading={individuals_loading}
						employers_ifa={employers_ifa}
						employersColumns={employersColumns}
						employerView={false}
						getIfaEmployers={getIfaEmployers}
						selectedEmployers={selectedEmployers}
						advisor_id={advisor_id}
						searchFindText={searchFindText}
						dispatch={dispatch}
						employerIfaAccess={employerIfaAccess}
						employers_loading_table={employers_loading_table}
					/>
					:
					<IndividualsView
						setSearchFindText={setSearchFindText}
						setSelectedIndividuals={setSelectedIndividuals}
						setEmployerView={setEmployerView}
						setSearchFindTextWithTimeOut={setSearchFindTextWithTimeOut}
						individuals_loading={individuals_loading}
						individuals={individuals}
						individualsColumns={individualsColumns}
						employerView={true}
						getIfaIndividuals={getIfaIndividuals}
						selectedIndividuals={selectedIndividuals}
						advisor_id={advisor_id}
						searchFindText={searchFindText}
						openFirst={openFirst}
						setOpenFirst={setOpenFirst}
					/>
			}
			<Dialog
				open={openFirst}
				maxWidth={false}
			>
				<DialogContent>
					<Grid2>
						Are you sure you want to change funds for these individuals: <br/>
						<ul>
							{selectedIndividuals.map(individual =>
								<li> {individual?.forename} {individual?.surname} </li>)}
						</ul>

					</Grid2>
					<Grid2>
						<FormControl fullWidth>
							<FormLabel htmlFor={`funds`}>
								Select the fund that you want to move your clients to:
							</FormLabel>
							<Select
								id="funds"
								name={"funds"}
								placeholder={`Select fund*`}
								MenuProps={MenuProps}
								value={selectedFund}
								onChange={(event) => {
									onChange(setSelectedFund(event.target.value));
								}}
								variant={"filled"}
							>
								{
									funds.map(fund => (
										<MenuItem key={fund.id} value={fund.id}>{fund.name}</MenuItem>
									))
								}
							</Select>
						</FormControl>
					</Grid2>
				</DialogContent>
				<DialogActions>
					<Button
						className={"MuiCardHeader-button generic-modal-button"}
						onClick={() => setOpenFirst(!openFirst)}
					>
						Cancel
					</Button>
					<Button
						className={"MuiCardHeader-button generic-modal-button"}
						onClick={() => {
							changeFunds({
								advisors: {
									individuals: selectedIndividuals,
									change_fund_id: selectedFund
								}
							});
							setOpenFirst(!openFirst);
						}}
					>
						Confirm
					</Button>
				</DialogActions>
			</Dialog>
			<Dialog
				open={openEmployees}
				maxWidth={false}
			>
				<DialogContent>
					<Stepper activeStep={activeStep} alternativeLabel>
						{
							steps.map((label) => (
								<Step key={label}>
									<StepLabel>{label}</StepLabel>
								</Step>
							))
						}
					</Stepper>
					{
						activeStep === 0 && (
							<Grid2
								marginTop={2}
							>
								<FormControl
									fullWidth
								>
									<TextField
										type={"numeric"}
										value={ifaFee}
										onChange={(event) => setIfaFee(event.target.value)}
										label={"Annual Fee*"}
										variant={"outlined"}
										name={"ifaFee"}
										id={"ifaFee"}
										inputMode={"decimal"}
										InputProps={{
											startAdornment: <InputAdornment position="start">%</InputAdornment>,
										}}
										inputProps={{
											maxLength: 4,
											inputMode: 'numeric',
											pattern: '[0-9]'
										}}
									/>
								</FormControl>
							</Grid2>
						)
					}
					{
						activeStep === 1 && (
							<Grid2>
								{
									employees_table_loading ?
										<LoadingPanel/>
										:
										<MUIDataTable
											className={"data-table individuals-table modal_mui"}
											columns={employeesColumns}
											data={employees ?? []}
											options={{
												searchPlaceholder: "Search an employee",
												searchOpen: true,
												searchAlwaysOpen: true,
												search: false,
												print: false,
												elevation: 0,
												filter: false,
												selectableRowsHeader: false,
												selectableRows: "multiple",
												responsive: "simple",
												viewColumns: false,
												onRowSelectionChange: (
													currentRowsSelected: [],
													allRowsSelected: [],
												) => {
													setSelectedEmployees([]);
													const selectedEmployees = [];
													allRowsSelected.forEach((row) => {
														selectedEmployees.push(employees[row.dataIndex]?.policy_number);
													});
													setSelectedEmployees(selectedEmployees);
												},

												customToolbarSelect: () => {
													return (
														<CustomToolbarSelectEmployees
															selectedEmployees={selectedEmployees}
															requestAccess={requestAccess}
															advisor_id={advisor_id}
															ifaFee={ifaFee}
														/>
													);
												},
											}}
										/>
								}
							</Grid2>
						)
					}
				</DialogContent>
				<DialogActions>
					{
						activeStep === 1 && (
							<Button
								className={"MuiCardHeader-button generic-modal-button"}
								onClick={() => {
									setActiveStep(0);
								}}
							>
								BACK
							</Button>
						)
					}
					<Button
						className={"MuiCardHeader-button generic-modal-button"}
						onClick={() => {
							setOpenEmployees(!openEmployees);
							setIfaFee("");
							setActiveStep(0);
							setSelectedEmployers([]);
						}}
					>
						CLOSE
					</Button>
					{
						activeStep === 0 && (
							<Button
								className={"MuiCardHeader-button generic-modal-button"}
								onClick={() => {
									setActiveStep(1);
								}}
								disabled={ifaFee.length < 1}
							>
								CONTINUE
							</Button>
						)
					}
				</DialogActions>
			</Dialog>
		</Grid2>
	);
}

const mapStateToProps = state => {
	return {
		...state.IndividualsIFA,
		...state.EmployersIFA
	};
};

const mapDispatchToProps = dispatch => ({
	changeFunds: (data = {}) => dispatch(changeFunds(data)),
	employerIfaAccess: (data = {}) => dispatch(employerIfaAccess(data)),
	getIfaIndividuals: (data = {}, queryString = {}) => dispatch(getIfaIndividuals(data, queryString)),
	getIfaEmployers: (data = {}, queryString = {}, first = true) => dispatch(getIfaEmployers(data, queryString, first)),
	getEmployees: (data = {}, queryString = {}) => dispatch(getEmployees(data, queryString)),
	requestAccess: (data = {}) => dispatch(requestAccess(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(IndividualsOrEmployersTable);
