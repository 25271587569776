import React from "react";

import Container from '@mui/material/Container';

import Header from './Header';


/**
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const WrapperComponent = (props = {
    showSandwich: false,
}) => {

    const [isOpen, setHandleDrawer] = React.useState(false);

    const handleDrawer = () => {
        setHandleDrawer(!isOpen);
    };

    return (
        <Container
            className={"no-padding"}
            fixed
        >
            <Header
                handleDrawer={handleDrawer}
                isOpen={isOpen}
                showSandwich={props.showSandwich}
            />
            {props.children}

        </Container>
    );
};

/**
 * @stateless
 * @param props
 * @returns {*}
 */
export default WrapperComponent;
