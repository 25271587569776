import * as React from 'react';
import Modal from "@mui/material/Modal";
import Grid2 from '@mui/material/Grid2';
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";

/**
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const UploadBankDdiFileModal = ({
	open = false,
	handleModal = () => {
	}
}) => {
	const [changeTextRedirectPage, setChangeTextRedirectPage] = React.useState(true);

	return (
		<Grid2>
			<Modal
				open={open}
				aria-labelledby={"simple-modal-title"}
				aria-describedby={"simple-modal-description"}
				className={"upload-ddi-history-modal"}
				closeAfterTransition
				onClose={() => handleModal(open)}
			>
				<Grid2
					size={{
						xs: 12, sm: 12, md: 12, lg: 12, xl: 12,
					}}
					container
					item
					justifyContent={"flex-start"}
				>
					<Grid2
						size={{
							xs: 12, sm: 12, md: 12, lg: 12, xl: 12,
						}}
						item
						className={"generic-modal-style"}
					>
						<Grid2
							size={{
								xs: 12, sm: 12, md: 12, lg: 12, xl: 12,
							}}
							container
							item
							justifyContent={"flex-start"}
						>
							<Grid2
								size={{
									xs: 12, sm: 12, md: 12, lg: 12, xl: 12,
								}}
								item
							>
								{
									changeTextRedirectPage ?
										<h2
											className={"generic-modal-title"}
										>
											How to proceed ?
										</h2>
										:
										<h2
											className={"generic-modal-title"}
										>
											Next Steps
										</h2>
								}
							</Grid2>
						</Grid2>
						<Grid2
							size={{
								xs: 12, sm: 12, md: 12, lg: 12, xl: 12,
							}}
							justifyContent={"flex-start"}
							container
							item
						>
							<Grid2
								item
								className={"generic-modal-body"}
							>
								<Typography
									component={"div"}
									variant={"subtitle1"}
								>
									{
										changeTextRedirectPage ?
											<ul>
												<li>
													We will open a new tab, where you will be asked to fill
													in your full name and email address.
												</li>
												<li>
													After that, you will be able to fill the form
													online and when you are finished just click on done.
												</li>
												<li>
													Last, you will be given the option to download the file.
													Just click on that option and after that you can close <br/>
													that tab and upload the file directly into the Collegia dashboard.
												</li>
											</ul>
											:
											<ul>
												<li>
													Now, you can upload the file directly into the Collegia Dashboard.
												</li>
												<li>
													If you were not able to download the file, please contact us through
													the Collegia chat, located on the bottom right corner of the page.
												</li>
											</ul>
									}
								</Typography>
							</Grid2>
						</Grid2>

						<Grid2
							container
							justifyContent={"flex-end"}
						>
							{
								changeTextRedirectPage ?
									<Grid2
										container
										justifyContent={"flex-end"}
										spacing={2}
									>
										<Grid2
											item
										>
											<Button
												onClick={() => handleModal(open)}
												className={"MuiCardHeader-button"}
											>
												CANCEL
											</Button>
										</Grid2>

										<Grid2
											item
										>
											<Button
												onClick={() => {
													window.open("https://pdf.ac/14uU3H");
													setChangeTextRedirectPage(false);
												}}
												className={"MuiCardHeader-button"}
											>
												CONTINUE
											</Button>
										</Grid2>
									</Grid2>
									:
									<Grid2
										container
										item
										justifyContent={"center"}
									>
										<Button
											onClick={() => handleModal(open)}
											className={"MuiCardHeader-button"}
										>
											OK
										</Button>
									</Grid2>
							}
						</Grid2>
					</Grid2>
				</Grid2>
			</Modal>
		</Grid2>
	);
}

export default UploadBankDdiFileModal;
