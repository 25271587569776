import React from "react";

import CircularProgress from "@mui/material/CircularProgress";

/**
 * @param props
 * @returns {JSX.Element}
 */
const circularProgress = (props) => (
	<CircularProgress
		style={props.inPlace === true ? {position: 'initial'} : {}}
		className={"logo-icon-loading"}
		id={props.absolute ? "absolute-loading" : "loading"}
	/>
);

/**
 * @stateless
 * @param props
 * @returns {*}
 */
export default circularProgress;
