import {TRIGGER_SNACK_BAR} from "../actions/SnackBar/types";
import moment from "moment";
import FormControl from "@mui/material/FormControl";
import Typography from "@mui/material/Typography";
import _ from "lodash";

/**
 * @type {number[]}
 */
const maxValueMonth = [31, 31, 29, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];

/**
 * @type {string[]}
 */
const formatOrder = ['yyyy', 'yy', 'mm', 'dd', 'HH', 'MM', 'SS'];

/**
 * Default input handler function.
 *
 * This function is for set state in components dynamically.
 *
 * @param event
 * @param component
 * @param onlyNumbers
 */
export function onChange(event = null, component = null, onlyNumbers: boolean = false) {
	if (event && component) {
		if (event.target) {
			let value = event.target.value;
			if (onlyNumbers) {
				if (value !== undefined) {
					if (typeof value.replace === "function") {
						value = value.replace(/[^0-9]/gi, '');
						value = value.replace(/^0+/, '')
					}
				}
			}
			component.setState({[event.target.name]: value});
		}
	}
}

/**
 * @param state
 * @param action
 * @returns {{valueOf(): boolean}|boolean}
 */
export function resolveReduxState(state = false, action = false) {
	if (action && state) {

		return {
			...state,
			...action,
		}
	}

	return state;
}

/**
 /**
 * Returns a random integer between min (inclusive) and max (inclusive).
 * The value is no lower than min (or the next integer greater than min
 * if min isn't an integer) and no greater than max (or the next integer
 * lower than max if max isn't an integer).
 * Using Math.round() will give you a non-uniform distribution!
 *
 * @param min
 * @param max
 * @returns {*}
 */
export function getRandomInt(min, max) {
	min = Math.ceil(min);
	max = Math.floor(max);
	return Math.floor(Math.random() * (max - min + 1)) + min;
}

/**
 * @param dispatch
 * @param success
 * @param error
 * @param info
 * @param message
 * @param custom_data
 * @param open
 */
export function toggleSnackBar(dispatch, success = false, error = false, info = false,
                               message = "", custom_data = {}, open = true) {
	dispatch({
		type: TRIGGER_SNACK_BAR,
		open,
		success,
		error,
		info,
		message,
		...custom_data,
	});
}

/**
 * @param email
 * @returns {boolean}
 */
export function validateEmail(email) {
	/*eslint-disable */
	const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
	return re.test(email);
	/*eslint-enable */
}

/**
 * @param str
 * @returns {boolean}
 */
export function checkPassword(str = ""): boolean {
	/*eslint-disable */
	if (str.length < 6) {
		return false;
	} else if (str.length > 50) {
		return false;
	} else if (str.search(/\d/) === -1) {
		return false;
	} else if (str.search(/[a-zA-Z]/) === -1) {
		return false;
	}
	// else if (str.search(/[^a-zA-Z0-9\!\@\#\$\%\^\&\*\(\)\_\+\.\,\;\:]/) !== -1) {
	//     return false;
	// }
	/*eslint-enable */
	return true;
}

/**
 * DO not delete this!!
 *
 * @param obj
 * @returns {boolean}
 */
export function isEmptyObject(obj): boolean {
	return Object.keys(obj).length === 0;
}

/**
 * @returns {string}
 */
export const retrieveCollegiaAccessToken = (): string => {
	let token = localStorage.getItem("token");

	if (!token || token === "") {
		token = localStorage.getItem("collegiaToken");
	}

	if (!token || token === "") {
		token = window.localStorage.getItem("collegiaToken");
	}

	if (!token || token === "") {
		token = sessionStorage.getItem("collegiaToken");
	}

	if (!token || token === "") {
		token = window.sessionStorage.getItem("collegiaToken");
	}


	return token;
}

/**
 * @param componentRef
 * @param forceScroll
 */
export function forceScrollUp(componentRef: any = false, forceScroll = true) {

	if (componentRef && componentRef.current !== null && forceScroll) {
		componentRef.current.scrollTo(0, 0);
	}

	const root = document.getElementById("root");

	if (forceScroll) {
		if (root) {
			root.scrollTo(0, 0);
		}
		window.scrollTo(0, 0);
	}
}

/**
 * @param componentRef
 * @param forceScroll
 */
export function removeDefaultLoggedLayout(componentRef: any = false, forceScroll = true): void {
	const menu = document.getElementById("menu-logged");
	const menu2 = document.getElementById("menu-logged-wrapper");
	const footer = document.getElementById("footer-logged")

	if (menu) {
		menu.style.display = "none";
	}

	if (footer) {
		footer.style.display = "none";
	}

	if (menu2) {
		menu2.style.display = "none";
	}

	forceScrollUp(componentRef ?? false, forceScroll);
}

/**
 * Aggregation function for multiple class inheritance.
 *
 * DO NOT DELETE THIS!!!!
 *
 * @param baseClass
 * @param mixins
 * @returns {base}
 */
export const aggregation = (baseClass, ...mixins) => {
	class base extends baseClass {
		constructor(...args) {
			super(...args);
			mixins.forEach((mixin) => {
				/*eslint-disable */
				copyProps(this, (new mixin));
				/*eslint-enable */
			});
		}
	}

	// this function copies all properties and symbols, filtering out some special ones
	let copyProps = (target, source) => {
		Object.getOwnPropertyNames(source)
			.concat(Object.getOwnPropertySymbols(source))
			.forEach((prop) => {
				if (!prop.match(/^(?:constructor|prototype|arguments|caller|name|bind|call|apply|toString|length)$/))
					Object.defineProperty(target, prop, Object.getOwnPropertyDescriptor(source, prop));
			})
	}

	// outside contructor() to allow aggregation(A,B,C).staticFunction() to be called etc.
	mixins.forEach((mixin) => {
		copyProps(base.prototype, mixin.prototype);
		copyProps(base, mixin);
	});
	return base;
}

/**
 * @param dateFormat
 * @param minYear
 * @param maxYear
 * @returns {function(*): (boolean|{value: *, indexesOfPipedChars: []})}
 */
export const createAutoCorrectedDatePipe = (dateFormat = 'dd/mm/yyyy', {
	minYear = 1,
	maxYear = 9999
} = {}) => {
	const dateFormatArray = dateFormat
		.split(/[^dmyHMS]+/)
		.sort((a, b) => formatOrder.indexOf(a) - formatOrder.indexOf(b));

	return function (conformedValue) {
		const indexesOfPipedChars = [],
			maxValue = {'dd': 31, 'mm': 12, 'yy': 99, 'yyyy': maxYear, 'HH': 23, 'MM': 59, 'SS': 59},
			minValue = {'dd': 1, 'mm': 1, 'yy': 0, 'yyyy': minYear, 'HH': 0, 'MM': 0, 'SS': 0},
			conformedValueArr = conformedValue.split('');

		// Check first digit
		dateFormatArray.forEach((format) => {
			const position = dateFormat.indexOf(format);
			const maxFirstDigit = parseInt(maxValue[format].toString().substr(0, 1), 10);

			if (parseInt(conformedValueArr[position], 10) > maxFirstDigit) {
				conformedValueArr[position + 1] = conformedValueArr[position];
				conformedValueArr[position] = 0;
				indexesOfPipedChars.push(position);
			}
		})

		// Check for invalid date
		let month = 0;
		const isInvalid = dateFormatArray.some((format) => {
			const position = dateFormat.indexOf(format);
			const length = format.length;
			const textValue = conformedValue.substr(position, length).replace(/\D/g, '');
			const value = parseInt(textValue, 10);
			if (format === 'mm') {
				month = value || 0;
			}
			const maxValueForFormat = format === 'dd' ? maxValueMonth[month] : maxValue[format];
			if (format === 'yyyy' && (minYear !== 1 || maxYear !== 9999)) {
				const scopedMaxValue = parseInt(maxValue[format].toString().substring(0, textValue.length), 10),
					scopedMinValue = parseInt(minValue[format].toString().substring(0, textValue.length), 10);
				return value < scopedMinValue || value > scopedMaxValue;
			}
			return value > maxValueForFormat || (textValue.length === length && value < minValue[format]);
		})

		if (isInvalid) {
			return false;
		}

		return {
			value: conformedValueArr.join(''),
			indexesOfPipedChars
		};
	}
}

/**
 * Validate user's staging date
 * @param stagingDate
 * @returns {boolean}
 */
export function validStagingDate(stagingDate: string = ""): boolean {
	if (stagingDate === "" || stagingDate === undefined || stagingDate === false) {
		return false;
	}

	if (typeof stagingDate.replace === "function") {
		if (stagingDate.trim().replace(/[^\w\s]/gi, '').length < 8) {
			return false;
		}
	}

	stagingDate = stagingDate.split("/").reverse().join("-");

	const hour = moment().diff(stagingDate, 'hours', false);

	return !(isNaN(hour) || hour < 0);
}

/**
 * @returns {string}
 * @param status
 */
export const getEmployerStatus = (status): string => {
	switch (status) {
		case 'Active':
		case 'Active Push Payment':
			return 'status-1'
		case 'Refused':
			return 'status-2'
		case 'Pending DDI':
			return 'status-3'
		default:
			return 'status-4'
	}
}

export const toCurrency: Function<Number, String> = (
	value: number = 0
) => (value ? parseFloat(value) : 0.0)
	.toFixed(2)
	.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
	.toString();

export const toLocaleDate = date => moment(date)?.format("DD/MM/YYYY");

/**
 * @param date
 * @returns {string}
 */
export const toLocaleTime = (date: any = ""): string => moment(date)?.format("HH:mm:ss") ?? "";

export const getQueryStringsParams = () => new Proxy(new URLSearchParams(window.location.search), {
	get: (searchParams, prop) => searchParams.get(prop),
});


/**
 * @param referral
 * @returns {JSX.Element}
 */
export const renderReferralData = (referral = {
	deal_owner: {
		name: "",
	}
}) => {

	if (referral?.deal_owner?.forename) {
		return (
			<div>
				<FormControl
					className={"signup-form-control-2 first-title strong"}
					fullWidth
				>
					<Typography align={"center"}>
						<strong>
							{referral?.deal_owner?.forename} {referral?.deal_owner?.surname}
						</strong> invited you to create an account.
					</Typography>
				</FormControl>
			</div>
		);
	}

	return [];
}

/**
 * @param string
 * @returns {string}
 */
export const capitalizeFirstLetter = (string = ""): string => {
	return string.charAt(0).toUpperCase() + string.slice(1);
}

export const onlyUnique = (value, index, array) => {
	return array.indexOf(value) === index;
};

export const whereLikeEmployers = (list = [], expression = "") => {

	if (expression !== "") {
		let data = [
			...new Set(
				list.filter(
					l => l?.employer?.title?.toUpperCase()?.match(expression.toUpperCase()) ||
						l?.employer?.company_number?.toUpperCase()?.match(expression.toUpperCase())
				).filter(Boolean)
			)
		];
		return _.uniqBy(data, 'employer_id');
	}

	return _.uniqBy(list, 'employer_id');
}
