import React from 'react';

import Grid2 from '@mui/material/Grid2';


const LoginSelection: React.PureComponent = ({ history = { push: () => {}} }) => (
	<Grid2
		id={"environment-buttons"}
		alignItems={"center"}
		alignContent={"center"}
		direction={"row"}
		spacing={3}
		container
	>
		<Grid2
			size={{
				xs: 4, sm: 4, md: 4, lg: 4, xl: 4,
			}}
			container
			item
		>
			<a
				href={process.env.REACT_APP_INDIVIDUAL_APP}
				target={"_blank"}
				rel="noopener noreferrer"
			>
				INDIVIDUAL
			</a>
		</Grid2>
		<Grid2
			size={{
				xs: 4, sm: 4, md: 4, lg: 4, xl: 4,
			}}
			container
			item
		>
			<a
				href={process.env.REACT_APP_EMPLOYER_APP}
				target={"_blank"}
				rel="noopener noreferrer"
			>
				EMPLOYER
			</a>
		</Grid2>
		<Grid2
			size={{
				xs: 4, sm: 4, md: 4, lg: 4, xl: 4,
			}}
			container
			item
		>
			<a
				href={process.env.REACT_APP_ADVISOR_DEFAULT_APP_LINK}
				className={"active"}
				target={"_blank"}
				rel="noopener noreferrer"
			>
				ADVISOR
			</a>
		</Grid2>
	</Grid2>
);

export default LoginSelection;
