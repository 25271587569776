import Tooltip from "@mui/material/Tooltip";
import Button from "@mui/material/Button";
import Grid2 from '@mui/material/Grid2';

import PropTypes from "prop-types";
import {useNavigate} from "react-router-dom";

/**
 * @param profile_data
 * @returns {string}
 */
const _renderMandateText = (
	profile_data: any = {
		ddi_pdf_link: false,
		is_push_payment: false,
		mandate: null,
	}
) => {
	if (!profile_data?.mandate && profile_data?.ddi_pdf_link?.length > 0) {
		return "We are processing your DDI. It may take up to 30 minutes for the file to be processed.";
	} else {
		if (profile_data?.mandate) {
			return "Change Direct Debit";
		}
		return "Activate Direct Debit";
	}
};

/**
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const ActiveDirectDebitButton = (
	{
		history = {
			push: () => {
			},
		},
		profile_data = {
			mandate: {
				mandate_started: false,
			},
			is_push_payment: false,
		}
	}
): JSX.Element => {
	history = useNavigate();
	if (!profile_data.is_push_payment) {
		if (profile_data?.mandate && !profile_data?.mandate?.mandate_started) {
			return (
				<Grid2
					size={{
						xs: 12, sm: 12, md: 12, lg: 12, xl: 12,
					}}
					id={"direct-debit-button-container"}
					container
					item
				>
					<Tooltip title={
						"You can only change your direct debit instruction after the " +
						"already existing mandate is concluded."}
					>
						<span>
							<Button
								className={"setup-button"}
								onClick={() => false}
								fullWidth
							>
								Mandate is Processing - You can only change your direct debit after your current mandate
								has been activated.
							</Button>
						</span>
					</Tooltip>
				</Grid2>
			);
		}
	}

	return (
		profile_data?.is_b2b_b2c && !profile_data.is_push_payment && (
			<Grid2
				size={{
					xs: 12, sm: 12, md: 12, lg: 12, xl: 12,
				}}
				id={"direct-debit-button-container"}
				container
				item
			>
				{
					history &&
					profile_data.is_push_payment !== true
					&& (
						<Tooltip title={!profile_data?.mandate ?
							"You can only change your direct debit instruction after the " +
							"already scheduled contributions have been collected." :
							"Click to change your Debit Direct"}
						>
							<span>
								<Button
									className={profile_data?.mandate ? "default-button" : "setup-button"}
									onClick={() => history('/active-account', {
										changeDdi: true,
										contributionstab: true
									})}
									fullWidth
									disabled={
										!profile_data?.mandate &&
										profile_data?.ddi_pdf_link?.length > 0
									}
								>
									{_renderMandateText(profile_data)}
								</Button>
							</span>
						</Tooltip>
					)}
			</Grid2>
		)
	)
};

ActiveDirectDebitButton.propTypes = {
	history: PropTypes.func.isRequired,
	profile_data: PropTypes.object.isRequired,
};

export default ActiveDirectDebitButton;
