import React from 'react'
import {useState, useEffect} from 'react'
import {connect, useDispatch} from "react-redux";
import {setPusherClient} from 'react-pusher';
import Pusher from 'pusher-js';
import * as Notifications from "../actions/Notifications"
import {notificationAction} from "../actions/Notifications";
import {PAPDIS_UPLOAD_PROGRESS_UPDATE} from "../actions/PAPDIS/types";


/**
 * @param profile_data
 * @param children
 * @param pushNotification
 * @param getNotifications
 * @param notificationAction
 * @returns {JSX.Element}
 * @constructor
 */
const NotificationProvider:React.Component = ({
	profile_data = {},
	children = () =>  <></>,
	pushNotification = () => {},
	getNotifications = () => {},
	notificationAction = () => {},
}:{
	profile_data: any,
	children: any,
	pushNotification: Function,
	getNotifications: Function,
}) => {
	const dispatch = useDispatch();
	const [pusherClient] = useState(new Pusher(process.env.REACT_APP_PUSHER_ID, {
		cluster: 'eu'
	}));

	useEffect(() => {setPusherClient(pusherClient);}, [pusherClient]);

	useEffect(() => {
		if (profile_data?.id) {
			const channel_name = `advisor-notification-channel-${profile_data.id}-${process.env.REACT_APP_ENV}`;
			const event_name = `advisor-notification-event-${profile_data.id}-${process.env.REACT_APP_ENV}`;

			const channel = pusherClient.subscribe(channel_name);
			try {
				channel.bind(event_name, event => {
					pushNotification(event?.message);
					notificationAction(event);
					if (typeof event?.message === "object") {
						dispatch({
							type: PAPDIS_UPLOAD_PROGRESS_UPDATE,
							...event?.message,
						})
					}
					else {
						getNotifications();
					}
				});
			} catch (error) {
				console.error('Error handling event:', error);
			}
			return () => {
				channel.unbind_all();
				pusherClient.unsubscribe(channel_name);
			}
		}
	}, [
		profile_data.id,
		pushNotification,
		pusherClient,
		getNotifications,
		notificationAction,
		dispatch,
	]);

	return <>{children}</>;
}

export default connect(
	state => state.Profile,
	dispatch => ({
		pushNotification: notif => dispatch(Notifications.notificationsSnackBar(notif)),
		getNotifications: () => dispatch(Notifications.getNotification()),
		notificationAction: (event) => dispatch(notificationAction(event))
	}))(NotificationProvider);
