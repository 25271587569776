import React, {useState} from "react";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Typography from "@mui/material/Typography";
import AccordionDetails from "@mui/material/AccordionDetails";
import LoadingPanel from "../LoadingPanel";
import MUIDataTable from "mui-datatables";
import CustomToolbar from "./CustomToolbar";
import CustomToolbarSelect from "./CustomToolbarSelect";
import Accordion from "@mui/material/Accordion";

/**
 * @param setSearchFindText
 * @param setSelectedIndividuals
 * @param setEmployerView
 * @param setSearchFindTextWithTimeOut
 * @param individuals_loading
 * @param individuals
 * @param individualsColumns
 * @param getIfaIndividuals
 * @param employerView
 * @param selectedIndividuals
 * @param searchFindText
 * @param advisor_id
 * @param openFirst
 * @param setOpenFirst
 * @returns {JSX.Element}
 * @constructor
 */
export const IndividualsView = (
	{
		setSearchFindText = () => {
		},
		setSelectedIndividuals = () => {
		},
		setEmployerView = () => {
		},
		setSearchFindTextWithTimeOut = () => {
		},
		individuals_loading = false,
		individuals = [],
		individualsColumns = [],
		getIfaIndividuals = () => {
		},
		employerView = true,
		selectedIndividuals = [],
		searchFindText = "",
		advisor_id = 0,
		openFirst = false,
		setOpenFirst = () => {
		}
	}
) => {

	const [sortColumn, setSortColumn] = useState("");
	const [sortDirection, setSortDirection] = useState("asc");
	const [actualPage, setActualPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(10);

	return (
		<Accordion id={"overview-table-accordion"}>
			<AccordionSummary
				expandIcon={
					<ExpandMoreIcon className={"accordion-arrow-icon"}/>
				}
				className={"overview-table-accordion-summary"}
			>
				<Typography variant={"h5"}>
					Manage Your Individuals
				</Typography>

			</AccordionSummary>
			<AccordionDetails>
				{individuals_loading ?
					<LoadingPanel/>
					:
					<MUIDataTable
						className={"data-table individuals-table"}
						columns={individualsColumns}
						data={individuals?.data}
						options={{
							searchPlaceholder: "Search an individual",
							searchOpen: true,
							searchAlwaysOpen: true,
							search: false,
							searchText: searchFindText,
							print: false,
							elevation: 0,
							filter: false,
							selectableRowsHeader: true,
							selectableRows: "multiple",
							responsive: "simple",
							viewColumns: false,
							serverSide: true,
							count: individuals?.total,
							page: individuals?.current_page - 1 || 0,
							rowsPerPage: rowsPerPage,
							sortOrder: {name: sortColumn ?? "", direction: sortDirection ?? ""},

							isRowSelectable: (dataIndex: number) => {
								return individuals?.data[dataIndex]?.active === true
							},

							customToolbar: () => {
								return (
									<CustomToolbar
										setSearchFindText={setSearchFindText}
										setSortColumn={setSortColumn}
										setSortDirection={setSortDirection}
										setActualPage={setActualPage}
										setRowsPerPage={setRowsPerPage}
										setSearchFindTextWithTimeOut={setSearchFindTextWithTimeOut}
										employerView={employerView}
										setEmployerView={setEmployerView}
									/>
								);
							},

							customToolbarSelect: () => {
								return (
									<CustomToolbarSelect
										selectedIndividuals={selectedIndividuals}
										openFirst={openFirst}
										setOpenFirst={setOpenFirst}
									/>
								);
							},


							onRowSelectionChange: (
								currentRowsSelected: [],
								allRowsSelected: [],
							) => {
								setSelectedIndividuals([]);
								const selectedIndividuals = [];
								allRowsSelected.forEach((row) => {
									selectedIndividuals.push(individuals?.data[row.dataIndex]);
								});
								setSelectedIndividuals(selectedIndividuals);
							},

							onChangeRowsPerPage: (numberOfRows: number) => {
								setRowsPerPage(numberOfRows);
								if (sortColumn === "") {
									getIfaIndividuals({
										advisor_id,
										advisors: {},
									}, {
										perPage: numberOfRows,
										page: actualPage,
									})
								} else {
									getIfaIndividuals({
										advisors: {
											advisor_id,
											sort: [
												true,
												sortColumn,
												sortDirection
											]
										},
									}, {
										perPage: numberOfRows,
										page: actualPage,
									})
								}
							},

							onChangePage: (currentPage: number) => {
								if (sortColumn === "") {
									getIfaIndividuals({
										advisor_id,
										advisors: {},
									}, {
										perPage: rowsPerPage ?? 10,
										page: currentPage + 1,
									});
									setActualPage(currentPage);
								} else {
									getIfaIndividuals({
										advisors: {
											advisor_id,
											sort: [
												true,
												sortColumn,
												sortDirection
											]
										},
									}, {
										perPage: rowsPerPage ?? 10,
										page: currentPage + 1,
									});
									setActualPage(currentPage);
								}
							},

							onColumnSortChange: (changedColumn: string) => {
								if (changedColumn === "full_name") {
									changedColumn = "individuals.forename";
									setSortColumn(changedColumn);
								} else {
									setSortColumn(changedColumn);
								}
								setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
								switch (changedColumn) {
									case "individuals.forename":
									case "national_insurance_number":
										getIfaIndividuals({
											advisors: {
												advisor_id,
												sort: [
													true,
													changedColumn,
													sortDirection
												]
											},
										}, {
											perPage: rowsPerPage ?? 10,
											page: actualPage + 1,
										});
										break;
									default:
										break;
								}
							},

							onSearchChange: (searchText) => {
								setSearchFindText(searchText);
								setTimeout(() => setSearchFindTextWithTimeOut(searchText), 2000);
							},

						}}
					/>
				}
			</AccordionDetails>
		</Accordion>
	);
}
