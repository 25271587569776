import Grid2 from '@mui/material/Grid2';

/**
 *
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const SecondaryHeader = (props): JSX.Element => {
	return (
		<Grid2
			className={"collegia-border secondary-header"}
			container
			justifyContent={"center"}
			alignItems={"center"}
		>
			{props.children}
		</Grid2>
	);
}

export default SecondaryHeader;
