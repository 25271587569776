import React from "react";

import FormControl from "@mui/material/FormControl";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Grid2 from '@mui/material/Grid2';
import PhoneInput from "react-phone-input-2";
import 'react-phone-input-2/lib/material.css';
import {checkPassword} from "../utils/Helpers";
/**
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const AdvisorForm = (props) => {

	return (
		<Grid2
			id={"signup-container"}
			size={{
				xs: 12, sm: 12, md: 12, lg: 12, xl: 12,
			}}
			alignItems={"center"}
			alignContent={"center"}
			direction={"column"}
			spacing={0}
			container
			item
		>
			<form
				noValidate
				autoComplete="off"
			>
				<FormControl
					className={"signup-form-control-2 first-title"}
					fullWidth
				>
					<Typography
						align={"center"}
					>
						Create your admin details
					</Typography>
				</FormControl>
				<FormControl
					className={"signup-form-control-2 second-title"}
					fullWidth
				>
					<Typography
						align={"center"}
					>
						Please follow the info below
					</Typography>
				</FormControl>
				<Grid2>
					<FormControl
						className={"signup-form-control"}
						fullWidth
					>
						<TextField
							type={"text"}
							onChange={(event) =>
								props.setFormValues({...props.formValues, [event.target.name]: event.target.value})
							}
							label="Name*"
							variant="outlined"
							name={"name"}
							id={"name"}
							value={props.name}
						/>
					</FormControl>

					<FormControl
						className={"signup-form-control"}
						fullWidth
					>
						<TextField
							type={"text"}
							onChange={(event) =>
								props.setFormValues({...props.formValues, [event.target.name]: event.target.value})
							}
							label="Middle Name"
							variant="outlined"
							name={"middle_name"}
							id={"middle_name"}
							value={props.middle_name}
						/>
					</FormControl>

					<FormControl
						className={"signup-form-control"}
						fullWidth
					>
						<TextField
							type={"text"}
							onChange={(event) =>
								props.setFormValues({...props.formValues, [event.target.name]: event.target.value})
							}
							label="Surname*"
							variant="outlined"
							name={"surname"}
							id={"surname"}
							value={props.surname}
						/>
					</FormControl>

					{
						props?.component?.setup_data?.software_advisor?.advisor?.users[0]?.email ?
							<FormControl
								className={"signup-form-control"}
								fullWidth
							>
								<TextField
									type={"email"}
									label="E-mail"
									variant="outlined"
									name={"second-email"}
									id={"second-email"}
									disabled={true}
									value={props?.component?.setup_data?.software_advisor?.advisor?.users[0]?.email}
								/>
							</FormControl>
							:
							""
					}
					<FormControl
						className={"signup-form-control"}
						fullWidth
					>
						<TextField
							type={"password"}
							onChange={(event) =>
								props.setFormValues({...props.formValues, [event.target.name]: event.target.value})
							}
							label="Password*"
							variant="outlined"
							name={"password"}
							id={"password"}
							value={props.password}
							error={
								(props.formValues.password !== props.formValues.confirm_password) ||
								(props.password === '' || !checkPassword(props.formValues.password))
							}
						/>

						<small>* Please type min. 6-character password containing</small>
						<small>letters and at least a number.</small>
					</FormControl>
					<FormControl
						className={"signup-form-control"}
						fullWidth
					>
						<TextField
							type={"password"}
							onChange={(event) =>
								props.setFormValues({...props.formValues, [event.target.name]: event.target.value})
							}
							label="Confirm password*"
							variant="outlined"
							name={"confirm_password"}
							id={"confirm_password"}
							value={props.confirm_password}
							error={
								props.formValues.password !== props.formValues.confirm_password ||
								!checkPassword(props.formValues.confirm_password)
							}
						/>
					</FormControl>
					<FormControl
						className={"signup-form-control form-control-color"}
						fullWidth
					>
						<PhoneInput
							inputStyle={{
								width: "100%",
							}}
							country="gb"
							inputProps={{
								name: 'telephone',
								id: "telephone",
								required: true,
							}}
							searchPlaceholder={"Country name"}
							searchNotFound={"No country found, please search again."}
							specialLabel={"Mobile | Phone*"}
							countryCodeEditable={false}
							enableSearch
							onChange={(event) => props.setFormValues({...props.formValues, 'telephone': event})}
						/>
						<small>
							There is no need to add the zero at the phone number
						</small>
					</FormControl>
					<FormControl
						className={"signup-form-control"}
						fullWidth
					>
						<Button
							variant="contained"
							color="primary"
							onClick={() =>
								props.proceedStep(props.stepIndex + 1, props.setStepIndex)
							}
							disabled={
								props.formValues.name.length < 2 ||
								props.formValues.surname.length < 2 ||
								!props.formValues.password ||
								!props.formValues.confirm_password ||
								(props.formValues.password !== props.formValues.confirm_password) ||
								(props.formValues.telephone.length < 8) ||
								props.error ||
								!checkPassword(props.formValues.password) ||
								!checkPassword(props.formValues.confirm_password)
							}
						>
							Continue
						</Button>
					</FormControl>
					<FormControl
						className={"signup-form-control-2 forgot-password"}
						fullWidth
					>
						<span
							className={"span-style"}
							onClick={() =>
								props.proceedStep(props.stepIndex - 1, props.setStepIndex)
							}
						>
							Back
						</span>
					</FormControl>
				</Grid2>
			</form>
		</Grid2>
	);
}

export default AdvisorForm;
