import React from "react";
import {Grid2, Link} from "@mui/material";
import logo from "../../images/logo-icon.svg";

const Footer = (props) => (
    <Grid2
        id={"footer-logged"}
        size={{
	        xs: 12, sm: 12, md: 12, lg: 12, xl: 12,
        }}
        direction={"column"}
        alignItems={"center"}
        alignContent={"center"}
        item
        container
        className={props.className || ""}
    >
        {props.includeLogo ?
            <Grid2
	            size={{
		            xs: 12, sm: 12, md: 12, lg: 12, xl: 12,
	            }}
                item
            >
                <img
                    src={logo}
                    alt={"Collegia logo."}
                />
            </Grid2> : null
        }
        <Grid2
	        size={{
		        xs: 12, sm: 12, md: 12, lg: 12, xl: 12,
	        }}
	        alignSelf={"center"}
	        alignItems={"center"}
	        alignContent={"center"}
	        direction={"column"}
	        container
        >
            <Link href={"https://www.collegia.co.uk"} target={"_blank"}>
                <span>© {new Date().getFullYear()} Collegia Partners Limited. All rights reserved.</span>
            </Link>
        </Grid2>
    </Grid2>
);

/**
 * @stateless
 * @param props
 * @returns {JSX.Element}
 */
export default Footer;
